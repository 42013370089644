import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";
import { functions, getAppCheckToken, httpsCallable } from "../../../Services/firebase";

function* requestToGetUsers(action) {

    yield call(
        request({
            type: constants.GET_USERS,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetUser(action) {

    yield call(
        request({
            type: constants.GET_USER,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `users/${action.payload.userId}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToAddUser(action) {
    yield call(
        request({
            type: constants.ADD_USER,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users/add`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToUpdateUser(action) {
    yield call(
        request({
            type: constants.EDIT_USER,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users/edit`,///${action.payload.userId}
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteUser(action) {
    yield call(
        request({
            type: constants.DELETE_USER,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users/delete`,///${action.payload.userId}
            data: { userId: action.payload.userId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToUserRolePermission(action) {
    yield call(
        request({
            type: constants.DELETE_USER,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users/get-permissions`,///${action.payload.userId}
            data: { userId: action.payload.userId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}



export default function* rootSaga() {
    yield takeLatest(constants.GET_USERS, requestToGetUsers);
    yield takeLatest(constants.GET_USER, requestToGetUser);
    yield takeLatest(constants.ADD_USER, requestToAddUser);
    yield takeLatest(constants.EDIT_USER, requestToUpdateUser);
    yield takeLatest(constants.DELETE_USER, requestToDeleteUser);
    yield takeLatest(constants.GET_USER_PERMISSION, requestToUserRolePermission);

}
