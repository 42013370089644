import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.GET_USERS)]: (
            state,
            action
        ) => ({
            ...state,
            users: action.payload.users,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_USERS)]: (
            state
        ) => ({
            ...state,
            users: [],
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_USERS)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.GET_USER)]: (
            state,
            action
        ) => ({
            ...state,
            user: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_USER)]: (
            state
        ) => ({
            ...state,
            user: {},
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_USER)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.ADD_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.ADD_USER)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.ADD_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.EDIT_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.EDIT_USER)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.EDIT_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.DELETE_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.DELETE_USER)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.DELETE_USER)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    initialState()
);
