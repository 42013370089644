import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";
import { functions, getAppCheckToken, httpsCallable } from "../../../Services/firebase";


function* requestToGetProjects(action) {
        
    yield call( 
        request({
            type: constants.GET_PROJECTS,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `projects`, //${action.payload.clientId || ""}
            data: action.payload.formData,
            // data: action.payload.users,
            //headers: { Authorization: `Bearer ${action.payload.token}`, 'X-Firebase-AppCheck': appCheckToken },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetProject(action) {

    yield call(
        request({
            type: constants.GET_PROJECT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `projects/detail`,
            data: {'projectId':action.payload.projectId},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToAddProject(action) { 
    yield call(
        request({ 
            type: constants.ADD_PROJECT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `projects/add`,
            data: {'project':action.payload.formData},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToEditProject(action) {
    yield call(
        request({
            type: constants.EDIT_PROJECT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `${apiConfig.API_END_POINT}projects/edit`,///${action.payload.userId}
            data: action.payload.formData,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteProject(action) {
    yield call(
        request({
            type: constants.DELETE_PROJECT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `projects/delete`,///${action.payload.userId}
            data: { projectId: action.payload.projectId },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

export default function* rootSaga() {
    yield takeLatest(constants.GET_PROJECTS, requestToGetProjects);
    yield takeLatest(constants.ADD_PROJECT, requestToAddProject);
    yield takeLatest(constants.EDIT_PROJECT, requestToEditProject);
    yield takeLatest(constants.DELETE_PROJECT, requestToDeleteProject);
    yield takeLatest(constants.GET_PROJECT, requestToGetProject);
}
