import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useDispatch, useSelector } from "react-redux";
import { requestAddClient, requestEditClient, requestGetClient } from "./Middleware/action";
import { toast } from "react-toastify";
import { requestGetRoles } from "components/roles/Middleware/action";
import { useForm, Controller } from "react-hook-form";
import {countryData} from '../../data/countryData.js';
import Select from 'react-select'
import IconButton from "components/common/IconButton";
import { randomString } from "Services/Utils/global";

const AddUpdateClient = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const { id } = useParams();

    const { control,register, watch, handleSubmit, setValue, getValues, getValue, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordRequired, setPasswordRequired] = useState(true);
    const [country, setCountry] = useState();
    const [isCopied, setTextCopied]=useState(false);

    const onSubmit = (formData) => {
        saveClient(formData);
    }

    useEffect(() => {
        if (id) {
            setPasswordRequired(false);
            getClient();
        }
    }, [id]);

    useEffect(()=>{
        if(!id){
            setValue('password', randomString(16));
        }
    }, [])

    const copyPassword = () =>{
        navigator.clipboard.writeText(getValues("password"));

        setTextCopied(true);

        setTimeout(() => {
            setTextCopied(false);
        }, 5000);

    }


    const getClient = () => {

        setLoading(true);
        const onSuccess = (data) => {
            setLoading(false)
            const client = data.user;
            const clientMeta = data.user.meta;
            // acccount details
            setValue('firstName', client.firstName || "");
            setValue('lastName', client.lastName || "");
            setValue('email', client.email);

            setValue('bussinessName', clientMeta.accountDetail.bussinessName);
            setValue('companyId', clientMeta.accountDetail.companyId);
            setValue('phone', clientMeta.accountDetail.phone);
            setValue('address', clientMeta.accountDetail.address);
            setValue('country', clientMeta.accountDetail.country);
            setValue('city', clientMeta.accountDetail.city);
            setValue('description', clientMeta.accountDetail.description);
            setValue('email_cc', clientMeta.email_cc);
            setValue('email_bcc', clientMeta.email_bcc);

            // billing detail
            setValue('billingEmail', clientMeta.billingDetail.email);
            setValue('billingPhone', clientMeta.billingDetail.phone);
            setValue('billingAddress', clientMeta.billingDetail.address);
            setValue('billingCountry', clientMeta.billingDetail.country);
            setValue('billingCity', clientMeta.billingDetail.city);

        }
        dispatch(requestGetClient({ clientId: id, onSuccess }))
    }

    const saveClient = (formData) => {
        setIsSubmitting(true);
        const onSuccess = (data) => {
            setIsSubmitting(false);

            if (data.success) {

                toast.success(`Client ${id ? 'updated' : 'added'} successfully`, {
                    theme: 'colored'
                });

                navigate("/clients");

            }
        }

        const onFail = (err) => {
            setIsSubmitting(false);
            toast.error(err.response.data.message);
        }

        //save client
        formData.role = "Client";

        formData.accountDetail = {
            phone: formData.phone,
            address: formData.address,
            description: formData.description,
            city: formData.city,
            country: formData.country,
            bussinessName: formData.bussinessName,
            companyId: formData.companyId
        };

        formData.billingDetail = {
            email: formData.billingEmail,
            phone: formData.billingPhone,
            address: formData.billingAddress,
            city: formData.billingCity,
            country: formData.billingCountry,
        };
        if (id) {
            formData.userId = id;
            dispatch(requestEditClient({ formData, onSuccess, onFail }))
        } else {
            dispatch(requestAddClient({ formData, onSuccess, onFail }))
        }
    }

    const sameAsAbove=event=>{

        if (event.target.checked) {

            const billingEmail = getValues("email");
            const billingPhone = getValues("phone");
            const billingAddress = getValues("address");
            const billingCountry = getValues("country");
            const billingCity = getValues("city");

            // billing detail
            setValue('billingEmail', billingEmail);
            setValue('billingPhone', billingPhone);
            setValue('billingAddress', billingAddress);
            setValue('billingCountry', billingCountry);
            setValue('billingCity', billingCity);

          //console.log('formData',formData);

        } 
        else 
        {
          // billing detail
            setValue('billingEmail', '');
            setValue('billingPhone', '');
            setValue('billingAddress', '');
            setValue('billingCountry', '');
            setValue('billingCity', '');
        }
            
    }

    return (
        // {loading ?
        //     <span className="spinner-grow spinner-grow-sm"></span>
        //     :
        <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>

                <FalconCardHeader title={'Account Detail'} />
                <Card.Body className="bg-light">
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                {...register('firstName', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.firstName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                {...register('lastName', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.lastName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Bussiness Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Bussiness Name"
                                {...register('bussinessName', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.bussinessName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>Company Id</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Company Id"
                                {...register('companyId', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.companyId ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                {...register('email', { required: 'Field is required', pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid Email',
                                } })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.email?.message ? 'block' : 'none' }}>{errors.email?.message}</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="password">
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Password"
                                    {...register('password', { required: { passwordRequired } })}
                                />
                                <IconButton  variant={isCopied ? "falcon-success" : "falcon-default"} icon="copy" onClick={copyPassword} >
                                 {/* Copy */}
                                </IconButton>
                            </InputGroup>

                            <div className="invalid-feedback" style={{ display: errors.password ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Email CC</Form.Label>
                             <Form.Control
                                type="text"
                                placeholder="Email CC"
                                {...register('email_cc', { required: false })}
                            />

                            <div className="invalid-feedback" style={{ display: errors.email_cc ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Email BCC</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email BCC"
                                {...register('email_bcc', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.email_bcc ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                placeholder="Phone"
                                {...register('phone', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.phone ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address"
                                {...register('address', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.address ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Country</Form.Label>
                             <Form.Select 
                             aria-label="country"
                             value={country}
                              {...register('country', { required: false })}>
                             <option value="">Select Country</option>
                                {countryData.map((item, index) => {
                                  return(
                                   <option value={item.name} key={index}>{item.name}</option>
                                   );
                                })}
                            </Form.Select>

                            <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                {...register('city', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.city ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} lg={12}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                type="text"
                                placeholder="Description"
                                {...register('description', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.description ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                </Card.Body>

                <FalconCardHeader title={'Billing Detail'} />

                <Card.Body className="bg-light">
                <Form.Check 
                  type='checkbox'
                  label='Same as above'
                  onClick={sameAsAbove}
                  {...register('sameAsAbove', { required: false })}
                />
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                {...register('billingEmail', { required: 'Field is required', pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid Email',
                                } })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.billingEmail?.message ? 'block' : 'none' }}>{errors.billingEmail?.message}</div>
                        </Form.Group>

                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                min="0"
                                placeholder="Phone"
                                {...register('billingPhone', { required: true })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.billingPhone ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address"

                                {...register('billingAddress', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.billingAddress ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Country</Form.Label>
                            <Form.Select 
                             aria-label="country"
                             value={watch("country")}
                              {...register('billingCountry', { required: false })}>
                             <option value="">Select Country</option>
                                {countryData.map((item, index) => {
                                  return(
                                   <option value={item.name} key={`1${index}`}  >{item.name}</option>
                                   );
                                })}
                            </Form.Select>
                            <div className="invalid-feedback" style={{ display: errors.bilingCountry ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                {...register('billingCity', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.billingCity ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>

                    <div className="text-end pt-3">
                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                            )}  {id ? 'Update Client' : 'Add Client'}
                        </Button>
                    </div>
                </Card.Body>
            </Form>
        </Card>
    )
}

export default AddUpdateClient;
