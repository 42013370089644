import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { _setAccessToken } from 'Services/localStorageService';
import { setAuthUser } from 'components/authentication/Middleware/action';
import { useOutletContext } from "react-router-dom";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const { authUser } = useSelector(store => store.auth);
  const navigate = useNavigate();
 // console.log("authUser..",authUser.user.displayName);

  const loggedInUserId =authUser.user.uid;

  const handleLogoutClick = (e) => {
    e.preventDefault();
    _setAccessToken(null)
    dispatch(setAuthUser(null));
    navigate("/auth/login", { replace: true })
  }

  return (
    <>
    <Dropdown navbar={true} as="li">
      <label>{authUser.user.displayName}</label>
    </Dropdown>

    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
      
        <Avatar src={team3} />

      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item> */}
          <Dropdown.Item as={Link} onClick={(e) => { e.preventDefault(); navigate("employees/edit/" + loggedInUserId) }}>
            Edit Profile
          </Dropdown.Item>
          {/* <Dropdown.Item href="#!">Feedback</Dropdown.Item> 
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>*/}
          <Dropdown.Divider />
          <Dropdown.Item as={Link} onClick={handleLogoutClick}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
    </>
  );
};

export default ProfileDropdown;
