import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.GET_INVOICES)]: (
            state,
            action
        ) => ({
            ...state,
            invoices: action.payload.invoices,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_INVOICES)]: (
            state
        ) => ({
            ...state,
            clients: [],
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_INVOICES)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.INVOICE_DETAIL)]: (
            state,
            action
        ) => ({
            ...state,
            invoice: action.payload.invoice,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.INVOICE_DETAIL)]: (
            state
        ) => ({
            ...state,
            invoice: {},
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.INVOICE_DETAIL)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    initialState()
);
