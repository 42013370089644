import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Error403 from 'components/errors/Error403';
import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleConfirmMail from 'components/authentication/simple/ConfirmMail';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';
import Dashboard from 'components/dashboards/default';
import Leads from 'components/leads';
import LeadDetails from 'components/leads/LeadDetails';
import Users from 'components/users';
import AddUpdateUser from 'components/users/AddUpdate';
import AddInvoice from 'components/invoices/AddInvoice';
import Invoices from 'components/invoices';
import InvoiceDetail from 'components/invoices/InvoiceDetail';
import Roles from 'components/roles';
import AddUpdateRole from 'components/roles/AddUpdate';
import { ROLES } from "Services/Utils/enums";
import RequireAuth from './requireAuth';
import Clients from 'components/clients';
import Image from 'components/images/Image';
import Projects from 'components/projects';
import AddUpdateProject from 'components/projects/AddProject';
import AddUpdateClient from 'components/clients/AddUpdate';
import PaymentResult from 'components/invoices/PaymentResult';
import Leaves from 'components/leaves/index';
import AddUpdateLeave from 'components/leaves/AddUpdateLeave';
import Employees from 'components/employees/index';
import AddUpdateEmployee from 'components/employees/AddUpdateEmployee';
import AddUpdateEmployeeLeave from 'components/employees/AddUpdateEmployeeLeave';
import Company from 'components/company/index';
import AddUpdateCompany from 'components/company/AddUpdateCompany';
import Logs from 'components/logs';
import Permission from  'components/permissions';
import AddUpdatePermission from  'components/permissions/AddUpdatePermission';
import Tasks from 'components/tasks';
import AddUpdateTask from 'components/tasks/AddUpdateTask';
import TaskTimeline from 'components/tasks/TaskTimeline';
import Chat from 'components/chat/Chat';
// import WhatsAppChat from 'components/whatsapp';

const FalconRoutes = () => {

  // const { authUser } = useSelector(store => store.auth);

  return (


    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
        <Route path="errors/403" element={<Error403 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}

      <Route element={<AuthSimpleLayout />}>
        <Route path="/" element={<SimpleLogin />} />
        <Route path="auth/login" element={<SimpleLogin />} />
        <Route path="/payment-result/:sessionId" element={<PaymentResult />} />
        {/* <Route
          path="auth/register"
          element={<SimpleRegistration />}
        /> */}
        <Route path="auth/logout" element={<SimpleLogout />} />
        <Route
          path="auth/forgot-password"
          element={<SimpleForgetPassword />}
        />
        <Route
          path="auth/reset-password"
          element={<SimplePasswordReset />}
        />
        <Route
          path="auth/confirm-mail"
          element={<SimpleConfirmMail />}
        />
        <Route
          path="auth/lock-screen"
          element={<SimpleLockScreen />}
        />
      </Route>

      {/* {authUser && ( */}
      <Route element={<MainLayout />}>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_dashboard']}/>}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/employees/edit/:id" element={<AddUpdateEmployee />} />

        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={[]} />}>
          <Route path="/leads" element={<Leads />} />
          <Route path="/leads/:id" element={<LeadDetails />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_user']}/>}>
          <Route path="/users" element={<Users />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_user']}/>}>
          <Route path="/users/add" element={<AddUpdateUser />} />
          <Route path="/users/edit/:id" element={<AddUpdateUser />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_client']}/>}>
          <Route path="/clients" element={<Clients />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_client']}/>}>
          <Route path="/clients/add" element={<AddUpdateClient />} />
          <Route path="/clients/edit/:id" element={<AddUpdateClient />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_invoice']}/>}>
          <Route path="/clients/:clientId/invoice/add" element={<AddInvoice />} />
          <Route path="/clients/:clientId/invoice/:id" element={<InvoiceDetail />} />
          <Route path="/clients/:clientId/invoice/edit/:id" element={<AddInvoice />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_role']}/>}>
          <Route path="/roles" element={<Roles />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_role']}/>}>
          <Route path="/roles/add" element={<AddUpdateRole />} />
          <Route path="/roles/edit/:id" element={<AddUpdateRole />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_invoice']}/>}>
          <Route path="/invoice" element={<Invoices />} />
          <Route path="/clients/invoice/:clientId" element={<Invoices />} />
          <Route path="/invoice/:id" element={<InvoiceDetail />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_invoice']}/>}>
          <Route path="/invoice/add" element={<AddInvoice />} />
          <Route path="/invoice/edit/:id" element={<AddInvoice />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_project']}/>}>
          <Route path="/projects" element={<Projects />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_project']}/>}>
          <Route path="/projects/add" element={<AddUpdateProject />} />
          <Route path="/projects/edit/:id" element={<AddUpdateProject />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_leave']}/>}>
          <Route path="/leaves" element={<Leaves />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_leave']}/>}>
          <Route path="/leaves/add" element={<AddUpdateLeave />} />
          <Route path="/leaves/add/:loggedInUserId" element={<AddUpdateLeave />} />
          <Route path="/leaves/edit/:id" element={<AddUpdateLeave />} />
          <Route path="/leaves/edit/:id/:loggedInUserId" element={<AddUpdateLeave />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_employee']}/>}>
          <Route path="/employees" element={<Employees />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_employee']}/>}>
          <Route path="/employees/add" element={<AddUpdateEmployee />} />
          <Route path="/employees/add-leave/:employeeId" element={<AddUpdateEmployeeLeave />} />
          <Route path="/employees/edit-leave/:employeeId/:leaveId" element={<AddUpdateEmployeeLeave />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_company']}/>}>
          <Route path="/company" element={<Company />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_company']}/>}>
          <Route path="/company/add" element={<AddUpdateCompany />} />
          <Route path="/company/edit/:id" element={<AddUpdateCompany />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['view_permission']}/>}>
          <Route path="/bugs" element={<Logs />} />
          <Route path="/permissions" element={<Permission />} />
          <Route path="/logs" element={<Logs />} />
        </Route>
         <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_permission']}/>}>
          <Route path="/permissions/add" element={<AddUpdatePermission />} />
          <Route path="/permissions/edit/:id" element={<AddUpdatePermission />} />
         </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]}  allowedPermissions={[]}/>}>
          <Route path="/image" element={<Image />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_task','view_task','view_own_task']}/>}>
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/projects/tasks/:projectId" element={<Tasks />} />
          <Route path="/tasks/add" element={<AddUpdateTask />} />
          <Route path="/tasks/edit/:id" element={<AddUpdateTask />} />
          <Route path="/tasks/:completed" element={<Tasks />} />
          <Route path="/tasks/timeline" element={<TaskTimeline />} />
         </Route>
         <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} allowedPermissions={['add_permission']}/>}>
          <Route path="/whatsapp_messages" element={<Chat />} />
         </Route>
      </Route>
    
      {/* )} */}
      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
