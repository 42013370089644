const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_EMPLOYEES: undefined,
  GET_EMPLOYEE: undefined,
  ADD_EMPLOYEE: undefined,
  EDIT_EMPLOYEE: undefined,
  DELETE_EMPLOYEE: undefined,
  ADD_EMPLOYEE_LEAVE: undefined,
  EDIT_EMPLOYEE_LEAVE: undefined,
  GET_EMPLOYEE_LEAVE: undefined,
  GET_EMPLOYEE_LEAVE_BY_EMPLOYEE_ID: undefined,
  GET_EMPLOYEE_FOR_TIMELINE:undefined
  
});
