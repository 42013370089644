import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Timeline from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css'
import { useDispatch, useSelector } from "react-redux";
import { requestGetTasksForTimeline } from 'components/tasks/Middleware/action';
import { requestGetEmployeesForTimeline } from 'components/employees/Middleware/action';


const TaskTimeline = () => {

    const dispatch = useDispatch();
    const [employees,setEmployees]=useState([]);
    const [tasks,setTasks]=useState([]);
    const [employeeLoading, setEmployeeLoading]= useState(false);
    const [taskLoading, setTaskLoading]= useState(false);
    //const startOfMonth = moment().add(-5,'days');
    const startOfMonth = moment().startOf('month');
    //const endOfMonth   = moment().endOf('month');
    const endOfMonth   = moment().add(5, 'days');

    //console.log("startOfMonth..",startOfMonth,"endOfMonth..",endOfMonth);

    useEffect(() => { 
        getTasks();
        getEmployees();

    }, []);

    const getEmployees=()=>{ 

      setEmployeeLoading(true);

      const onSuccess = (data) => { 
         
          if (data.success) { 
              setEmployees(data.employees); 
              console.log("data.employees..",data.employees);
              setEmployeeLoading(false);
          }
      }
      dispatch(requestGetEmployeesForTimeline({ onSuccess }))
    }

    const getTasks = async (searchValue) => {

      setTaskLoading(true);

      const onSuccess = (data) => { console.log("data..",data);

        setTaskLoading(false);
        setTasks(data.tasks);                     
      }

      if(!searchValue)
      {

        searchValue ={taskAssignedDate:[startOfMonth,endOfMonth]}
      }

      const formData ={'search':searchValue} 

      console.log('formData..',formData);

      dispatch(requestGetTasksForTimeline({formData, onSuccess }))
    }

  //const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }];

  /*const items = [
  {
    id: 1,
    group: 1,
    title: 'item 1',
    start_time: moment(),
    end_time: moment().add(1, 'hour')
  },
  {
    id: 2,
    group: 2,
    title: 'item 2',
    start_time: moment().add(-0.5, 'hour'),
    end_time: moment().add(0.5, 'hour')
  },
  {
    id: 3,
    group: 1,
    title: 'item 3',
    start_time: moment().add(2, 'hour'),
    end_time: moment().add(3, 'hour')
  }
]*/

    console.log("tasks...",tasks,"taskLoading..",taskLoading);

    return( 
      <>
      { (taskLoading || employeeLoading) ?
        <span className="spinner-grow spinner-grow-sm"></span>
        :
      
        <>
        <h1>hello</h1>
        <Timeline
          groups={employees}
          items={tasks}
          defaultTimeStart={startOfMonth}
          defaultTimeEnd={endOfMonth}
          //visibleTimeStart={startOfMonth} 
          //visibleTimeEnd={endOfMonth}

          minZoom={31 * 86400 * 1000} // 1 month
         // maxZoom={86400 * 1000 } // 20 years
          lineHeight="100"
          itemHeightRatio=".20"
        />
        </>

       }
        </>
      )   
};

export default TaskTimeline;
