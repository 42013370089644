import { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import { requestGetProjects } from '../projects/Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';

const AddProjectToInvoice = ({ register, control, clientId, invoiceId, projectss, getValues, setValue, watch, errors, updateProjectList, setUpdateProjectList, addNewProject, setAddNewProject }) => {

  const dispatch = useDispatch();
  const [allProjects, setAllProjects] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "projects"
  });

  useEffect(() => {

    fields.forEach((field, index) => {
      removeProject(index);
    })

    if (invoiceId && projectss && projectss.length > 0) {
      setValue("projects", projectss);

      fields.forEach((field, index) => {
        addProject(projectss[index]);
      })
    } else {
      if (!invoiceId) {
        addProject();
      }
    }
  }, [projectss]);

  useEffect(() => {
    getProjects();
    setUpdateProjectList(false);
  }, [updateProjectList, addNewProject])

  useEffect(() => {
    if(addNewProject){
      addProject()
      setAddNewProject(false)
    }
  }, [addNewProject])

  const addProject = value => {
    append(value);
  }

  const removeProject = index => {
    remove(index);
  };

  const getProjects = async () => {

    const onSuccess = (data) => {
      setAllProjects(data.projects);
    }
    const formData = { 'pageNo': 1, 'perPage': 100, 'search': '', 'clientId': clientId }

    //dispatch(requestGetProjects({ clientId, onSuccess }))
    dispatch(requestGetProjects({ formData, onSuccess }))
  }

  const setProjectName = function (event, index) {
    event.preventDefault();
    let projectId = event.target.value;
    const result = allProjects.find(({ _id }) => _id === projectId);

    if (result !== undefined) {
      let projectName = result.projectName;
      setValue(`projects.${index}.projectName`, projectName);
    }
  }

  const getCalculatedPrice = (e, index) => {
    e.preventDefault();
    const subTotal = parseFloat(getValues(`projects.${index}.subTotal`)).toFixed(2);
    const discountType = getValues(`projects.${index}.discountType`);
    const discountValue = parseFloat(getValues(`projects.${index}.discountValue`) || "0")
    const totalPrice = subTotal ? discountType == 'fixed' ? (subTotal - discountValue) : (subTotal - (subTotal * (discountValue / 100))) : '';
    setValue(`projects.${index}.totalAmount`, totalPrice.toFixed(2));

    const discount = discountType == "fixed" ? discountValue : (subTotal * (discountValue / 100));
    setValue(`projects.${index}.discount`, discount);
  }

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div key={index} className="mb-3 g-3">
            <Row className="mb-3 g-3" >
              <Form.Group as={Col} lg={6} >
                <Form.Label>Project Name</Form.Label>

                {clientId ?
                  <>
                    {/* onChange={(event) => setProjectName(event, index)} */}
                    <Form.Select aria-label="Project Name" {...register(`projects.${index}.projectId`, { required: true })} onChange={(event) => setProjectName(event, index)}>
                      <option value="">Select Project</option>
                      {allProjects.map((item, index) => {
                        return (
                          <option value={item._id.valueOf()} key={`1${index}`}>{item.projectName}</option>
                        );
                      })}
                    </Form.Select>
                    <Form.Control
                      type="hidden"
                      // value={watch(`projects.${index}.projectId`) ? allProjects.find(({ _id }) => _id === watch(`projects.${index}.projectId`))?.projectName : ''}
                      {...register(`projects.${index}.projectName`, { required: "Field is required" })}
                    />

                  </>
                  :
                  <Form.Control
                    type="text"
                    placeholder="Project Name"
                    {...register(`projects.${index}.projectName`, { required: "Field is required" })}
                  />

                }
                {errors?.projects?.[index]?.projectName && <div className="invalid-feedback" style={{ display: 'block' }}>{errors?.projects?.[index]?.projectName.message}</div>}
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>Time Spent</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="Time Spent"
                  {...register(`projects.${index}.timeSpent`, { required: "Field is required" })}

                />
                {errors?.projects?.[index]?.timeSpent && <div className="invalid-feedback" style={{ display: 'block' }}>{errors?.projects?.[index]?.timeSpent.message}</div>}
              </Form.Group>
              <Form.Group as={Col} lg={3}>
                <Form.Label>Hours/Minutes</Form.Label>
                <Form.Select aria-label="discountType"
                  {...register(`projects.${index}.timeIn`, { required: false })}>
                  <option value="Hours">Hours</option>
                  <option value="Minutes">Minutes</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3" >
              <Form.Group as={Col} lg={6} >
                <Form.Label>Project Amount</Form.Label>
                <Form.Control
                  // type="number"
                  min="0"
                  placeholder="Project Amount"
                  {...register(`projects.${index}.subTotal`, { required: "Field is required", onChange: (e) => { getCalculatedPrice(e, index) } })}
                />
                {errors?.projects?.[index]?.subTotal && <div className="invalid-feedback" style={{ display: 'block' }}>{errors?.projects?.[index]?.subTotal.message}</div>}
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Discount Type</Form.Label>
                <Form.Select aria-label="discountType"
                  {...register(`projects.${index}.discountType`, { required: false, onChange: (e) => { getCalculatedPrice(e, index) } })}>
                  {/* <option value="">Select</option> */}
                  <option value="fixed">Fixed</option>
                  <option value="percent">Percent</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>Discount Value</Form.Label>
                <Form.Control
                  // type="number"
                  min="0"
                  placeholder="Discount Value"
                  {...register(`projects.${index}.discountValue`, { onChange: (e) => { getCalculatedPrice(e, index) } })} />
              </Form.Group>
              <Form.Group as={Col} lg={6} >

                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  // type="number"
                  // min="0"
                  placeholder="Total Amount"
                  // value={getCalculatedPrice(index)}
                  {...register(`projects.${index}.totalAmount`, { required: "Field is required" })}
                />


                {errors?.projects?.[index]?.totalAmount && <div className="invalid-feedback" style={{ display: 'block' }}>{errors?.projects?.[index]?.totalAmount.message}</div>}
              </Form.Group>

              <Form.Control
                type="hidden"
                {...register(`projects.${index}.discount`, { required: "Field is required" })}
              />
              <Form.Group as={Col} lg={6} >
              </Form.Group>
            </Row>
            <IconButton variant="danger" icon="trash" type="button" onClick={() => removeProject(index)}>
              Delete
            </IconButton>
          </div>
        );
      })}

      <IconButton variant="primary" icon="plus" type="button" onClick={(e) => addProject()}>
        Add Project
      </IconButton>
    </>
  );
}

export default AddProjectToInvoice;
