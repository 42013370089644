export const CURRENCY ={
    "ILS": "₪",
    "USD": "$",
    "INR": "₹",
    "EUR": "€"
}

export const STATUS={
    1:"Active",
    2:"Inactive"   
}

export const PRIORITY={
    1:"Urgent",
    2:"High",
    3:"Medium",
    4:"Low"  
}

export const TASK_TIME_TYPE={
    "Hours":"Hours",
    "Minutes":"Minutes" 
}

export const TASK_STATUS ={ 
    1:"Assigned",
    2:"Working on It",
    3:"Stuck",
    4:"Done",
    5:"Pending",
    6:"On Hold",
    7:"Timer Off"
}