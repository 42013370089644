import { call, takeLatest } from "redux-saga/effects";
//
import { constants } from "./constants";
import { request } from "../../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* fetchAllProducts(action) {
    yield call(
        request({
            type: constants.FETCH_ALL_PRODUCT,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            url: `product/list`,
            data: action.payload.data,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

 
export default function* rootSaga() {
    yield takeLatest(constants.FETCH_ALL_PRODUCT, fetchAllProducts);
}
