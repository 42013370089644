const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_USERS: undefined,
  GET_USER: undefined,
  ADD_USER: undefined,
  EDIT_USER: undefined,
  DELETE_USER: undefined,
  GET_USER_PERMISSION:undefined
});
