import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useDispatch, useSelector } from "react-redux";
import { requestAddRole, requestEditRole, requestGetRole } from "./Middleware/action";
import { requestGetPermissions } from "components/permissions/Middleware/action";
import { toast } from "react-toastify";
import { useForm,Controller, useFieldArray } from "react-hook-form";
import { publish } from "Services/events";

const AddUpdateRole = () => {

    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);

    let navigate = useNavigate();

    const { id } = useParams();

    // functions to build form returned by useForm() hook
    const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allPermissions, setAllPermissions] = useState([]);

    const onSubmit = (formData) => {
        saveRole(formData);
    };

    useEffect(() => {
        if (id) {
            getRole();
        }
    }, [id]);


    const getRole = () => {
        setLoading(true);
        const onSuccess = (data) => {
            setLoading(false)
            setValue('role', data.role.role);
            setValue('status', data.role.status);
            setValue('rolePermissions', data.role.rolePermissions[0]);

        }
        dispatch(requestGetRole({ roleId: id, onSuccess }))
    }

    const saveRole = (formData) => { //console.log('formData..',formData);
   
        setIsSubmitting(true);
        const onSuccess = (data) => { //console.log('data..',data,'permissions',data.userPermission[0].permissions.rolePermissions);
            setIsSubmitting(false);

            if (data.success) {
                toast.success(`Role ${id ? 'updated' : 'added'} successfully`, {
                    theme: 'colored'
                });
                
                publish("userPermission", data.userPermission[0].permissions.rolePermissions[0]);
                navigate("/roles");
            }
        }

        if (id) {
            const data = formData;
            data.roleId = id;
            dispatch(requestEditRole({ data, onSuccess }))
        } else {
            dispatch(requestAddRole({ formData, onSuccess }))
        }
    }

    useEffect(() => {
        getPermissions();
    }, []);

    const getPermissions = async () => {
        const onSuccess = (data) => {
            setAllPermissions(data.permissions);
        }
        dispatch(requestGetPermissions({ onSuccess }))
    }

    return (
        <Card>
            <FalconCardHeader title={id ? 'Update Role' : 'Add Role'} />
            <Card.Body className="bg-light">
                {loading ?
                    <span className="spinner-grow spinner-grow-sm"></span>
                    :
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Role"
                                    {...register('role', { required: true })}
                                />

                            </Form.Group>
                            <div className="invalid-feedback" style={{ display: errors.role ? 'block' : 'none' }}>Field is required</div>
                            <Form.Group as={Col} lg={6}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Status" {...register('status', { required: true })}>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>

                                </Form.Select>
                                <div className="invalid-feedback" style={{ display: errors.status ? 'block' : 'none' }}>Field is required</div>
                              </Form.Group>
                        </Row>
                        <Form.Group as={Col} lg={6}>
                                <Form.Label>Permissions</Form.Label>
                        </Form.Group>
                         <Row className="mb-3 g-3">
                        {allPermissions.map((permission, index)=>{

                             return(
                                <Form.Group as={Col} lg={3} key={index}>
                                 <Form.Check 
                                    type='checkbox'
                                    id={index}
                                    label={permission.name}
                                    {...register(`rolePermissions.${permission.slug}`)}
                                    
                                  /> 
                                </Form.Group>
                                )
                            })   
                        }

                        </Row>
                        <div className="text-end">
                            <Button variant="primary" type="submit" disabled={isSubmitting} >
                                {isSubmitting && (
                                    <span className="spinner-grow spinner-grow-sm"></span>
                                )}
                                {id ? 'Update Role' : 'Add Role'}
                            </Button>
                        </div>
                    </Form>
                }
            </Card.Body>
        </Card>
    )
}

export default AddUpdateRole;
