import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { useForm } from 'react-hook-form';

const CancelInvoicesModal = ({ show, handleClose, cancelInvoice }) => {
    const [cancelling, setCancelling] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        cancelInvoice(data.reason)
    }

    return (

        <Modal show={show} onHide={handleClose} size="lg">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header
                    closeButton
                    closeVariant={null}
                    className="border-200"
                >
                    <Modal.Title as="h5">
                        <Flex alignItems="center">
                            Enter Reason
                        </Flex>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="reason">
                        <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Reason"
                            {...register('reason', { required: true })}
                        />
                        {/* <textarea placeholder="Enter reason" {...register("reason", { required: true })} ></textarea> */}
                        <div className="invalid-feedback" style={{ display: errors.reason ? 'block' : 'none' }}>Field is required</div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-200 d-block">
                    <IconButton
                        size="sm"
                        icon="ban"
                        variant="primary"
                        iconAlign="right"
                        disabled={cancelling}
                        type="submit"
                    >
                        {cancelling ? 'Cancelling...' : 'Cancel invoice'}
                    </IconButton>
                    <Button
                        size="sm"
                        className="ms-2"
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CancelInvoicesModal;
