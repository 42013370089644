import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetClients = createAction(constants.GET_CLIENTS);
export const requestGetClient = createAction(constants.GET_CLIENT);
export const requestAddClient = createAction(constants.ADD_CLIENT);
export const requestEditClient = createAction(constants.EDIT_CLIENT);
export const requestDeleteClient = createAction(constants.DELETE_CLIENT);
export const requestGetClientList = createAction(constants.CLIENT_LIST);



