import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { auth, registerWithEmailAndPassword, signInWithFaceBook, signInWithGoogle, signInWithApple, sendEmailVerification, db, logInWithEmailAndPassword, functions, httpsCallable } from "../../../Services/firebase";
import { updateProfile } from "firebase/auth";
import axios from "axios";
import { apiConfig } from "config/apiConfig";

// import * as Realm from "realm-web";

// const APP_ID = "navoto_realmapp-nelgm";
// const app = new Realm.App({ id: APP_ID });

function* setAuthUser(action) {
    yield put({
        type: requestSuccess(constants.AUTH_USER),
        payload: action.payload
    });
}

function* requestToSignIn(action) {
    const { onSuccess, onFail } = action.payload;
    try {
        yield put({
            type: requestPending(constants.SIGN_IN),
        })

        const { email, password } = action.payload.data;
        const user = yield logInWithEmailAndPassword(email, password );

        if (user) {
            onSuccess("Success");
            yield put({
                type: requestSuccess(constants.SIGN_IN),
                payload: user
            });
        }
        else onFail("Error !")
    } catch (err) {
        onFail(err);
        yield put({
            type: requestFail(constants.SIGN_IN),
        })
        throw err;
    }
}

function* requestToSignUp(action) {
    const { onSuccess, onFail } = action.payload;
    
    try {
        yield put({
            type: requestPending(constants.SIGN_UP),
        })
        const { name, email, password } = action.payload.data;

        // registeration with firebase
        const registeredUser = yield registerWithEmailAndPassword(name, email, password );
        // save user name on firebase auth
        yield updateProfile(registeredUser.user, { displayName: name });
        
        debugger;
        // add user to db
        // http://localhost:5001/navoto-crm/us-central1/app
        // const addUser = httpsCallable(functions, 'app/v1/save-user');
        // addUser({ firstName: name, lastName: name, email: email, password: password, role: "User" })
        // .then((result) => {
        //   debugger;
        // }).catch((error) => {
        //   debugger;
        // });
      

       // onSuccess("Success");
        
        yield put({
            type: requestSuccess(constants.SIGN_IN),
            payload: registeredUser.user
        });
    } catch (err) {
        onFail(err);

        yield put({
            type: requestFail(constants.SIGN_UP),
        })
        throw err;
    }
}

function* requestToFetchUser(action) {
    // const { onSuccess, onFail } = action.payload;
    // try {
    //     yield put({
    //         type: requestPending(constants.FETCH_USER),
    //     })

    //     if (!app.currentUser) return onFail("Not logged In !");

    //     yield app.currentUser.refreshCustomData();

    //     if (app.currentUser) {
    //         onSuccess(app.currentUser);
    //         yield put({
    //             type: requestSuccess(constants.FETCH_USER),
    //             payload: app.currentUser
    //         });
    //     }
    //     else onFail("Error !")
    // } catch (err) {
    //     onFail(err);
    //     yield put({
    //         type: requestFail(constants.FETCH_USER),
    //     })
    //     throw err;
    // }
}

function* requestToLogout(action) {
    // if (!app.currentUser) return false;
    // const { onSuccess, onFail } = action.payload;
    // try {
    //     yield put({
    //         type: requestPending(constants.LOG_OUT),
    //     })

    //     if (!app.currentUser) return onFail("Not logged In !");

    //     yield app.currentUser.logOut();

    //     onSuccess(app.currentUser);
    //     yield put({
    //         type: requestSuccess(constants.LOG_OUT),
    //         payload: app.currentUser
    //     });

    // } catch (err) {
    //     onFail(err);
    //     yield put({
    //         type: requestFail(constants.LOG_OUT),
    //     })
    //     throw err;
    // }
}

export default function* rootSaga() {
    yield takeLatest(constants.AUTH_USER, setAuthUser);
    yield takeLatest(constants.SIGN_IN, requestToSignIn);
    yield takeLatest(constants.SIGN_UP, requestToSignUp);
    yield takeLatest(constants.FETCH_USER, requestToFetchUser);
    yield takeLatest(constants.LOG_OUT, requestToLogout);
}
