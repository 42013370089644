import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetProjects, requestDeleteProject } from './Middleware/action';
import { requestGetClients } from 'components/clients/Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import ProjectTableHeader from './ProjectTableHeader';
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";
import ServerSideAdvanceTablePagination from 'components/permissions/ServerSideAdvanceTablePagination';
import { useForm, Controller } from "react-hook-form";

const Projects = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const [allProjects, setAllProjects] = useState([]);
    const { requestInPending } = useSelector(store => store.project);
    let navigate = useNavigate();
    const [addProjectPermission, setAddProjectPermission] = useState(false);
    const [deleteProjectPermission, setDeleteProjectPermission] = useState(false);
    const [recordCount, setRecordCount] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [searchString, setSearchString] = useState("");
    const [allClients, setAllClients] = useState([]);

    const setSearchParam = (value) => {
        setSearchString((prev) => { return value });

        pageChangeHandler(1, value);
    }

    const { control, register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm();

    useEffect(() => {

        getProjects();

        if (loggedInUserPermission.length) {   //console.log(loggedInUserPermission[0].delete_user);

            setAddProjectPermission(loggedInUserPermission[0].add_project);
            setDeleteProjectPermission(loggedInUserPermission[0].delete_project)
        }
    }, []);


    const getProjects = async (searchValue = "") => {
        const onSuccess = (data) => {
            setAllProjects(data.projects);
            setRecordCount(data.recordCount);
        }

        const formData = { 'pageNo': pageNo, 'perPage': perPage, 'search': searchValue }

        console.log('formData..', formData);

        dispatch(requestGetProjects({ formData, onSuccess }))
    }

    const deleteProject = (projectId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`Project deleted successfully`, {
                    theme: 'colored'
                });

                getProjects();
            }
        }
        dispatch(requestDeleteProject({ projectId, onSuccess }))
    }

    const pageChangeHandler = (pageNo, searchValue = "") => {

        setPageNo(pageNo);
        getProjects(searchValue);
    }

    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {

        const onSuccess = (data) => {
            setAllClients(data.clients);
        }
        dispatch(requestGetClients({ onSuccess }))
    }

    console.log('allProjects', allProjects);

    const columns = [
        {
            accessor: 'projectName',
            Header: 'Project Name',
        },
        {

            accessor: 'clientName',
            Header: 'Client Name',
            disableSortBy: true,
            Cell: (rowData) => {
                const { user } = rowData.row.original;
                console.log("user..", user);

                if (user !== undefined && user.length) {  //return "";  
                    return user[0].firstName + ' ' + user[0].lastName;
                }
                return "";

            }
        },
        {
            accessor: 'startDate',
            Header: 'Start Date',
            disableSortBy: true,
            Cell: (rowData) => {
                const { startDate } = rowData.row.original;
                console.log("startDate..", startDate)
                let startDateFormatted = '';

                if (startDate) {
                    let objectDate = new Date(startDate);

                    let day = objectDate.getDate();
                    let month = objectDate.getMonth() + 1;
                    let year = objectDate.getFullYear();

                    startDateFormatted = (day < 10 ? '0' + day : day) + '-' + (month < 10 ? '0' + month : month) + '-' + year;
                }

                return startDateFormatted;
            }
        },
        {
            accessor: 'endDate',
            Header: 'End Date',
            disableSortBy: true,
            Cell: (rowData) => {
                const { endDate } = rowData.row.original;

                let endDateFormatted = '';

                if (endDate) {
                    let objectDate = new Date(endDate);

                    let day = objectDate.getDate();
                    let month = objectDate.getMonth() + 1;
                    let year = objectDate.getFullYear();

                    endDateFormatted = (day < 10 ? '0' + day : day) + '-' + (month < 10 ? '0' + month : month) + '-' + year;
                }

                return endDateFormatted;
            }
        },
        {
            accessor: 'client.accountDetail.bussinessName',
            Header: 'Bussiness Name',
            disableSortBy: true,

        },
        {
            accessor: 'createdAt',
            Header: 'Created At',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;

                let objectDate = new Date(createdAt);

                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();

                let invoiceDate = (day < 10 ? '0' + day : day) + '-' + (month < 10 ? '0' + month : month) + '-' + year;
                return invoiceDate;
            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;

                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                                {addProjectPermission && (
                                    <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/projects/edit/" + _id) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}
                                {deleteProjectPermission && (
                                    <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteProject(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>

                                )}
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/projects/tasks/" + _id) }}><FontAwesomeIcon icon="list" /> Tasks</Dropdown.Item>
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allProjects}
            selection
            sortable
            pagination
            perPage={perPage}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ProjectTableHeader table addProjectPermission={addProjectPermission} register={register} control={control} pageChangeHandler={pageChangeHandler} getValues={getValues} setValue={setValue} setSearchParam={setSearchParam} clients={allClients} />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <ServerSideAdvanceTablePagination pageChangeHandler={pageChangeHandler} totalRows={recordCount} rowsPerPage={perPage} pageNo={pageNo} setPageNo={setPageNo}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Projects;
