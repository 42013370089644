import { call, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetRoles(action) {

    yield call(
        request({
            type: constants.GET_ROLES,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `roles`,
            // data: action.payload.roles,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToGetRole(action) {

    yield call(
        request({
            type: constants.GET_ROLE,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `roles/${action.payload.roleId}`,
            // data: action.payload.role,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestToAddRole(action) {
    yield call(
        request({
            type: constants.ADD_ROLE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `roles/add`,
            data: {role:action.payload.formData},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestToUpdateRole(action) {
    yield call(
        request({
            type: constants.EDIT_ROLE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `roles/edit`,
            data: {role:action.payload.data},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteRole(action) { console.log('payoad...',action.payload);
    yield call(
        request({
            type: constants.DELETE_ROLE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `roles/delete`,
            data: {roleId:action.payload.role},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

export default function* rootSaga() {
    yield takeLatest(constants.GET_ROLES, requestToGetRoles);
    yield takeLatest(constants.GET_ROLE, requestToGetRole);
    yield takeLatest(constants.ADD_ROLE, requestToAddRole);
    yield takeLatest(constants.EDIT_ROLE, requestToUpdateRole);
    yield takeLatest(constants.DELETE_ROLE, requestToDeleteRole);

}
