import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
//import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeletePermission, requestGetPermissions } from './Middleware/action';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PermissionsTableHeader from './PermissionsTableHeader';
import { toast } from 'react-toastify';
import {STATUS} from '../../enum/enum.js';
import { useOutletContext } from "react-router-dom";
import  ServerSideAdvanceTablePagination from './ServerSideAdvanceTablePagination';
import { useForm, Controller } from "react-hook-form";

const Permissions = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);
    const { permissions, requestInPending, requestInSuccess } = useSelector(store => store.permission);
    let navigate = useNavigate();
    const [allPermissions, setAllPermissions] = useState([]);
    const [addPermission, setAddPermission] = useState(false);
    const [recordCount,setRecordCount]=useState(0);
    const [perPage,setPerPage]=useState(20);
    const [pageNo,setPageNo]=useState(1);
    const [searchString,setSearchString]=useState("");

    const setSearchParam = (value) =>{
        setSearchString((prev)=> {return value});

        pageChangeHandler(1, value);
    }

    const {control, register, handleSubmit, setValue, getValues,reset, formState: { errors } } = useForm();

    useEffect(() => {

        getPermissions();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddPermission(loggedInUserPermission[0].add_permission);
        }
    }, []);

    useEffect(() => {
        if (permissions && permissions.length > 0) {
            setAllPermissions(permissions);
        }
    }, [])

    const getPermissions = async (searchValue="") => {
        const onSuccess = (data) => {
            setAllPermissions(data.permissions);
            setRecordCount(data.recordCount);
        }

        const formData ={'pageNo':pageNo,'perPage':perPage,'name':searchValue} 

        console.log('formData..',formData);
        
        dispatch(requestGetPermissions({ formData,onSuccess }))
    }

    const deletePermission = (permissionId) => {
        const onSuccess = (data) => {
            if (data.success) {

                toast.success(`Permission deleted successfully`, {
                    theme: 'colored'
                });

                getPermissions();
            }
        }
        dispatch(requestDeletePermission({ permissionId, onSuccess }))
    }

    const pageChangeHandler=(pageNo, searchValue="")=>{
       
        setPageNo(pageNo);
        getPermissions(searchValue);
    }

    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
            disableSortBy: true,
        },
        {
            accessor: 'slug',
            Header: 'Slug',
            disableSortBy: true,
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                let statusText =STATUS[status];

                return statusText;
                
            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
            className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                                {/*<Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/permissions/edit/" + _id) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                               <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deletePermission(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>*/}
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allPermissions}
            selection
            sortable
            pagination
            perPage={perPage}
        >
            <Card className="mb-3">
                <Card.Header>
                    <PermissionsTableHeader table addPermission={addPermission} register={register} control={control} pageChangeHandler={pageChangeHandler} getValues={getValues} name={searchString} setSearchParam={setSearchParam}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {requestInPending ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <ServerSideAdvanceTablePagination pageChangeHandler={pageChangeHandler} totalRows={recordCount} rowsPerPage={perPage} pageNo={pageNo} setPageNo={setPageNo}  
                        />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Permissions;
