import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, Row, Button, Image } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { toast } from 'react-toastify';
import { addMonths, subMonths, startOfMonth, endOfMonth } from 'date-fns';

const DownloadInvoicesModal = ({ show, handleClose, handleDownload, control, getValues, setValue, downloading }) => {

    const [activeBtn, setActiveBtn] = useState(1);
    const search = () => {
        const dateRange = getValues("dateRange");
        if (dateRange && dateRange[0] && dateRange[1]) {
            handleDownload(dateRange);
        } else {
            toast('Please select date range for filter', { type: "error" });
        }
    }

    useEffect(() => {
        currentMonth();
    }, [])

    const setDateRange = (startDate, endDate) => {
        setValue("dateRange", [startDate, endDate]);
    };


    const currentMonth = () => {
        const start = startOfMonth(new Date());
        const end = endOfMonth(new Date());
        setDateRange(start, end);
        setActiveBtn(1);
    };

    const previousMonth = () => {
        const start = startOfMonth(subMonths(new Date(), 1));
        const end = endOfMonth(subMonths(new Date(), 1));
        setDateRange(start, end);
        setActiveBtn(2);
    };

    const lastThreeMonths = () => {
        const start = startOfMonth(subMonths(new Date(), 3));
        const end = endOfMonth(new Date());
        setDateRange(start, end);
        setActiveBtn(3);
    };

    const lastSixMonths = () => {
        const start = startOfMonth(subMonths(new Date(), 6));
        const end = endOfMonth(new Date());
        setDateRange(start, end);
        setActiveBtn(4);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header
                closeButton
                closeVariant={null}
                className="border-200"
            >
                <Modal.Title as="h5">
                    <Flex alignItems="center">
                        Select date range
                    </Flex>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="d-flex justify-content-between mb-3">
                    <Button variant="outline-primary" onClick={currentMonth} className={activeBtn == 1 ? "active" : ""}>Current Month</Button>
                    <Button variant="outline-primary" onClick={previousMonth} className={activeBtn == 2 ? "active" : ""}>Previous Month</Button>
                    <Button variant="outline-primary" onClick={lastThreeMonths} className={activeBtn == 3 ? "active" : ""}>Last 3 Months</Button>
                    <Button variant="outline-primary" onClick={lastSixMonths} className={activeBtn == 4 ? "active" : ""}>Last 6 Months</Button>
                </div>

                <Controller
                    control={control}
                    name="dateRange"
                    render={({ field }) => (
                        <DatePicker
                            {...field}
                            selectsRange
                            autoComplete='off'
                            startDate={field.value && field.value[0]}
                            endDate={field.value && field.value[1]}
                            dateFormat="dd/MM/yyyy"
                            className='form-control'
                            onChange={(dates) => {
                                const [start, end] = dates;
                                field.onChange([start, end]);
                            }}
                            ref={(ref) => {
                                field.ref({
                                    focus: ref?.setFocus
                                });
                            }}
                        />
                    )}
                />
            </Modal.Body>
            <Modal.Footer className="border-200 d-block">
                <IconButton
                    size="sm"
                    onClick={search}
                    icon="download"
                    variant="primary"
                    iconAlign="right"
                    disabled={downloading}
                >
                    {downloading ? 'Downloading...' : 'Download'}
                </IconButton>
                <Button
                    size="sm"
                    className="ms-2"
                    variant="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DownloadInvoicesModal;
