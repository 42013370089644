import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";
import { functions, getAppCheckToken, httpsCallable } from "../../../Services/firebase";


function* requestToGetInvoices(action) {

    yield call(
        request({
            type: constants.GET_INVOICES,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            //url: `invoice/${action.payload.clientId || ""}`,
            url: `invoice`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToAddInvoice(action) {
    yield call(
        request({
            type: constants.ADD_INVOICE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/add`,
            data: { 'invoice': action.payload.formData },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToEditInvoice(action) {
    yield call(
        request({
            type: constants.ADD_INVOICE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/edit`,
            data: { 'invoice': action.payload.formData },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToSendInvoice(action) {

    yield call(
        request({
            type: constants.SEND_INVOICE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/send`,///${action.payload.userId}
            data: action.payload,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToInvoiceDetail(action) {

    yield call(
        request({
            type: constants.INVOICE_DETAIL,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `invoice/detail?invoiceId=${action.payload.invoiceId}`,///${action.payload.userId}
            data: { invoiceId: action.payload.invoiceId },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToUpdatePaymentStatus(action) {

    yield call(
        request({
            type: constants.UPDATE_PAYMENT_STATUS,
            baseURL: apiConfig.API_PAYMENT_STATUS_UPDATE,
            method: "POST",
            redirect: 'follow',
            url: '',
            data: { sessionId: action.payload.sessionId },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteInvoice(action) {
    yield call(
        request({
            type: constants.DELETE_INVOICE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/delete`,///${action.payload.userId}
            data: { invoiceId: action.payload.invoiceId },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestCancelInvoice(action) {
    yield call(
        request({
            type: constants.CANCEL_INVOICE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/cancel-invoice`,
            data: { invoiceId: action.payload.invoiceId, reason: action.payload.reason },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestMarkAsDone(action) {
    yield call(
        request({
            type: constants.MARK_DONE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/mark-complete-invoice`,
            data: { invoiceId: action.payload.invoiceId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* sendReciept(action) {
    yield call(
        request({
            type: constants.SEND_RECIEPT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `invoice/send-reciept`,
            data: { invoiceId: action.payload.invoiceId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestToGetReciept(action) {
    yield call(
        request({
            type: constants.GET_RECIEPT,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `receipts/detail?invoiceId=${action.payload.invoiceId}`,
            // data: { 'invoiceId': action.payload.invoiceId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestEditReciept(action) {
    yield call(
        request({
            type: constants.EDIT_RECIEPT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `receipts/edit`,
            data: { invoiceId: action.payload.invoiceId, recieptDate: action.payload.recieptDate },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


export default function* rootSaga() {
    yield takeLatest(constants.GET_INVOICES, requestToGetInvoices);
    yield takeLatest(constants.ADD_INVOICE, requestToAddInvoice);
    yield takeLatest(constants.EDIT_INVOICE, requestToEditInvoice);
    yield takeLatest(constants.INVOICE_DETAIL, requestToInvoiceDetail);
    yield takeLatest(constants.SEND_INVOICE, requestToSendInvoice);
    yield takeLatest(constants.UPDATE_PAYMENT_STATUS, requestToUpdatePaymentStatus);
    yield takeLatest(constants.DELETE_INVOICE, requestToDeleteInvoice);
    yield takeLatest(constants.CANCEL_INVOICE, requestCancelInvoice);
    yield takeLatest(constants.MARK_DONE, requestMarkAsDone);
    yield takeLatest(constants.SEND_RECIEPT, sendReciept);
    yield takeLatest(constants.GET_RECIEPT, requestToGetReciept);
    yield takeLatest(constants.EDIT_RECIEPT, requestEditReciept);

}
