const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_CLIENTS: undefined,
  GET_CLIENT: undefined,
  ADD_CLIENT: undefined,
  EDIT_CLIENT: undefined,
  DELETE_CLIENT: undefined,
  CLIENT_LIST:undefined
});
