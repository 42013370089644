import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";

const InvoiceTableHeader = ({ selectedRowIds, clientId, addInvoicePermission, clientName, register, control, pageChangeHandler, getValues, setValue, setSearchParam, handleDownloadInvoices }) => {

  let navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSearch, setIsSearch] = useState(true);
  const showHideFilter = () => {
    showFilter ? setShowFilter(false) : setShowFilter(true);
  }

  const search = () => {
    setIsSearch(true);
    const name = getValues("name");
    const dateRange = getValues("dateRange");

    let searchString = { name: name }

    if (dateRange && dateRange[0] && !dateRange[1]) {
      setIsSearch(false);
      setErrorMessage('Please select date range for filter');
    }
    else {
      searchString.dateRange = dateRange;
      setErrorMessage('');
    }

    if (isSearch) {
      setSearchParam(searchString);
    }

  }

  const resetSearch = () => {
    setValue("name", "");
    setValue('dateRange', '');
    let searchString = ""
    setIsSearch(true);
    debugger
    setSearchParam(searchString);
    setErrorMessage('');
  }

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">All Invoices {clientName && (`- ${clientName}`)}</h5>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length > 0 ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              {addInvoicePermission && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={(e) => { e.preventDefault(); navigate(clientId ? `/clients/${clientId}/invoice/add` : "/invoice/add") }}
                >
                  <span className="d-none d-sm-inline-block ms-1" >New</span>
                </IconButton>
              )}
              {!clientName && (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="filter"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={showHideFilter}
                >
                  <span className="d-none d-sm-inline-block ms-1">Filter</span>
                </IconButton>)}

                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="download"
                  transform="shrink-3"
                  className="mx-2"
                  onClick={handleDownloadInvoices}
                >
                  <span className="d-none d-sm-inline-block ms-1">Download invoices</span>
                </IconButton>                
            </div>
          )}
        </Col>
      </Row>
      {showFilter && (
        <>
          {errorMessage && (<Row className="mb3">
            <Col className="alert alert-danger" xs={12}>
              {errorMessage}
            </Col>
          </Row>)
          }
          <Row>
            <Col xs={4}>
              <Col>
                <Form.Label>Client Name or Email</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register('name')}
                />
              </Col>
            </Col>
            <Col xs={4}>
              <Col>
                <Form.Label>Date Range</Form.Label>
              </Col>
              <Col>
                <Controller
                  control={control}
                  name="dateRange"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selectsRange
                      autoComplete='off'
                      startDate={field.value && field.value[0]}
                      endDate={field.value && field.value[1]}
                      dateFormat="dd/MM/yyyy"
                      className='form-control'
                      onChange={(dates) => {
                        const [start, end] = dates;
                        field.onChange([start, end]);
                      }}
                      ref={(ref) => {
                        field.ref({
                          focus: ref?.setFocus
                        });
                      }}
                    />
                  )}
                />
              </Col>
            </Col>
            <Col xs={2} >
              <Col><Form.Label>&nbsp;</Form.Label></Col>
              <Col>
                <Button variant="primary" type="button" onClick={search}>Search</Button>
              </Col>
            </Col>
            <Col xs={2}>
              <Col><Form.Label>&nbsp;</Form.Label></Col>
              <Col>
                <Button variant="secondary" type="button" onClick={resetSearch}>Reset</Button>
              </Col>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

InvoiceTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default InvoiceTableHeader;
