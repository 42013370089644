import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import LeadsTableHeader from './LeadsTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestGetLeads } from './Middleware/action';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Leads = () => {
  // const [customers] = useState(customersData);
  const dispatch = useDispatch();
  const { leads, requestInPending, requestInSuccess } = useSelector(store => store.lead);

  const [allLeads, setLeads] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {

    setLoading(true);
    if (leads && leads.length > 0) {
      setLoading(false);
      setLeads(leads);
  }
  }, [])

  const getLeads = async () => {
    const onSuccess = (data) => {
      setLeads(data.leads)
    }
    dispatch(requestGetLeads({ onSuccess }))
  }

  const columns = [
    {
      accessor: 'fullName',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { fullName, avatar } = rowData.row.original;
        return (
          <Link to="/e-commerce/customer-details">
            <Flex alignItems="center">
              {/* {avatar.img ? (
                <Avatar src={avatar.img} size="xl" className="me-2" />
              ) : (
                <Avatar size="xl" name={avatar.name} className="me-2" />
              )} */}
              <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              <div className="flex-1">
                <h5 className="mb-0 fs--1 ps-2 text-ellipsis">{fullName}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'phone',
      Header: 'Phone',
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <div className='text-ellipsis'> <a href={`tel:${phone}`}>{phone}</a></div>;
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      // Cell: rowData => {
      //   const { email } = rowData.row.original;
      //   return <a href={`mailto:${email}`}>{email}</a>;
      // }
    },
    {
      accessor: 'comment',
      Header: 'Comments',
      headerProps: { style: { minWidth: '200px' }, className: 'ps-5' },
      cellProps: { className: 'ps-5' },
      Cell: (rowData) => {
        const { comment } = rowData.row.original;
        return <div className='text-ellipsis'>{comment}</div>
      }
    },
    {
      accessor: 'waStatus',
      Header: 'Whatsapp status',
      Cell: rowData => {
        const { waStatus } = rowData.row.original;
        return <div>{waStatus ? 'Sent' : 'Not sent'}</div>;
      }
    },
    {
      accessor: 'emailStatus',
      Header: 'Email status',
      Cell: rowData => {
        const { emailStatus } = rowData.row.original;
        return <div>{emailStatus ? 'Sent' : 'Not sent'}</div>;
      }
    },
    {
      accessor: 'txtMsgStatus',
      Header: 'Message status',
      Cell: rowData => {
        const { txtMsgStatus } = rowData.row.original;
        return <div>{txtMsgStatus ? 'Sent' : 'Not sent'}</div>;
      }
    },
    {
      accessor: 'linkVisited',
      Header: 'Link Visited',
      Cell: rowData => {
        const { linkVisited } = rowData.row.original;
        return <div>{linkVisited ? 'Visited' : 'Not visited'}</div>;
      }
    },
    {
      accessor: 'visitedFrom',
      Header: 'Link Visited From'
      // Cell: rowData => {
      //   const { linkVisited } = rowData.row.visitedFrom;
      //   return <div>{linkVisited ? 'Visited' : 'Not visited'}</div>;
      // }
    },
    {
      accessor: 'joined',
      Header: 'Status',
      headerProps: { style: { minWidth: '150px' }, className: 'ps-5' },
      Cell: () => {
        return (
          <Form.Select aria-label="Default select example" className="select-custom">
            <option>Status</option>
            <option value="1">connected</option>
            <option value="2">Not connected</option>
            <option value="3">On Hold</option>
          </Form.Select>

        )
      }
    },
    {
      accessor: 'none',
      Header: 'Action',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: () => {
        return (
          <Flex>
            <DropdownButton id="custom-dropdown" title="Send to" className='custom-btn'>
              <Dropdown.Item as="button"><FontAwesomeIcon icon="paper-plane" /> Send to Designer</Dropdown.Item>
              <Dropdown.Item as="button"><FontAwesomeIcon icon="paper-plane" /> Send to Developer</Dropdown.Item>
            </DropdownButton>

            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/leads/3") }}><FontAwesomeIcon icon="info-circle" /> View Details</Dropdown.Item>
                <Dropdown.Item href="#!"><FontAwesomeIcon icon="comments" /> Chat</Dropdown.Item>
                <Dropdown.Item href="#!"><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        );
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={allLeads}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <LeadsTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          {isLoading ?
            <span className="spinner-grow spinner-grow-sm ms-3"></span>
            :
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          }
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Leads;
