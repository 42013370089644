import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";
import { functions, getAppCheckToken, httpsCallable } from "../../../Services/firebase";


function* requestToGetLeaves(action) {
        
    yield call( 
        request({
            type: constants.GET_LEAVES,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `leaves/`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetLeave(action) {

    yield call(
        request({
            type: constants.GET_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `leaves/${action.payload.leaveId}`,
            //data: {'leaveId':action.payload.leaveId},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToAddLeave(action) { 
    yield call(
        request({ 
            type: constants.ADD_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `leaves/add`,
            data: {'leave':action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToEditLeave(action) {
    yield call(
        request({
            type: constants.EDIT_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `leaves/edit`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteLeave(action) {
    yield call(
        request({
            type: constants.DELETE_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `leaves/delete`,
            data: {leaveId: action.payload.leaveId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestAcceptRejectLeave(action) {
    yield call(
        request({
            type: constants.ACCEPT_REJECT_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `leaves/accept-reject`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

export default function* rootSaga() {
    yield takeLatest(constants.GET_LEAVES, requestToGetLeaves);
    yield takeLatest(constants.ADD_LEAVE, requestToAddLeave);
    yield takeLatest(constants.EDIT_LEAVE, requestToEditLeave);
    yield takeLatest(constants.DELETE_LEAVE, requestToDeleteLeave);
    yield takeLatest(constants.GET_LEAVE, requestToGetLeave);
    yield takeLatest(constants.ACCEPT_REJECT_LEAVE, requestAcceptRejectLeave);
}
