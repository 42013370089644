import { initializeApp } from "firebase/app";
import * as firebase from "firebase/app";
import { getStorage } from "firebase/storage";

import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    updateProfile,
    updatePassword,
    confirmPasswordReset,
    fetchSignInMethodsForEmail
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { apiConfig } from "config/apiConfig";
import { _clearLocalStorage } from "./localStorageService";
import { getToken, initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = apiConfig.dbConfig;

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHASITEKEY),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
  
  const getAppCheckToken = async () => {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);

        return appCheckTokenResponse.token
    } catch (err) {
        // Handle any errors if the token was not retrieved.
        return;
    }


  
    // // Include the App Check token with requests to your server.
    // const apiResponse = await fetch('https://yourbackend.example.com/yourApiEndpoint', {
    //     headers: {
    //         'X-Firebase-AppCheck': appCheckTokenResponse.token,
    //     }
    // });
}
  

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
// auth.setPersistence(firebase)
const storage = getStorage(app);

const logInWithEmailAndPassword = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
};


const registerWithEmailAndPassword = async (name, email, password) => {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    //await updateProfile({ "displayName": name });
    return user;
};

const fetchSignInByEmail = async (email) => {
    const isExist = await fetchSignInMethodsForEmail(email);
    return isExist;
}

// const modifyProfile = async () =>{
//     await updateProfile({ displayName: 'jitendra', emailVerified: true });
// }

const sendPasswordReset = async (email) => {
    return await sendPasswordResetEmail(auth, email);
};

const changePassword = async (oobCode, newPassword) => {
    return await confirmPasswordReset(auth, oobCode, newPassword);
};

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    return await signInWithPopup(auth, googleProvider);
};

const facebookProvider = new FacebookAuthProvider();
const signInWithFaceBook = async () => {
    return await signInWithPopup(auth, facebookProvider);
    // const user = res.user;
}

const appleProvider = new OAuthProvider('apple.com');
appleProvider.setCustomParameters({
    locale: 'en'
});

const signInWithApple = async () => {
    return await signInWithPopup(auth, appleProvider);
    // const user = res.user;
}

const logout = async () => {
    await signOut(auth);
    _clearLocalStorage();
};

const fetchLoggedInUser = async () =>{
var promise = new Promise((resolve, reject) => {
  auth.onAuthStateChanged(function(user) {
      resolve(user);
 })
})
return promise

}

export {
    auth,
    db,
    functions,
    storage,
    httpsCallable,
    signInWithGoogle,
    signInWithFaceBook,
    signInWithApple,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
    sendEmailVerification,
    changePassword,
    fetchSignInByEmail,
    getAppCheckToken,
    fetchLoggedInUser,
    // modifyProfile
};
