import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';

const Questionnaire = () => {

    const [formData, setFormData] = useState({
        projectTitle: '',
        website: '',
        withDesign: '',
        colors: '',
        competitorWebsite: '',

    });

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <div className='bg-light p-3 questionnaire-form'>

            <Form onSubmit={handleSubmit}>

                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={12} controlId="projectTitle">
                        <Form.Label> Project Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Surfing Website"
                            value={formData.projectTitle}
                            name="projectTitle"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="website">
                        <Form.Label> Do you currently have a website?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="www.surfingnow.com"
                            value={formData.website}
                            name="website"
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="technology">
                        <Form.Label> Technology</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="technology"
                            value={formData.website}
                            name="technology"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="withDesign">
                        <Form.Label>Do you have old logo? </Form.Label>
                        <Form.Select value={formData.withDesign} name="withDesign" onChange={handleChange}>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} lg={6} controlId="withDesign">
                        <Form.Label>Are there any websites with designs that you like? </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No"
                            value={formData.withDesign}
                            name="withDesign"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>

                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="colors">
                        <Form.Label>Do you like any colors?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Blue, Red"
                            value={formData.colors}
                            name="colors"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="competitorWebsite">
                        <Form.Label>Do you have your competitor website?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No Answer"
                            value={formData.competitorWebsite}
                            name="competitorWebsite"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>

                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="targetAudience">
                        <Form.Label>Who is your target audience?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Youngers"
                            value={formData.targetAudience}
                            name="targetAudience"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="mainCompetitors">
                        <Form.Label>Who are your main competitors?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No Answer"
                            value={formData.mainCompetitors}
                            name="mainCompetitors"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="websiteHosted">
                        <Form.Label>Where is your website hosted?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="go daddy"
                            value={formData.websiteHosted}
                            name="websiteHosted"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="fullAccess">
                        <Form.Label>Do you have full access?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="You"
                            value={formData.fullAccess}
                            name="fullAccess"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="userNamePassword">
                        <Form.Label>Can you provide usernames and passwords?</Form.Label>
                        <Form.Select onChange={handleChange} name="userNamePassword" value={formData.userNamePassword}>
                            <option>Yes</option>
                            <option>No</option>
                        </Form.Select>

                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="otherWebsite">
                        <Form.Label>Any other contractors?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No Answer"
                            value={formData.otherWebsite}
                            name="otherWebsite"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="budget">
                        <Form.Label>Do you have a budget you are trying to meet?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No Answer"
                            value={formData.budget}
                            name="budget"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="withAccess">
                        <Form.Label>Can you provide us with access?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No Answer"
                            value={formData.withAccess}
                            name="withAccess"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6} controlId="contactNumber">
                        <Form.Label>Do you have other contact number?</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="No"
                            value={formData.contactNumber}
                            name="contactNumber"
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Form.Group as={Col} lg={6} controlId="contactEmail">
                        <Form.Label>Do you have other email?</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="rickysupport@gmail.com"
                            value={formData.contactEmail}
                            name="contactEmail"
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>

                <Form.Group className="mb-3" controlId="projectNotes">
                    <Form.Label>Project Notes</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ...
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled Lorem Ipsum has been the industry's."
                        value={formData.projectNotes}
                        name="projectNotes"
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                    <div className="d-grid gap-2">

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form.Group>
            </Form>
        </div>
    );
}

export default Questionnaire;