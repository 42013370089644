import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestGetLogs } from './Middleware/action';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LogsTableHeader from './LogsTableHeader';
import { toast } from 'react-toastify';

const Logs = () => {
    const dispatch = useDispatch();

    const { logs } = useSelector(store => store.log);

    const [allLogs, setAllLogs] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getLogs();
    }, []);

    useEffect(() => {
        setLoading(true);
        if (logs && logs.length > 0) {
            setLoading(false);

            setAllLogs(logs);
        }
    }, [])

    const getLogs = async () => {
        const onSuccess = (data) => {
            setLoading(false);

            setAllLogs(data.logs);
        }
        dispatch(requestGetLogs({ onSuccess }))
    }

    const columns = [

        {
            accessor: 'createdAt',
            Header: 'Created',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;
                return new Date(createdAt).toLocaleString();
            }
        },
        {
            accessor: 'error.stack',
            Header: 'Error Description',
            // Cell: (rowData) => {
            //     const { createdAt } = rowData.row.original;
            //     return createdAt?.split("T")[0];
            // }
        }
    ];

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allLogs}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <LogsTableHeader table />
                </Card.Header>
                <Card.Body className="p-0">
                    {isLoading ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Logs;
