import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.GET_LOGS)]: (
            state,
            action
        ) => ({
            ...state,
            logs: action.payload.logs,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_LOGS)]: (
            state
        ) => ({
            ...state,
            logs: [],
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_LOGS)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    initialState()
);
