import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';
import React, { useContext, useState ,useEffect} from 'react';
import { Collapse, Nav } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { subscribe } from 'Services/events';

const CollapseItems = ({ route }) => {

  const { pathname } = useLocation();

  const openCollapse = childrens => {
    const checkLink = children => {
      if (children.to === pathname) {
        return true;
      }
      return (
        Object.prototype.hasOwnProperty.call(children, 'children') &&
        children.children.some(checkLink)
      );
    };
    return childrens.some(checkLink);
  };

  const [open, setOpen] = useState(openCollapse(route.children));
  
  return (
    <Nav.Item as="li" >
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer', {
          'text-500': !route.active
        })}
        aria-expanded={open}
        // {...route}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open} className="bg-light">
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool
  }).isRequired
};

const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

const [userPermission, setUserPermission] = useState([]);
useEffect(() => {
    getLoggedInUserPermission();
  }, []);

  const getLoggedInUserPermission = () => {
    
    subscribe("userPermission", data => {
     
      setUserPermission(data.detail);

    });

  }

  return routes.map(route => {

    let isPermisionGranted=false;
    let routePermission = route.permission;

    //console.log('routePermission',routePermission,'userPermission..',userPermission);

    if(routePermission != undefined)
    {
      isPermisionGranted=routePermission.some(x=>userPermission[x]);

      if(isPermisionGranted)
      {
        if (!route.children) {
          
            return (
              <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
                <NavLink
                  end={route.exact}
                  to={route.to}
                  state={{ open: route.to === '/authentication-modal' }}
                  className={({ isActive }) =>
                    isActive && route.to !== '#!' ? ' nav-link' : 'nav-link' //active
                  }
                >
                  <NavbarVerticalMenuItem route={route} />
                </NavLink>
              </Nav.Item>
            );
        }
        return <CollapseItems route={route} key={route.name} />;
      }
    }
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired
};

export default NavbarVerticalMenu;
