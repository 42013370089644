import IconButton from "components/common/IconButton";
import { Card, Dropdown, DropdownButton, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ItemButton = ({ tootltip, icon, className, onClick }) => {
    return (
        <OverlayTrigger
            overlay={
                <Tooltip style={{ position: 'fixed' }} id="overlay-trigger-example">
                    {tootltip}
                </Tooltip>
            }
        >
            <div className="d-inline-block">
                <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon={icon}
                    className={className}
                    onClick={onClick}
                />
            </div>
        </OverlayTrigger>
    );
};
const LeadDetailHeader = () => {
    let navigate = useNavigate();

    return (
        <Card className="mb-3">
            <Card.Body as={Flex} justifyContent="between">
                <div>
                    <ItemButton
                        tootltip="Back to leads"
                        icon="arrow-left"
                        onClick={() => {
                            navigate('/leads');
                        }}
                    />
                </div>
                <Flex>

                    {/* <Form.Select
                        size="sm"
                        value={0}
                        onChange={({ target }) => {
                        }}
                        style={{ maxWidth: '10rem' }}
                    >
                        <option value={0}>Lead Send to</option>
                        <option value={2}>Send to Designer</option>
                        <option value={4}>Send to Developer</option>
                    </Form.Select> */}
                    <Dropdown className="lead-send-btn">
                        <Dropdown.Toggle variant="falcon-default">
                            Lead Send to
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='py-2'>
                            <Dropdown.Item as="button">Send to Designer</Dropdown.Item>
                            <Dropdown.Item as="button">Send to Developer</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown className="font-sans-serif" align="end">
                        <Dropdown.Toggle
                            variant="falcon-default"
                            size="sm"
                            className="text-600 dropdown-caret-none ms-2"
                        >
                            <FontAwesomeIcon icon="ellipsis-v" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-2">
                            <Dropdown.Item href="#/action-1">Configure inbox</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Themes</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-4">Send feedback</Dropdown.Item>
                            <Dropdown.Item href="#/action-4">Help</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Flex>
            </Card.Body>
        </Card>
    )
}

export default LeadDetailHeader;