import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";
import { functions, getAppCheckToken, httpsCallable } from "../../../Services/firebase";

function* requestToGetTasks(action) {
        
    yield call( 
        request({
            type: constants.GET_TASKS,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks`, 
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetTask(action) {

    yield call(
        request({
            type: constants.GET_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `tasks/${action.payload.taskId}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToAddTask(action) { 
    
    yield call(
        request({ 
            type: constants.ADD_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/add`,
            data: {'task':action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToEditTask(action) {
    yield call(
        request({
            type: constants.EDIT_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/edit`,
            data: {'task':action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeleteTask(action) {
    yield call(
        request({
            type: constants.DELETE_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/delete`,
            data: { taskId: action.payload.taskId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToChangeTaskStatus(action) { console.log(action.payload);
    
    yield call(
        request({
            type: constants.DELETE_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/change-task-status`,
            data: {taskId:action.payload.taskId,taskStatus:action.payload.taskStatus},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToChangeTaskRunningStatus(action) { console.log(action.payload);
    
    yield call(
        request({
            type: constants.DELETE_TASK,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/change-task-running-status`,
            data: {taskId:action.payload.taskId},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetTasksForTimeline(action) {
        
    yield call( 
        request({
            type: constants.GET_TASKS,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `tasks/get-tasks-for-timeline`, 
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(constants.GET_TASKS, requestToGetTasks);
    yield takeLatest(constants.GET_TASK, requestToGetTask);
    yield takeLatest(constants.ADD_TASK, requestToAddTask);
    yield takeLatest(constants.EDIT_TASK, requestToEditTask);
    yield takeLatest(constants.DELETE_TASK, requestToDeleteTask);
    yield takeLatest(constants.CHANGE_TASK_STATUS, requestToChangeTaskStatus);
    yield takeLatest(constants.CHANGE_TASK_RUNNING_STATUS, requestToChangeTaskRunningStatus);
    yield takeLatest(constants.GET_TASKS_FOR_TIMELINE, requestToGetTasksForTimeline);
}
