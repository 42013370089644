import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestEditTask, requestAddTask,requestGetTask } from "./Middleware/action";
import { useState } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetProjects} from 'components/projects/Middleware/action';
import { requestGetEmployees } from 'components/employees/Middleware/action';
import { PRIORITY,TASK_TIME_TYPE } from 'enum/enum';
import DatePicker from "react-datepicker";
import {fixTimezoneOffset} from '../../helpers/custom';

const AddUpdateTask = () => {

    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingProject, setIsLoadingProject] = useState(false);
    const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);
    const [isLoadingTask, setIsLoadingTask] = useState(false);
    const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [allProject, setAllProject] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [taskAssignedDate, setTaskAssignedDate] = useState(null);
    let navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
          getTask();
        }
        else
        {
            setIsLoadingTask(true);
        }
    }, [id]);

    useEffect(() => { 
        getProjects();
        getEmployees();

    }, [id]);

    const getProjects=()=>{ 

        setIsLoadingProject(false);
       
        const onSuccess = (data) => { 
           
            if (data.success) { 

                setAllProject(data.projects);
                setIsLoadingProject(true);
            }
        }
        dispatch(requestGetProjects({ onSuccess }))
    }

    const getEmployees=()=>{ 

        setIsLoadingEmployee(false);
       
        const onSuccess = (data) => { 
           
            if (data.success) { 

                setEmployee(data.employees);
                setIsLoadingEmployee(true);
            }
        }
        dispatch(requestGetEmployees({ onSuccess }))
    }

    const getTask = () => {
        setIsLoadingTask(false);
        const onSuccess = (data) => { 
        setIsLoadingTask(true);
        console.log('data..',data);
            const task = data.task; console.log("task.projectId",task.projectId);
            setValue('projectId', task.projectId);
            setValue('taskName', task.taskName);
            setValue('assignedTo', task.assignedTo);
            setValue('priority', task.priority);
            setValue('assignedHours', task.assignedHours);
            setValue('taskTimeType', task.taskTimeType);
            
            if(task.taskAssignedDate !== '')
            {
                const assignedDate = new Date(task.taskAssignedDate);
                setTaskAssignedDate(assignedDate);
                setValue('taskAssignedDate', assignedDate);
            }
        }
        dispatch(requestGetTask({ taskId: id, onSuccess }))
    }

    const onSubmit = (formData) => {
        saveTaskData(formData);
    }

    const saveTaskData = (formData) => { console.log("formdata..",formData);
        setIsSubmitting(true);
        const onSuccess = (data) => {
            setIsSubmitting(false);

            if (data.success) {
                toast.success(`Task ${id? 'updated':  'added'} successfully`, {
                    theme: 'colored'
                });

                navigate("/tasks");
            }
        }

        formData.taskAssignedDate = fixTimezoneOffset(formData.taskAssignedDate);

        const onFail = (err) => {
            setIsSubmitting(false);
            toast.error(err.response.data.message);
        }
    
        if (id) 
        {   console.log("edit...");
            formData.taskId = id;
            
            dispatch(requestEditTask({ formData, onSuccess, onFail }))
        } else
        {  
            dispatch(requestAddTask({ formData, onSuccess, onFail }))
        }
    }



    console.log("TaskAssignedDate..",taskAssignedDate);

  return (
    <>
     {  isLoadingProject && isLoadingEmployee && isLoadingTask && (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={12}>
            <Card>
              <FalconCardHeader title={ id? 'Edit Task': 'Add Task'}  />
                <Card.Body className="bg-light">
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="projectId">
                            <Form.Label>Project</Form.Label>
                                { isLoadingProject && (
                                <>
                                 <Form.Select
                                  {...register('projectId', { required: true })}
                                >
                                  <option value="">Project</option>
                                  { isLoadingProject && allProject.map( (project, i) => <option key={i} value={project._id.valueOf()}>{project.projectName} ({ project.user[0]!=undefined && project.user[0].firstName} {project.user[0] !=undefined && project.user[0].lastName})</option>)}
                            </Form.Select>
                            </>
                            )}
                            <div className="invalid-feedback" style={{ display: errors.projectId ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="taskName">
                            <Form.Label>Task</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Task"
                                {...register('taskName', { required: true })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.taskName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="assignedTo">
                            <Form.Label>Assign To</Form.Label>
                                <Form.Select
                                  {...register('assignedTo', { required: true })}
                                >
                                  <option value="">Person</option>
                                  { isLoadingEmployee && employee.map( (emp, i) => <option key={i} value={emp.userId}>{emp.firstName} {emp.lastName}</option>)}
                            </Form.Select>
                            <div className="invalid-feedback" style={{ display: errors.projectId ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="priority">
                            <Form.Label>Priority</Form.Label>
                                <Form.Select
                                  {...register('priority', { required: true })}
                                >
                                  <option value="">Priority</option>
                                    { Object.entries(PRIORITY).map(([key, val]) => {
                                      return  <option value={key} key={val}>{val}</option>
                                    })}
                            </Form.Select>
                            <div className="invalid-feedback" style={{ display: errors.projectId ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Date</Form.Label>
                            <Controller
                              control={control}
                              name="taskAssignedDate"
                              rules={{ required: true }}
                              selected={taskAssignedDate}
                              render={({ field }) => (
                              <DatePicker
                                  {...field}
                                  utcOffset={0}
                                  selected={taskAssignedDate}
                                  dateFormat="yyyy-MM-dd"
                                  className='form-control'
                                  onChange={(date)=>{
                                    
                                    field.onChange(date);
                                    setTaskAssignedDate(date)
                                  }}
                                />
                            )}
                            />
                            <div className="invalid-feedback" style={{ display: errors.taskAssignedDate ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Hour Assigned</Form.Label>
                            <Row>
                                <Col lg={8}>
                                    <Form.Control
                                      type="number"
                                      placeholder="Hours Assigned"
                                      {...register('assignedHours', { required:true})}
                                    />
                                    <div className="invalid-feedback" style={{ display: errors.assignedHours ? 'block' : 'none' }}>Field is required
                                    </div>
                                </Col>
                                <Col lg={4}>
                                   <Form.Select aria-label="taskTimeType" {...register('taskTimeType', { required: true })}>
                                      {Object.keys(TASK_TIME_TYPE).map((type, i) => {
                                        return (
                                          <option value={type} key={type}>{type}</option>
                                        )
                                      })}
                                     
                                    </Form.Select> 
                                </Col>
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6}>
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                            )}
                      {id? 'Update Task':'Create Task'}
                      </Button> 
                      </Form.Group>
                    </Row>
                    
                </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
      ) }
      {  !isLoadingProject || !isLoadingEmployee || !isLoadingTask && (
      
       <h1>Loading...</h1>
      )}
    </>
  );
};

export default AddUpdateTask;
