import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Tab, Tabs } from "react-bootstrap";
import Questionnaire from "./Questionnaire";

const DetailTabs = () => {

    return (
        <Card>
            <Tabs defaultActiveKey="questionnaire" id="uncontrolled-tab-example">
                <Tab eventKey="questionnaire" title={<span> <FontAwesomeIcon icon="file-alt" />  Questionnaire </span>} className='border-bottom border-x'>
                    <Questionnaire />
                </Tab>
                <Tab eventKey="chat" title={<span> <FontAwesomeIcon icon="comments" />  Chat </span>} className='border-bottom border-x p-3'>
                    <p>
                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic.
                    </p>
                </Tab>
                <Tab eventKey="timeline" title={<span> <FontAwesomeIcon icon="stream" />  Timeline </span>} className='border-bottom border-x p-3'>
                    <p>
                        Accuse me thus: that I have scanted all, Wherein I should your great deserts repay, Forgot
                        upon your dearest love to call, Whereto all bonds do tie me day by day; That I have frequent
                        been with unknown minds, And given to time your own dear-purchas'd right;
                    </p>
                </Tab>
                <Tab eventKey="notes" title={<span> <FontAwesomeIcon icon="file" />  Notes </span>} className='border-bottom border-x p-3'>
                    <p>
                        Accuse me thus: that I have scanted all, Wherein I should your great deserts repay, Forgot
                        upon your dearest love to call, Whereto all bonds do tie me day by day; That I have frequent
                        been with unknown minds, And given to time your own dear-purchas'd right;
                    </p>
                </Tab>
            </Tabs>
        </Card>

    )
}

export default DetailTabs;