import { call, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetLogs(action) {

    yield call(
        request({
            type: constants.GET_LOGS,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `logs`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


export default function* rootSaga() {
    yield takeLatest(constants.GET_LOGS, requestToGetLogs);
}
