const keyMirror = require("keymirror");

export const constants = keyMirror({
  AUTH_USER: undefined,
  SIGN_IN: undefined,
  SIGN_UP: undefined,
  FETCH_USER: undefined,
  LOG_OUT: undefined,
  SAVE_USER: undefined
});
