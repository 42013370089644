import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.GET_EMPLOYEES)]: (
            state,
            action
        ) => ({
            ...state,
            employees: action.payload.employees,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_EMPLOYEES)]: (
            state
        ) => ({
            ...state,
            employees: [],
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_EMPLOYEES)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.GET_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            employee: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_EMPLOYEE)]: (
            state
        ) => ({
            ...state,
            employee: {},
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_EMPLOYEE)]: (
            state
        ) => ({
            ...state,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.ADD_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.ADD_EMPLOYEE)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.ADD_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.EDIT_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.EDIT_EMPLOYEE)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.EDIT_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {
        [requestSuccess(constants.DELETE_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.DELETE_EMPLOYEE)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.DELETE_EMPLOYEE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    {

        [requestSuccess(constants.GET_EMPLOYEE_FOR_TIMELINE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: false,
            requestInSuccess: true,
        }),
        [requestPending(constants.GET_EMPLOYEE_FOR_TIMELINE)]: (
            state
        ) => ({
            ...state,
            requestInPending: true,
            requestInFail: false,
            requestInSuccess: false,
        }),
        [requestFail(constants.GET_EMPLOYEE_FOR_TIMELINE)]: (
            state,
            action
        ) => ({
            ...state,
            data: action.payload,
            requestInPending: false,
            requestInFail: true,
            requestInSuccess: false,
        })
    },
    initialState()
);
