
export const apiConfig = {
    //API_END_POINT: "http://localhost:5001/navoto-crm/us-central1/app/v1/",

    API_END_POINT: process.env.NODE_ENV === "development" ? "http://127.0.0.1:5001/navoto-crm/us-central1/api/v1/" : "https://us-central1-navoto-crm.cloudfunctions.net/api/v1/",
    //API_END_POINT: "https://us-central1-navoto-crm.cloudfunctions.net/api/v1/",
    API_PAYMENT_STATUS_UPDATE:"https://invoice-api-gateway-5h8k489m.uc.gateway.dev/v1/invoice/updatePaymentStatus?key=AIzaSyCSbqn4V7Gk46X_rT9xUc9bxrabNTHScik",
    API_END_POINT1: "https://lead-api-gateway-5h8k489m.uc.gateway.dev/v1/",
    REACT_APP_CRYPTOPASSWORD: process.env.REACT_APP_CRYPTOPASSWORD,
    REACT_APP_STRIPEPUBLISHABLEKEY: process.env.REACT_APP_STRIPEPUBLISHABLEKEY,
    REACT_APP_STRIPESECRETKEY: process.env.REACT_APP_STRIPESECRETKEY,
    dbConfig: {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCEKT,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID
    },

}
/*
export const getAppEnv =()=>{


    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') { return "Dev";
console.log("Heelo",process.env.NODE_ENV);
    } else { console.log("sdfkhsdfihdfs"); return "ryurwey";
        // production code
    }
}
*/