import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestAddLeave, requestEditLeave,requestGetLeave } from "./Middleware/action";
import { requestGetEmployees } from '../employees/Middleware/action';
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { requestGetEmployeeLeaveByEmployeeId } from "components/employees/Middleware/action";

const AddUpdateLeave = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const { id,loggedInUserId } = useParams();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [allUsers, setAllUsers] = useState([]);

  const [totalLeaves, setTotalLeaves] = useState("");
  const [fullDayLeaveTaken, setFullDayLeaveTaken] = useState("");
  const [leaveTaken, setLeaveTaken] = useState("");
  const [halfDayLeave, setHalfDayLeave] = useState("");
  const [remainingLeaves, setRemainingLeaves] = useState("");
  //const [allUsers, setAllUsers] = useState(;

  let navigate = useNavigate();

  const onSubmit = (formData) => {

  saveLeaveData(formData);
  }

  useEffect(() => {
    getEmployees();

    setValue('fromDate', fromDate);
    setValue('toDate', toDate);
  } , []);

  useEffect(() => {
    if (id) {
        getLeave();
    }
  }, [id]);

  useEffect(() => {
    if (loggedInUserId) {
        setValue('appliedBy',loggedInUserId );
        getEmployeeLeave(loggedInUserId);
    }
  }, [loggedInUserId]);

  const getEmployees = async () => {
      const onSuccess = (data) => {
          setAllUsers(data.employees);
      }
      dispatch(requestGetEmployees({ onSuccess }))
  }

  const getEmployeeLeave = (loggedInUserId) => {
    const onSuccess = (data) => { 
    
      const employeeLeave = data.employeeLeave;

      setTotalLeaves(employeeLeave.fullDayLeaveGranted);
      setFullDayLeaveTaken(employeeLeave.fullDayLeaveTaken);
      setHalfDayLeave(employeeLeave.halfDayLeaveTaken);
    }
    dispatch(requestGetEmployeeLeaveByEmployeeId({ employeeId: loggedInUserId, onSuccess }))
  }

  const getLeave = () => {

    setLoading(true);
    const onSuccess = (data) => { console.log("leave data..",data);
      setLoading(false)

      const fromDate = new Date(data.leave[0].fromDate);
      const toDate = new Date(data.leave[0].toDate);

      setFromDate(fromDate);
      setToDate(toDate);

      setValue('fromDate', fromDate);
      setValue('toDate', toDate);
      setValue('appliedBy',data.leave[0].appliedBy );
      setValue('noOfDays', data.leave[0].noOfDays);
      setValue('leaveCategoryId', data.leave[0].leaveCategoryId);
      setValue('description', data.leave[0].description);
    }
    dispatch(requestGetLeave({ leaveId: id, onSuccess }))
  }

  const saveLeaveData = (formData) => { 
      
      setIsSubmitting(true);
      const onSuccess = (data) => {
          setIsSubmitting(false);

          if (data.success) {
              console.log(data);
              let leaveId = data.leaveId;
              toast.success(`Leave ${id ? 'updated' : 'added'} successfully`, {
                  theme: 'colored'
              });
            
              navigate("/leaves");
          }
      }
      if (id) {
          formData.leaveId = id;
          dispatch(requestEditLeave({ formData, onSuccess }))
      } else {
          dispatch(requestAddLeave({ formData, onSuccess }))
      }
  }

  return (
    <>
    <Row className="g-3">
        <Col lg={8}>
        <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
        <FalconCardHeader title={id ? 'Edit Leave' : 'Add leave'} />
          <Card.Body className="bg-light">
          { !loggedInUserId && (
          <Form.Group as={Col} lg={6}>
              <Form.Label>Applied By</Form.Label>
              <Form.Select aria-label="statuz" {...register('appliedBy', { required: true })} >
                <option value="">Select</option>
                {allUsers.map((item, index) => {
                  return(
                   <option key={index} value={item.userId}>{item.firstName} {item.lastName}</option>
                   );
                })}
              </Form.Select>
               <div className="invalid-feedback" style={{ display: errors.projectName ? 'block' : 'none' }}>Field is required</div>
            </Form.Group>
            )}

            { loggedInUserId && (
              <Form.Control
                      type="hidden"
                      {...register('appliedBy', { required: true })}
                    />
            )}
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6} >
                  <Form.Label>From Date</Form.Label>
                          <Controller
                          control={control}
                          name="fromDate"
                          selected={fromDate}
                          render={({ field }) => (
                          <DatePicker
                          {...field}
                          selected={fromDate}
                          dateFormat="dd/MM/yyyy"
                          className='form-control'
                          onChange={(date)=>{
                            console.log("date changed", date)
                            field.onChange(date)
                            setFromDate(date)
                          }}
                          />
                  )}
                />
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>To Date</Form.Label>
                  <Controller
                        control={control}
                        name="toDate"
                        selected={toDate}
                        render={({ field }) => (
                        <DatePicker
                        {...field}
                        selected={toDate}
                        dateFormat="dd/MM/yyyy"
                        className='form-control'
                        onChange={(date)=>{
                          field.onChange(date)
                          setToDate(date)
                        }}
                        />
                     )}
                   />      
                </Form.Group>
              </Row>
              <Row className="mb-3 g-3">
                <Form.Group as={Col} lg={6}>
                <Form.Label>Leave Type</Form.Label>
                 <Form.Select aria-label="Status" {...register('leaveCategoryId', { required: true })}>
                    <option value="Full day leave">Full day leave</option>
                    <option value="Half day leave">Half day leave</option>
                    <option value="Short day leave">Short day leave</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} lg={6}>
                  <Form.Label>No of Days</Form.Label>
                   <Form.Control
                      type="text"
                      placeholder="No of days"
                      {...register('noOfDays', { required:true})}
                    />
                     <div className="invalid-feedback" style={{ display: errors.noOfDays?.message ? 'block' : 'none' }}>{errors.noOfDays?.message}</div>
                </Form.Group>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
              <div className="text-end">
                <Button variant="primary" type="submit">
                  {id ? 'Update' : 'Create'}
                </Button>
                </div>
          </Card.Body>
          </Form>
        </Card>
        </Col>
        { loggedInUserId && (
        <Col lg={4}>
          <Card className="mb-3">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <h5 className="mb-0">Leave Detail</h5>
                </Col>
              </Row>
            </Card.Header>
           <Card.Body className="bg-light border-top">
              <p className="fw-semi-bold">
                Total Leaves: <b>{totalLeaves}</b>
              </p>
              <p className="fw-semi-bold">
               Leaves taken: <b>{leaveTaken}</b>
              </p>
              <p className="fw-semi-bold">
                Full day leave: <b>{fullDayLeaveTaken}</b>
              </p>
              <p className="fw-semi-bold">
                Half day leave: <b>{halfDayLeave}</b>
              </p>
              <p className="fw-semi-bold">
                Remaining leave: <b> { remainingLeaves} </b>
              </p> 
            </Card.Body> 
          </Card>
        </Col>
        )}
        </Row>
    </>
  );
};
      
export default AddUpdateLeave;
