import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetInvoices = createAction(constants.GET_INVOICES);
export const requestAddInvoice = createAction(constants.ADD_INVOICE);
export const requestEditInvoice = createAction(constants.EDIT_INVOICE);
export const requestInvoiceDetail = createAction(constants.INVOICE_DETAIL);
export const requestSendInvoice= createAction(constants.SEND_INVOICE);
export const requestUpdatePaymentStatus= createAction(constants.UPDATE_PAYMENT_STATUS);
export const requestDeleteInvoice= createAction(constants.DELETE_INVOICE);
export const requestCancelInvoice= createAction(constants.CANCEL_INVOICE);
export const requestMarkAsDone= createAction(constants.MARK_DONE);
export const sendReciept= createAction(constants.SEND_RECIEPT);
export const requestToGetReciept= createAction(constants.GET_RECIEPT);
export const requestEditReciept= createAction(constants.EDIT_RECIEPT);



