import FalconCardHeader from "components/common/FalconCardHeader";
import TooltipBadge from "components/common/TooltipBadge";
import { useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Form } from "react-bootstrap";
import Avatar from 'components/common/Avatar';
import avatar from 'assets/img/team/2.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";

const ContactDetails = () => {

    const [formData, setFormData] = useState({
        viewProfile: 'my-followers',
        tagSettings: 'group-members',
        showFollowers: true,
        showEmail: true,
        showExperience: false,
        numberVisibility: true,
        allowFollow: false
    });

    const handleChange = e => {
        if (e.target.type === 'checkbox') {
            setFormData({
                ...formData,
                [e.target.name]: e.target.checked
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };
    return (
        <Card className="mb-3">
            <FalconCardHeader title="Contact Details" endEl={
                <>
                    <Dropdown className="font-sans-serif" align="end">
                        <Dropdown.Toggle
                            variant="falcon-default"
                            size="sm"
                            className="text-600 dropdown-caret-none ms-2"
                        >
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="py-2">
                            <Dropdown.Item href="#/action-1">Configure inbox</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Themes</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-4">Send feedback</Dropdown.Item>
                            <Dropdown.Item href="#/action-4">Help</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </>

            } />
            <Card.Body className="bg-light">

                <div className="custom-border text-center mb-3">

                    <Avatar
                        size="4xl"
                        className=""
                        src={avatar}
                        mediaClass="img-thumbnail shadow-sm"
                    />
                    <h5 className="mb-3">
                        Anthony Hopkins
                    </h5>

                    {/* <Button variant="falcon-primary" size="sm" className="px-3">
                        Working
                    </Button> */}
                    <Flex justifyContent="center">


                        <DropdownButton id="status" title="Working">
                            <Dropdown.Item as="button">Working</Dropdown.Item>
                            <Dropdown.Item as="button">On Hold</Dropdown.Item>
                            <Dropdown.Item as="button">Pending</Dropdown.Item>
                        </DropdownButton>

                        <Button variant="falcon-default" size="sm" className="px-3 ms-2">
                            <FontAwesomeIcon icon="phone-alt" />  Call
                        </Button>
                    </Flex>
                </div>

                <div className="custom-border">
                    <div>
                        <h6 className="fw-bold">
                            Email
                        </h6>
                        <div className="mb-2">
                            <a href={`mailto:jitendra@ugi.co.il`}>jitendra@ugi.co.il</a>
                        </div>
                    </div>
                    <div>
                        <h6 className="fw-bold">
                            Phone
                        </h6>
                        <div className="mb-2">
                            <a href={`mailto:8057884767`}>8057884767</a>
                        </div>
                    </div>
                    <div>
                        <h6 className="fw-bold">
                            Language
                        </h6>
                        <div className="mb-2">
                            English, Hebrew
                        </div>
                    </div>
                    <div>
                        <h6 className="fw-bold">
                            Account Verified by
                        </h6>
                        <div className="mb-2">
                            <a href='www.facebook.com'>Facebook</a>
                        </div>
                    </div>
                    <div>
                        <h6 className="fw-bold">
                            Start Date
                        </h6>
                        <div className="mb-2">
                            April 21, 2023, 5:33 PM
                        </div>
                    </div>
                    <div>
                        <h6 className="fw-bold">
                            Subscription
                        </h6>
                        <div className="mb-2">
                            Active
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
export default ContactDetails;