import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestAddInvoice, requestEditInvoice, requestInvoiceDetail } from "./Middleware/action";
import { requestGetCompany } from "components/company/Middleware/action";
import { useState } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import { requestGetClient } from 'components/clients/Middleware/action';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import DatePicker from "react-datepicker";
import AddProjectToInvoice from "./AddProjectToInvoice";
import { apiConfig } from 'config/apiConfig';
import { _removeLocalStorage, _saveLocalStorage } from 'Services/localStorageService';
import { CURRENCY } from 'enum/enum';
import moment from 'moment';
import 'moment-timezone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal } from "react-bootstrap";
import AddClientProject from '../../components/projects/AddClientProject';

const AddInvoice = () => {

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, register, watch, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm();
  // const watchFields = watch(["projects"]);
  // const [allProjects, setAllProjects] = useState([]);

  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [open, setOpen] = useState(false);
  // const [fromDate, setFromDate] = useState(new Date());
  // const [toDate, setToDate] = useState(new Date());

  const [invoiceData, setInvoiceData] = useState({});

  const [showModel, setShowModel] = useState(false);
  const handleModelClose = () => setShowModel(false);
  const handleModelShow = () => setShowModel(true);
  const [updateProjectList, setUpdateProjectList] = useState(false);

  const [addNewProject, setAddNewProject]= useState(false);

  // const date = new Date();
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const onSubmit = (formData) => {

    const data = formData;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fromDate = moment.tz(formData.dateRange[0], timeZone).format();
    const toDate = moment.tz(formData.dateRange[1], timeZone).format();
    const dateRanges = [fromDate, toDate];
    data.dateRange = dateRanges;

    saveInvoiceData(data);
  }

  let navigate = useNavigate();
  const { id } = useParams();

  const saveInvoiceData = (formData) => {
    setIsSubmitting(true);
    const onSuccess = (data) => {
      setIsSubmitting(false);

      if (data.success) {
        console.log(data);
        let invoiceId = data.invoiceId;

        // toast.success(`Invocie ${id ? 'updated' : 'added'} successfully`, {
        //   theme: 'colored'
        // });

        if (clientId) {
          navigate(`/clients/${clientId}/invoice/${invoiceId}`);
        } else {
          navigate("/invoice/" + invoiceId);
        }
      }
    }

    if (clientId) {
      formData.clientId = clientId;
    }

    if (id) {
      formData.invoiceId = id;
      dispatch(requestEditInvoice({ formData, onSuccess }))
    } else {
      dispatch(requestAddInvoice({ formData, onSuccess }))
    }
  }

  useEffect(() => {

    _removeLocalStorage("totalAmount");
    _removeLocalStorage("currency");

    if (clientId) {
      getClient();
    }
  }, [clientId]);

  const getClient = () => {
    const onSuccess = (data) => {
      const client = data.user;
      setValue('firstName', client.firstName);
      setValue('lastName', client.lastName);
      setValue('email', client.email);
      setValue('phone', client.meta.accountDetail.phone);
      setValue('email_cc', client.meta.email_cc);
      setValue('email_bcc', client.meta.email_bcc);
      setValue('invoiceTo', client.meta.accountDetail.bussinessName);
      setClient(client);
    }
    dispatch(requestGetClient({ clientId: clientId, onSuccess }))
  }

  useEffect(() => {
    if (!id) {
      getCompany();
    }

  }, []);

  const getCompany = () => {

    const onSuccess = (data) => {
      // console.log('comany data..', data);

      let company = data.company[0];

      setValue('bankName', company.bankName);
      setValue('benificaryName', company.benificaryName);
      setValue('accountNo', company.accountNo);
      setValue('ifscCode', company.ifscCode);
      setValue('swiftCode', company.swiftCode);
      setValue('country', company.bankCountry);
    }
    dispatch(requestGetCompany({ onSuccess }))
  }

  useEffect(() => {
    if (id) {
      getInvoice();
    }
  }, [id]);

  const getInvoice = () => {
    const onSuccess = (data) => {
      console.log("getInvoice..", data);
      const invoice = data.invoice;
      setInvoiceData(invoice);
      setValue('firstName', invoice.firstName);
      setValue('lastName', invoice.lastName);
      setValue('email', invoice.email);

      if (invoice.email_cc) {
        setValue('email_cc', invoice.email_cc);
      }
      if (invoice.email_bcc) {
        setValue('email_bcc', invoice.email_bcc);
      }

      setValue('phone', invoice.phone);
      const dateRanges = [new Date(invoice.dateRange[0].split("T")[0]), new Date(invoice.dateRange[1].split("T")[0])];
      setValue('dateRange', dateRanges);

      if (invoice.invoiceTo) {
        setValue('invoiceTo', invoice.invoiceTo);
      }
      setValue('bankName', invoice.bankName);
      setValue('benificaryName', invoice.benificaryName);
      setValue('accountNo', invoice.accountNo);
      setValue('ifscCode', invoice.ifscCode);
      setValue('swiftCode', invoice.swiftCode);
      setValue('country', invoice.country);
      setValue('monthlyBilling', invoice.monthlyBilling);
      setValue('generatePaymentLink', invoice.generatePaymentLink);
      setValue('currency', invoice.currency);

      invoice.projects.forEach((project, index) => {
        const discount = project.discount ? project.discount : project.discountType == "fixed" ? project.discountValue : (project.subTotal * (project.discountValue / 100));
        setValue(`projects.${index}.discount`, discount);
        setValue(`projects.${index}.projectId`, project.projectId);
        setValue(`projects.${index}.projectName`, project.projectName);
        setValue(`projects.${index}.timeSpent`, project.timeSpent);
        setValue(`projects.${index}.timeIn`, project.timeIn);
        setValue(`projects.${index}.subTotal`, project.subTotal);
        setValue(`projects.${index}.discountType`, project.discountType);
        setValue(`projects.${index}.discountValue`, project.discountValue);
        setValue(`projects.${index}.totalAmount`, project.totalAmount);
      });

      _saveLocalStorage("totalAmount", invoice.invoiceTotal);
      _saveLocalStorage("currency", invoice.currency);

      // setValue("projects", invoice.projects);
      // const fromDate = new Date(invoice.dateRange[0]);
      // const toDate = new Date(invoice.dateRange[1]);

      // setFromDate(fromDate);
      // setToDate(toDate);

    }
    dispatch(requestInvoiceDetail({ invoiceId: id, onSuccess }))
  }

  useEffect(() => {
    register("invoiceTo", { required: false });
  }, [register]);

  const onEditorStateChange = (editorState) => {
    setValue("invoiceTo", editorState);
  };
  const invoiceTo = watch("invoiceTo");

  return (
    <>

      {client &&
        <>
          <Card className="mb-3">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="mb-2">
                    {client?.firstName} {client?.lastName} (<a href="mailto: + client?.email+">{client?.email}</a>)
                  </h5>
                </Col>
                <Col xs="auto">
                  <h6 className="text-uppercase text-600">
                    Customer
                    <FontAwesomeIcon icon="user" className="ms-2" />
                  </h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="border-top">
              <Flex>
                <FontAwesomeIcon
                  icon="user"
                  className="text-success me-2"
                  transform="down-5"
                />
                <div className="flex-1">
                  <p className="mb-0">Customer was created</p>
                  <p className="fs--1 mb-0 text-600">{client?.updatedAt}</p>
                </div>
              </Flex>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <h5 className="mb-0">Details</h5>
                </Col>
                <Col xs="auto">
                  <IconButton
                    onClick={() => setOpen(!open)}
                    iconClassName="fs--2"
                    variant="falcon-default"
                    size="sm"
                    icon="chevron-down"
                  >
                  </IconButton>
                </Col>
              </Row>
            </Card.Header>
            <Collapse in={!open}>
              <Card.Body className="bg-light border-top">
                <Row>
                  <Col lg xxl={5}>
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                      Account Information <Link to={`/clients/edit/${client?.userId}`}><FontAwesomeIcon icon="pencil-alt" className="ms-2" /></Link>
                    </h6>

                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Phone</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.accountDetail?.phone}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Email</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.email}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Address</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.accountDetail?.address}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Description</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.accountDetail?.description}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Bussiness Name</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.accountDetail?.bussinessName}
                        </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg xxl={{ span: 5, offset: 1 }} className="mt-4 mt-lg-0">
                    <h6 className="fw-semi-bold ls mb-3 text-uppercase">
                      Billing Information <Link to={`/clients/edit/${client?.userId}`}><FontAwesomeIcon icon="pencil-alt" className="ms-2" /></Link>
                    </h6>

                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Send email to</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.billingDetail?.email}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Phone number</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.billingDetail?.phone}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5} sm={4}>
                        <p className="fw-semi-bold mb-1">Address</p>
                      </Col>
                      <Col>
                        <p>
                          {client?.meta?.billingDetail?.address}
                        </p>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Card.Body>
            </Collapse>
          </Card>
        </>

      }

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={8}>
            <Card>
              <FalconCardHeader title={id ? 'Edit Invoice' : 'Add Invoice'} />
              <Card.Body className="bg-light">

                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      {...register('firstName', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.firstName ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      {...register('lastName', { required: false })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.lastName ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      {...register('email', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.email ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone"
                      {...register('phone', { required: false })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.phone ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Email CC</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email CC"
                      {...register('email_cc', { required: false })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.email_cc ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Email BCC</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email BCC"
                      {...register('email_bcc', { required: false })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.email_bcc ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Currency</Form.Label>
                    <Form.Select aria-label="Currency" {...register('currency', { required: false })}>
                      {Object.keys(CURRENCY).map((curr, i) => {
                        return (
                          <option value={curr} key={i}>{curr}</option>
                        )
                      })}
                      {/* <option value="ILS">ILS</option>
                          <option value="USD">USD</option>
                          <option value="INR">INR</option> */}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} lg={6} >
                    <Form.Label>Date Range</Form.Label>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name="dateRange"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selectsRange
                          autoComplete='off'
                          startDate={field.value && field.value[0]}
                          endDate={field.value && field.value[1]}
                          dateFormat="dd/MM/yyyy"
                          className='form-control'
                          onChange={(dates) => {
                            const [start, end] = dates;
                            field.onChange([start, end]);
                          }}
                          ref={(ref) => {
                            field.ref({
                              focus: ref?.setFocus
                            });
                          }}
                        />
                      )}
                    />
                    <div className="invalid-feedback" style={{ display: errors.dateRange ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <AddProjectToInvoice
                  register={register}
                  control={control}
                  clientId={clientId}
                  invoiceId={id}
                  getValues={getValues}
                  setValue={setValue}
                  // watchFields={watchFields}
                  watch={watch}
                  errors={errors}
                  projectss={invoiceData?.projects}
                  updateProjectList={updateProjectList}
                  setUpdateProjectList={setUpdateProjectList}
                  addNewProject={addNewProject}
                  setAddNewProject={setAddNewProject}
                />
                {clientId && (
                  <Row className="mb-3 mt-3 g-3">
                    <Form.Group as={Col} lg={6} >
                      <IconButton variant="primary" icon="plus" type="button" onClick={handleModelShow} >
                        Add New Project
                      </IconButton>
                    </Form.Group>
                  </Row>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Invoice To</Form.Label>
                  {/* <Form.Control
                    as="textarea"
                    rows={13}
                    placeholder="Invoice To"
                    {...register('invoiceTo', { required: false })}
                  /> */}

                  <ReactQuill theme="snow" value={invoiceTo} onChange={onEditorStateChange} />
                  {/* 
<Controller
        name="invoiceTo"
        control={control}
        rules={{ required: 'Please enter some content' }}
        render={({ field }) => (
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
          />
        )}
      /> */}

                  {clientId && (
                    <Modal
                      show={showModel}
                      onHide={handleModelClose}
                      backdrop="static"
                      keyboard={false}
                      dialogClassName=" modal-lg"
                    >
                      <Modal.Header closeButton>

                      </Modal.Header>
                      <div id="simpleModal_2" className="popupBx_modal openModalBx modal-dialog-center ">
                        <div className="modal-window small">

                          <div className="modal_content">
                            <AddClientProject invoiceClientId={clientId} handleModelClose={handleModelClose} setUpdateProjectList={setUpdateProjectList} setAddNewProject={setAddNewProject}/>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  )}



                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <FalconCardHeader title="Bank Detail" />
              <Card.Body className="bg-light">

                <Form.Group className="mb-3">
                  <Form.Check
                    type='checkbox'
                    label='Generate Payment link'
                    {...register('generatePaymentLink')}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Bank Name"
                    {...register('bankName', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.bankName ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Benificary Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Benificary Name"
                    {...register('benificaryName', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.benificaryName ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Account No</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="Account No"
                    {...register('accountNo', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.accountNo ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="IFSC Code"
                    {...register('ifscCode', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.ifscCode ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Swift Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Swift Code"
                    {...register('swiftCode', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.swiftCode ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    {...register('country', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Monthly Billing</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Monthly Billing"
                    {...register('monthlyBilling', { required: false })}
                  />
                </Form.Group>
                <div className="text-end">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? <span className="spinner-grow spinner-grow-sm"></span> : 'Preivew'}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>

      {/* {!client && (
        <span className="spinner-grow spinner-grow-sm ms-3"></span>
      )} */}

    </>
  );
};

export default AddInvoice;
