import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetUsers = createAction(constants.GET_USERS);
export const requestGetUser = createAction(constants.GET_USER);
export const requestAddUser = createAction(constants.ADD_USER);
export const requestEditUser = createAction(constants.EDIT_USER);
export const requestDeleteUser = createAction(constants.DELETE_USER);
export const requestUserRolePermission = createAction(constants.GET_USER_PERMISSION)




