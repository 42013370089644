function convertDateFormat(dateString) {
    //     const [month, day, year] = dateString.split('T')[0].split("-");
    //     return `${day}/${month}/${year}`;
    if (dateString) {
        let objectDate = new Date(dateString);

        let day = objectDate.getDate();
        let month = objectDate.getMonth() + 1;
        let year = objectDate.getFullYear();

        let receiptD = (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year;
        return receiptD;
    }
    return "";
}

async function getBase64ImageFromUrl(imageUrl) {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export const invoicePaymentReciept = async (data, isDownloading) => {
    const logoLink = "https://navoto.io/cdn-cgi/imagedelivery/jYeHBOCXwjVaMkst73hWpQ//006892e9-51f1-4a04-1133-93a9a68ab900/public";
    const base64Logo = isDownloading ? await getBase64ImageFromUrl(logoLink) : logoLink;

    const htmlString = `
<!DOCTYPE html>
<html>
<head>
<meta charset="utf-8"> 
<meta name="viewport" content="width=device-width"> 
<meta http-equiv="X-UA-Compatible" content="IE=edge"> 
<meta name="x-apple-disable-message-reformatting"> 
<title></title> 

<style>
html,
body {
    margin: 0 auto !important;
    padding: 0 !important;
    height: 100% !important;
    width: 100% !important;
    background: #f1f1f1;
}

/* What it does: Stops email clients resizing small text. */
* {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

/* What it does: Centers email on Android 4.4 */
div[style*="margin: 16px 0"] {
    margin: 0 !important;
}

/* What it does: Stops Outlook from adding extra spacing to tables. */
table,
td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
}

/* What it does: Fixes webkit padding issue. */
table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    table-layout: fixed !important;
    margin: 0 auto !important;
}

/* iPhone 4, 4S, 5, 5S, 5C, and 5SE */
@media only screen and (min-device-width: 320px) and (max-device-width: 374px) {
    u ~ div .email-container {
        min-width: 320px !important;
    }
}
/* iPhone 6, 6S, 7, 8, and X */
@media only screen and (min-device-width: 375px) and (max-device-width: 413px) {
    u ~ div .email-container {
        min-width: 375px !important;
    }
}
/* iPhone 6+, 7+, and 8+ */
@media only screen and (min-device-width: 414px) {
    u ~ div .email-container {
        min-width: 414px !important;
    }
}
.bg_white{
    background: #ffffff;
}
.email-section {
    padding: 0 2.5em;
}

h1,h2,h3,h4,h5,h6{
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
    margin-top: 0;
    font-weight: 400;
}

body{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.8;
    color: rgba(0,0,0,.4);
}
a{
    color: #2f89fc;
}
@page  {
  margin: 2mm 2mm;
  size: 6in 7in;
}


</style>
</head>
<body>
<div style="max-width: 550px; margin: 0 auto;" class="email-container">
<table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
<tbody><tr>
<td valign="top" class="bg_white" style="padding: 0em 20px 0 20px;">
<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
<tbody><tr>
<td class="logo" style="text-align: center;margin:0;">
<h1 style="margin:0; padding:0;"><a href="#"><img src=${base64Logo} alt="logo" width="200"></h1>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td valign="middle" class="hero bg_white" style="padding: 0em 0 2em 0;">
<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
    <tbody><tr>
        <td style="padding: 0 20px; text-align: center;" width="100%">
            <div class="text">
                <h2 style="text-align:center;font-size: 24px;margin: 0;">Receipt from Navoto India LTD</h2>
                <p style="text-align:center;margin: 5px 0;color: #888;font-size: 14px;">Receipt #${data?.recieptId || ""}</p>
                <table style="width:100%">
                    <tr>
                        <td style="font-size:10px; color:#999; text-transform: uppercase;text-align: left;">Amount Paid:</td>
                        <td colspan="2" style="font-size:10px; color:#999; text-transform: uppercase;text-align: left;">Date Paid:</td>
                        <td style="font-size:10px; color:#999; text-transform: uppercase;text-align: left;">Created By:</td>
                    </tr>
                    <tr>
                        <td style="font-size:12px;font-weight: 500; color:#777; text-transform: uppercase;text-align: left;"> ${data.currency} ${data.invoiceTotal}</td>
                        <td colspan="2" style="font-size:12px;font-weight: 500; color:#777; text-transform: uppercase;text-align: left;"> ${convertDateFormat(data?.receiptDate)} </td>
                        <td style="font-size:12px;font-weight: 500; color:#777; text-transform: uppercase;text-align: left;"> ${data?.createdByUser?.firstName} ${data?.createdByUser?.lastName} </td>
                    </tr>
                    <tr>
                        <td style="padding:20px 0; text-align: left;font-size: 15px; font-weight:bold;">SUMMARY</td>
                        <td style="padding:20px 0;"></td>
                    </tr>
                    <tr>
                        <td colspan="4" style="text-align:left; background: #d7f9ff; padding: 10px;"><strong>Invoice for:</strong>  ${data?.invoiceTo.replace(/<\/?[^>]+(>|$)/g, "")}  </td>
                    </tr>

                       ${data.projects.map((project) => {
        return `
                        <tr>
                            <td colspan="2" style="text-align:left; background: #d7f9ff; padding: 10px;"><span>${project.projectName} x 1</span></td>
                           <td colspan="2" style="text-align:left; background: #d7f9ff; padding: 10px;"> <span> ${data.currency} ${project.totalAmount} </span></td>
                        </tr>
                           `
    }).join(' ')}
                    <tr>
                        <td colspan="2" style="text-align:left; background: #d7f9ff; padding: 10px;"><strong>Amount charged:</strong></td>
                        <td colspan="2" style="text-align:left; background: #d7f9ff; padding: 10px;"><strong>${data.currency} ${data.invoiceTotal}</strong></td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td colspan="4" style="text-align: left; padding-left: 5px; padding-right: 5px;">
                            <br>
                            <hr style="background: #eee;">
                            <br>
                            <p style="text-align:left;color: #000;">If you have any questions, contact us at <br>
                            <a style="color: #007BFF;" href="mailto:omri@ugi.co.il"><strong>omri@ugi.co.il</strong></a> or call us at <a style="color: #007BFF;" href="tel:+97233761245"><strong>+972 3-376-1245</strong></a>.</p>

                        </td>
                    </tr>
                </table>
                <div style="clear:both"></div>
            </div>
        </td>
    <td></td>
    </tr>
    </tbody>
</table>

</div>
</body>
</html>

`
    return htmlString;
};