import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetContacts(action) {
    yield call(
        request({
            type: constants.GET_CONTACTS,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `whatsapp/contacts`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetMessages(action) {
    yield call(
        request({
            type: constants.GET_MESSAGES,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `whatsapp/messages?phoneNumber=${action.payload.phoneNumber}&page=${action.payload.page}&limit=${action.payload.limit}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToSendMessage(action) {
    yield call(
        request({
            type: constants.SEND_MESSAGE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `whatsapp/send_message`,
            data: action.payload,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(constants.GET_CONTACTS, requestToGetContacts);
    yield takeLatest(constants.GET_MESSAGES, requestToGetMessages);
    yield takeLatest(constants.SEND_MESSAGE, requestToSendMessage);
}
