import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useDispatch, useSelector } from "react-redux";
import { requestAddUser, requestEditUser, requestGetUser } from "./Middleware/action";
import { toast } from "react-toastify";
import { requestGetRoles } from "components/roles/Middleware/action";
import { useForm } from "react-hook-form";


const AddUpdateUser = () => {
    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);
    const { roles } = useSelector(store => store.role);
    const [allRoles, setAllRoles] = useState([]);
    // const { users, requestInPending, requestInSuccess } = useSelector(store => store.user);

    let navigate = useNavigate();

    const { id } = useParams();

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = (formData) => {
        saveUser(formData);
    }

    useEffect(() => {
        if (id) {
            getUser();
        }
    }, [id]);

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = async () => {
        if (roles && roles.length > 0) {
            setAllRoles(roles);
        }

        const onSuccess = (data) => {
            setAllRoles(data.roles);
        }
        dispatch(requestGetRoles({ onSuccess }))
    }


    const getUser = () => {

        setLoading(true);
        const onSuccess = (data) => {
            setLoading(false)

            setValue('firstName', data.user.firstName);
            setValue('lastName', data.user.lastName);
            setValue('email', data.user.email);
            // setValue('password', data.user.password);
            setValue('role', data.user.role);
            setValue('status', data.user.status);

        }
        dispatch(requestGetUser({ userId: id, onSuccess }))
    }

    const saveUser = (formData) => {
        setIsSubmitting(true);
        const onSuccess = (data) => {
            setIsSubmitting(false);

            if (data.success) {

                toast.success(`User ${id ? 'updated' : 'added'} successfully`, {
                    theme: 'colored'
                });

                navigate("/users");

            }
        }
        if (id) {
            formData.userId = id;
            dispatch(requestEditUser({ formData, onSuccess }))
        } else {
            dispatch(requestAddUser({ formData, onSuccess }))
        }
    }

    return (
        <Card>
            <FalconCardHeader title={id ? 'Update User' : 'Add User'} />
            <Card.Body className="bg-light">
                {loading ?
                    <span className="spinner-grow spinner-grow-sm"></span>
                    :
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="firstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="First Name"
                                    {...register('firstName', { required: true })}
                                />
                                <div className="invalid-feedback" style={{ display: errors.firstName ? 'block' : 'none' }}>Field is required</div>

                            </Form.Group>


                            <Form.Group as={Col} lg={6} controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Last Name"

                                    {...register('lastName', { required: true })}
                                />
                                <div className="invalid-feedback" style={{ display: errors.lastName ? 'block' : 'none' }}>Field is required</div>

                            </Form.Group>
                        </Row>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Email"
                                    {...register('email', { required: 'Field is required', pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Invalid Email',
                                    } })}
                                />
                    <div className="invalid-feedback" style={{ display: errors.email?.message ? 'block' : 'none' }}>{errors.email?.message}</div>

                            </Form.Group>

                            <Form.Group as={Col} lg={6} controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Password"

                                    {...register('password', { required: true })}
                                />
                                <div className="invalid-feedback" style={{ display: errors.password ? 'block' : 'none' }}>Field is required</div>

                            </Form.Group>

                        </Row>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6} controlId="role">
                                <Form.Label>Role</Form.Label>
                                <Form.Select

                                    {...register('role', { required: true })}
                                >
                                    <option value="">Select Role</option>
                                    {allRoles.map((rol, i) => <option key={i} value={rol.role}>{rol.role}</option>)}
                                </Form.Select>
                                <div className="invalid-feedback" style={{ display: errors.role ? 'block' : 'none' }}>Field is required</div>
                            </Form.Group>
                            <Form.Group as={Col} lg={6}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Status" {...register('status', { required: true })}>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>

                                </Form.Select>
                                <div className="invalid-feedback" style={{ display: errors.status ? 'block' : 'none' }}>Field is required</div>
                              </Form.Group>
                        </Row>

                        <div className="text-end">
                            <Button variant="primary" type="submit">
                                {isSubmitting && (
                                    <span className="spinner-grow spinner-grow-sm"></span>
                                )}  {id ? 'Update User' : 'Add User'}
                            </Button>
                        </div>
                    </Form>
                }
            </Card.Body>
        </Card>
    )
}

export default AddUpdateUser;
