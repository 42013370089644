import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const convertSecondsToHMS = (seconds)=> {
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


export const downloadExcel = ( data, taskArrayHeader,fileName)=>{

    const fileType='application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset-UTF-8';

    const fileExtension='.xlsx';

    /*const ws = XLSX.utils.json_to_sheet([{
      0: 0,
      1: 1,
      2: 2
    }], {
      header: ['0', '1', '2'],
    });*/

    const ws = XLSX.utils.json_to_sheet(data, {
      header: taskArrayHeader
    });

    const wb={Sheets: {'data':ws}, SheetNames:['data']};
    const excelBuffer = XLSX.write(wb,{bookType:'xlsx',type:'array'});

    const excelData= new Blob([excelBuffer,{type:fileType}]);

    FileSaver.saveAs(excelData,fileName+fileExtension);

}


export const fixTimezoneOffset = (date: Date) : string => {
  if(!date) return "";
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toJSON();
}
