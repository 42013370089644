import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { invoicePaymentReciept } from './helper/recieptHtml';
import { requestToGetReciept } from './Middleware/action';
import { useDispatch, useSelector } from "react-redux";

const RecieptModal = ({ show, handleClose, handleReciept, invoice, newReciept }) => {
    const [loading, setLoading] = useState(false);
    const [preview, showPreview] = useState(false);
    const [invoiceDetails, setInvoiceDetail] = useState(null);
    const [isDownloading, setDownloading] = useState(false);
    const [htmlString, setHtmlString] = useState("");

    const dispatch = useDispatch();

    const { register, handleSubmit, control, getValues, formState: { errors } } = useForm();

    useEffect(() => {
        if (invoice) {
            handleSetInvoiceDetail();
        }
        getReciept();
    }, [invoice])

    useEffect(() => {
        if (newReciept) {
            handleSetReciept();
        }
    }, [newReciept])

    const handleSetReciept = async () => {
        // const newReciept1 = invoice;
        // newReciept1.reciept = newReciept.reciept;
        // newReciept1.createdByUser = newReciept.userData;
        // if (newReciept) {
        //     newReciept1.recieptId = newReciept.recieptId || "";
        //     newReciept1.receiptDate = newReciept.recieptDate;
        // } else {
        //     newReciept1.receiptDate = newReciept1.createdAt;
        // }

        setInvoiceDetail(newReciept);
        const htmlStringData = await invoicePaymentReciept(newReciept, false);
        setHtmlString(htmlStringData);
    }


    const getReciept = () => {
        const onSuccess = (data) => {
            if (data.success && data.receipt) {
                const newReciept = invoice;
                newReciept.receipt = data.receipt;
                newReciept.createdByUser = data.userData;
                newReciept.recieptId = data.receipt.recieptId || "";
                newReciept.receiptDate = data.receipt.recieptDate;
                setInvoiceDetail(newReciept);
            }
        }
        dispatch(requestToGetReciept({ invoiceId: invoice._id, onSuccess }))
    }

    const handleSetInvoiceDetail = () => {
        setInvoiceDetail(invoice);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        await handleReciept(data, invoiceDetails, isDownloading);
        setLoading(false);
    }


    const togglePreview = async () => {
        if (!preview) {
            invoiceDetails.receiptDate = new Date(invoiceDetails.receiptDate);
            setInvoiceDetail(invoiceDetails);

            const data = {
                recieptDate: getValues("recieptDate")
            }
            await handleReciept(data, invoiceDetails);
            const htmlStringData = await invoicePaymentReciept(invoiceDetails, false);
            setHtmlString(htmlStringData);
        }
        showPreview(!preview);
    }

    const formatDateToLocalISOString = (date) => {
        const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1); // remove the 'Z' at the end
        return localISOTime;
    };


    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header
                    closeButton
                    closeVariant={null}
                    className="border-200"
                >
                    <Modal.Title as="h5">
                        <Flex alignItems="center">
                            Reciept Detail
                        </Flex>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="recieptDate">
                        <Form.Label>Reciept Date</Form.Label>
                        <Controller
                            control={control}
                            name="recieptDate"
                            // rules={{ required: 'Field is required' }}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    autoComplete='off'
                                    dateFormat="dd/MM/yyyy"
                                    // showTimeSelect//h:mm aa
                                    // timeCaption="Time"
                                    // timeIntervals={5}
                                    // timeFormat='HH:mm aa'
                                    minDate={new Date(invoice.createdAt)}
                                    className={`form-control ${errors.recieptDate ? 'is-invalid' : ''}`}
                                    selected={field.value || null}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        invoiceDetails.receiptDate = formatDateToLocalISOString(date);
                                        setInvoiceDetail(invoiceDetails);
                                        showPreview(false);
                                    }}
                                    ref={(ref) => {
                                        field.ref({
                                            focus: ref?.setFocus
                                        });
                                    }}

                                />
                            )}
                        />
                        {/* {errors.recieptDate && (
                            <div className="invalid-feedback">
                                {errors.recieptDate.message}
                            </div>
                        )} */}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="border-200 d-block">
                    <IconButton
                        size="sm"
                        icon="envelope"
                        variant="primary"
                        iconAlign="right"
                        disabled={loading}
                        type="submit"
                        onClick={() => setDownloading(false)}
                    >
                        {loading ? 'Sending...' : 'Send reciept'}
                    </IconButton>
                    <IconButton
                        size="sm"
                        icon="download"
                        variant="primary"
                        iconAlign="right"
                        disabled={loading}
                        type="submit"
                        onClick={() => setDownloading(true)}
                    >
                        {loading ? 'Downloading...' : 'Download reciept'}
                    </IconButton>
                    <IconButton
                        size="sm"
                        icon="file-pdf"
                        variant="primary"
                        iconAlign="right"
                        type="button"
                        onClick={togglePreview}
                    >
                        Preview Reciept
                    </IconButton>
                    {/* <Button
                        size="sm"
                        className="ms-2"
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button> */}
                </Modal.Footer>

                {(preview && htmlString) && (
                    <div className='px-3'>
                        <div dangerouslySetInnerHTML={{ __html: htmlString }}></div>
                    </div>
                )}
            </Form>

        </Modal>

    );
};

export default RecieptModal;
