import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
//import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeleteUser, requestGetUsers } from './Middleware/action';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UsersTableHeader from './UsersTableHeader';
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";
import  ServerSideAdvanceTablePagination from 'components/permissions/ServerSideAdvanceTablePagination';
import { useForm, Controller } from "react-hook-form";
import { requestGetRoles } from "components/roles/Middleware/action";

const Users = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);
    const { users, requestInPending, requestInSuccess } = useSelector(store => store.user);
    let navigate = useNavigate();
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [addUserPermission, setAddUserPermission] = useState(false);
    const [deleteUserPermission, setDeleteUserPermission] = useState(false);
    const [recordCount,setRecordCount]=useState(0);
    const [perPage,setPerPage]=useState(20);
    const [pageNo,setPageNo]=useState(1);
    const {control, register, handleSubmit, setValue, getValues,reset, formState: { errors } } = useForm();
    const [allRoles, setAllRoles] = useState([]);

    const [searchString,setSearchString]=useState("");

    const setSearchParam = (value) =>{
        setSearchString((prev)=> {return value});

        pageChangeHandler(1, value);
    }

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = async () => {
        
        const onSuccess = (data) => {
            setAllRoles(data.roles);
        }
        dispatch(requestGetRoles({ onSuccess }))
    }

    useEffect(() => {
        getUsers();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddUserPermission(loggedInUserPermission[0].add_user);
            setDeleteUserPermission(loggedInUserPermission[0].delete_user)
        }

    }, []);

    useEffect(() => {
        setLoading(true);
        if (users && users.length > 0) {
            setLoading(false);
            setAllUsers(users);
        }
    }, [])

    const getUsers = async (searchValue) => {
        const onSuccess = (data) => {
            setLoading(false);
            setAllUsers(data.users);
            setRecordCount(data.recordCount);
        }

        const formData ={'pageNo':pageNo,'perPage':perPage,'search':searchValue} 
        //const formData ={'pageNo':pageNo,'perPage':perPage} 

        console.log('formData..',formData);
        dispatch(requestGetUsers({formData, onSuccess }))
    }

    const pageChangeHandler=(pageNo, searchValue="")=>{
        setPageNo(pageNo);
        getUsers(searchValue);
    }

    const deleteUser = (userId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`User deleted successfully`, {
                    theme: 'colored'
                });

                getUsers();
            }
        }
        dispatch(requestDeleteUser({ userId, onSuccess }))
    }

    const columns = [
        {
            accessor: 'firstName',
            Header: 'First Name',
        },
        {
            accessor: 'lastName',
            Header: 'Last Name',
        },
        {
            accessor: 'email',
            Header: 'Email',
        },
        {
            accessor: 'role',
            Header: 'Role',
        },
        {
            accessor: 'createdAt',
            Header: 'Created At',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;

                let createdAtDate ='';
                if(createdAt)
                {
                    createdAtDate = createdAt.split("T")[0];
                }
                return createdAtDate;
                
            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { userId } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                               { addUserPermission && (

                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/users/edit/" + userId) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                
                                )}
                                { deleteUserPermission && (

                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteUser(userId) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allUsers}
            selection
            sortable
            pagination
            perPage={perPage}
        >
            <Card className="mb-3">
                <Card.Header>
                    <UsersTableHeader table addUserPermission={addUserPermission} register={register} control={control} pageChangeHandler={pageChangeHandler} getValues={getValues} setSearchParam={setSearchParam} roles={allRoles} />
                </Card.Header>
                <Card.Body className="p-0">
                    {isLoading ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <ServerSideAdvanceTablePagination pageChangeHandler={pageChangeHandler} totalRows={recordCount} rowsPerPage={perPage} pageNo={pageNo} setPageNo={setPageNo}  
                        />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Users;
