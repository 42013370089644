import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetEmployees(action) {
    yield call(
        request({
            type: constants.GET_EMPLOYEES,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `employees`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToGetEmployee(action) {
    yield call(
        request({
            type: constants.GET_EMPLOYEE,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `employees/${action.payload.employeeId}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToAddEmployee(action) {
    yield call(
        request({
            type: constants.ADD_EMPLOYEE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/add`,
            data: {employee:action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToUpdateEmployee(action) {
    yield call(
        request({
            type: constants.EDIT_CLIENT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/edit`,///${action.payload.userId}
            data: {employee:action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToDeleteEmployee(action) {
    yield call(
        request({
            type: constants.DELETE_EMPLOYEE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/delete`,
            data: { userId: action.payload.userId },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToAddEmployeeLeave(action) {
    yield call(
        request({
            type: constants.ADD_EMPLOYEE_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/add-leave`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToEditEmployeeLeave(action) {
    yield call(
        request({
            type: constants.EDIT_EMPLOYEE_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/edit-leave`,///${action.payload.userId}
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetEmployeeLeave(action) {
    yield call(
        request({
            type: constants.GET_EMPLOYEE_LEAVE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/get-leave`,///${action.payload.userId}
            data:{leaveId: action.payload.leaveId},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetEmployeeLeaveByEmployeeId(action) {
    yield call(
        request({
            type: constants.GET_EMPLOYEE_LEAVE_BY_EMPLOYEE_ID,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/get-leave-employee-id`,///${action.payload.userId}
            data:{employeeId: action.payload.employeeId},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetEmployeeForTimeline(action) {
    yield call(
        request({
            type: constants.GET_EMPLOYEE_FOR_TIMELINE,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `employees/get-employee-for-timeline`,///${action.payload.userId}
            data:action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


export default function* rootSaga() {
    yield takeLatest(constants.GET_EMPLOYEES, requestToGetEmployees);
    yield takeLatest(constants.GET_EMPLOYEE, requestToGetEmployee);
    yield takeLatest(constants.ADD_EMPLOYEE, requestToAddEmployee);
    yield takeLatest(constants.EDIT_EMPLOYEE, requestToUpdateEmployee);
    yield takeLatest(constants.DELETE_EMPLOYEE, requestToDeleteEmployee);
    yield takeLatest(constants.ADD_EMPLOYEE_LEAVE, requestToAddEmployeeLeave);
    yield takeLatest(constants.EDIT_EMPLOYEE_LEAVE, requestToEditEmployeeLeave);
    yield takeLatest(constants.GET_EMPLOYEE_LEAVE, requestToGetEmployeeLeave);
    yield takeLatest(constants.GET_EMPLOYEE_LEAVE_BY_EMPLOYEE_ID, requestToGetEmployeeLeaveByEmployeeId);
    yield takeLatest(constants.GET_EMPLOYEE_FOR_TIMELINE, requestToGetEmployeeForTimeline);
}
