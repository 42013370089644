import { Card, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const Image = () => {

    const [uplodedFile, setUploadedFile] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getSignedUrl("https://imagedelivery.net/jYeHBOCXwjVaMkst73hWpQ/a322fb45-b9f7-490d-7dfc-aa3205e36a01/public");
    },[])

    const getSignedUrl = async (imageLink) => {
        const imageDeliveryURL = new URL(
            imageLink //|| 'https://imagedelivery.net/jYeHBOCXwjVaMkst73hWpQ/9012ca3c-afa6-4d13-2a62-f362be0dc901/1280x610'
        );
        const newUrl = await generateSignedUrl(imageDeliveryURL);
        setUploadedFile(newUrl.href);
        setLoading(false);
    }

    const onFileChange = async (event) => {
        setLoading(true);
        const file = event.currentTarget.files[0];
        if (file) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var formdata = new FormData();
            formdata.append("file", file, file.name);

            const requestOptions = {
                method: 'POST',
                body: formdata,
            }

            const resp = await fetch('https://uploadfile.jitendra-pal.workers.dev/', requestOptions);
            const data = await resp.json();
            const newUrl = data.result.variants[0];

            console.log(newUrl);
            getSignedUrl(newUrl);
        }
    }

    const KEY = 'LNYTnPayGIIsvtoDFBO9jYBlQ7KoJpAZ';
    const EXPIRATION = 60 * 60 * 24; // 1 day

    const bufferToHex = buffer => [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join('');

    async function generateSignedUrl(url) {
        // `url` is a full imagedelivery.net URL
        // e.g. https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile
        const encoder = new TextEncoder();
        const secretKeyData = encoder.encode(KEY);
        const key = await crypto.subtle.importKey(
            'raw',
            secretKeyData,
            { name: 'HMAC', hash: 'SHA-256' },
            false,
            ['sign']
        );
        // Attach the expiration value to the `url`
        const expiry = Math.floor(Date.now() / 1000) + EXPIRATION;

    
        url.searchParams.set('exp', expiry);
        // url = `${url}?exp=${expiry}`;
        // `url` now looks like
        // https://imagedelivery.net/cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

        // const stringToSign = url.split("https://imagedelivery.net/").pop();
        const stringToSign = url.pathname  + '?' + url.searchParams.toString();
        // for example, /cheeW4oKsx5ljh8e8BoL2A/bc27a117-9509-446b-8c69-c81bfeac0a01/mobile?exp=1631289275

        // Generate the signature
        const mac = await crypto.subtle.sign('HMAC', key, encoder.encode(stringToSign));
        const sig = bufferToHex(new Uint8Array(mac).buffer);

        // And attach it to the `url`
        url.searchParams.set('sig', sig);
        // url = `${url}&sig=${sig}`;

        return url;
        //return new Response(url);
    }

    return (
        <Card className="mb-3">
            <Card.Body>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Choose file</Form.Label>
                    <Form.Control type="file" onChange={onFileChange} />
                </Form.Group>

                {uplodedFile && (
                    <img src={uplodedFile} className="img-fluid" />
                )}

                {isLoading && (
                    <span className="spinner-grow spinner-grow-sm ms-3"></span>
                )}

            </Card.Body>
        </Card>
    )
}

export default Image;
