/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';

export const ServerSideAdvanceTablePagination = ({ pageChangeHandler, totalRows, rowsPerPage, pageNo, setPageNo, totalTimeSpent=''}) => {
 
  //const [currentPage, setCurrentPage] = useState(1);

  useEffect(()=> {
    pageChangeHandler(pageNo)
  },[pageNo]);

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  const pagesArr = [...new Array(noOfPages)];

  // Onclick handlers for the butons
  const onNextPage = () => setPageNo(pageNo + 1);
  const onPrevPage = () => setPageNo(pageNo - 1);
  const onPageSelect = (selectedPageNo) => setPageNo(selectedPageNo);

  useEffect(() => {
    if (noOfPages === pageNo) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (pageNo === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, pageNo]);

  return (
    <>
    { noOfPages > 1 &&(
    <Flex alignItems="center" justifyContent="center">
        <Button
          size="sm"
          variant="falcon-default"
          onClick={onPrevPage}
          className={classNames({ disabled: !canGoBack })}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </Button>
        <ul className="pagination mb-0 mx-2">

        {pagesArr.map((num, index) => (
          <li key={index} className={classNames({ active: (index+1) === pageNo })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-2': index + 1 !== noOfPages
              })}
              onClick={() => onPageSelect(index+1)}
            >
              {index + 1}
            </Button>
          </li>
        ))}
        </ul>
        <Button
        size="sm"
        variant="falcon-default"
        onClick={onNextPage}
        className={classNames({ disabled: !canGoNext })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>   
     </Flex>
      )}
    { totalTimeSpent.length >0 && (
      <p>Total Time Spent: {totalTimeSpent}</p>
    )}
  </>
  );
};

export default ServerSideAdvanceTablePagination;
