
export function _setAccessToken(token) {
    return localStorage.setItem("accessToken", token);
}
export function _getAccessToken() {
    return localStorage.getItem("accessToken");
}

export function _clearToken() {
    localStorage.removeItem("accessToken");
}

export function _clearLocalStorage() {
    localStorage.clear();
}

export function _isLogin() {
    return localStorage.getItem("accessToken");
}

export function _saveLocalStorage(key, value){
    return localStorage.setItem(key, value);
}

export function _getLocalStorage(key) {
    return localStorage.getItem(key);
}


export function _removeLocalStorage(key) {
    return localStorage.removeItem(key);
}

export var loggedInUserObject = { role: ""};
