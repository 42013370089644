import { call, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetPermissions(action) {
   
    yield call(
        request({
            type: constants.GET_PERMISSIONS,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `permissions`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToGetPermission(action) {

    yield call(
        request({
            type: constants.GET_PERMISSION,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `permissions/${action.payload.permissionId}`,
            // data: action.payload.role,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestToAddPermission(action) {
    yield call(
        request({
            type: constants.ADD_PERMISSION,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `permissions/add`,
            data: {permission:action.payload.formData},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}


function* requestToUpdatePermission(action) {
    yield call(
        request({
            type: constants.EDIT_PERMISSION,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `permissions/edit`,
            data: {permission:action.payload.data},
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

function* requestToDeletePermission(action) {
    yield call(
        request({
            type: constants.DELETE_PERMISSION,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `permissions/delete`,
            data: action.payload,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

}

export default function* rootSaga() {
    yield takeLatest(constants.GET_PERMISSIONS, requestToGetPermissions);
    yield takeLatest(constants.GET_PERMISSION, requestToGetPermission);
    yield takeLatest(constants.ADD_PERMISSION, requestToAddPermission);
    yield takeLatest(constants.EDIT_PERMISSION, requestToUpdatePermission);
    yield takeLatest(constants.DELETE_PERMISSION, requestToDeletePermission);

}
