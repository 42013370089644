import axios from "axios";
import { get } from "lodash";
import { call, put } from "redux-saga/effects";
import { fetchLoggedInUser, getAppCheckToken } from "../Services/firebase";
//
import { _getAccessToken } from '../Services/localStorageService';
// 
export const requestPending = type => `${type}_PENDING`;
export const requestSuccess = type => `${type}_SUCCESS`;
export const requestFail = type => `${type}_FAILURE`;

export const request = ({
    type,
    method,
    url,
    data,
    headers = {},
    success,
    fail,
    baseURL
}) =>
    function* apiRequest() {
        try {
            if (type) {
                yield put({
                    type: requestPending(type),
                    originalType: type
                });
            }
           
            // const accessToken = yield _getAccessToken();
            const loggedInUser = yield fetchLoggedInUser();
            const appCheckToken = yield getAppCheckToken();
            // SET AXIOS DEFAULT VALUE

            axios.defaults.baseURL = baseURL;
            axios.defaults.headers.common = {
                "Cache-Control": "no-store",
                Pragma: "no-cache",
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin':'*'
            };
            if(loggedInUser && loggedInUser.accessToken){
             axios.defaults.headers.common.Authorization = loggedInUser.accessToken ? `Bearer ${loggedInUser.accessToken}` : "";
            }
            if(appCheckToken){
                axios.defaults.headers.common['X-Firebase-AppCheck'] = appCheckToken;
            }
            const res = yield call(axios.request, {
                url,
                method: method.toLowerCase(),
                headers: headers || {},
                data: data || {}
            });

            if (res.data.success) {
                if (success) success(res.data);
            }
            else {
                if (fail) fail(res.data);
            }

            if (type) {
                yield put({
                    type: res.data.success ? requestSuccess(type) : requestFail(type),
                    payload: res.data.data||res.data,
                    originalType: type
                });
            }
            return res.data;
        } catch (err) {
            const errRes = get(err, "data", err);
            if (fail) fail(errRes);
            if (type) {
                yield put({
                    type: requestFail(type),
                    payload: errRes,
                    originalType: type
                });
            } else {
                throw err;
            }
        }
    };