const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_LEAVES: undefined,
  ADD_LEAVE: undefined,
  EDIT_LEAVE: undefined,
  DELETE_LEAVE: undefined,
  GET_LEAVE: undefined,
  ACCEPT_REJECT_LEAVE: undefined
});
