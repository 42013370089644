import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestSuccess, requestPending, requestFail } from "../../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.FETCH_ALL_PRODUCT)]: (
            state,
            action
        ) => ({
            ...state,
            allProducts: action.payload,
            fetchAllProductsPending: false,
            fetchAllProductsFailure: false,
            fetchAllProductsSuccess: true,
        }),
        [requestPending(constants.FETCH_ALL_PRODUCT)]: (
            state
        ) => ({
            ...state,
            allProducts: [],
            fetchAllProductsPending: true,
            fetchAllProductsFailure: false,
            fetchAllProductsSuccess: false,
        }),
        [requestFail(constants.FETCH_ALL_PRODUCT)]: (
            state
        ) => ({
            ...state,
            fetchAllProductsPending: false,
            fetchAllProductsFailure: true,
            fetchAllProductsSuccess: false,
        })
    },
    initialState()
);
