import React, { useEffect, useState } from 'react';
import { Card, Dropdown,Tabs,Tab } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeleteEmployee, requestGetEmployees } from './Middleware/action';
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EmployeesTableHeader from './EmployeesTableHeader';
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";

const Employees = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const { employees, requestInPending, requestInSuccess } = useSelector(store => store.employee);
    let navigate = useNavigate();
    const [allEmployees, setAllEmployees] = useState([]);

    const [addEmployeePermission, setAddEmployeePermission] = useState(false);
    const [deleteEmployeePermission, setDeleteEmployeePermission] = useState(false);

    useEffect(() => {
        getEmployees();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddEmployeePermission(loggedInUserPermission[0].add_employee);
            setDeleteEmployeePermission(loggedInUserPermission[0].delete_employee)
        }

    }, []);

    useEffect(() => {
        if (employees && employees.length > 0) {
            setAllEmployees(employees);
        }
    }, []);

    const getEmployees = async () => {
        const onSuccess = (data) => {
            setAllEmployees(data.employees);
        }
        dispatch(requestGetEmployees({ onSuccess }))
    }

    const deleteEmployee = (userId) => { 
        const onSuccess = (data) => {
            if (data.success) {

                toast.success(`Employee deleted successfully`, {
                    theme: 'colored'
                });

                getEmployees();
            }
        }
        dispatch(requestDeleteEmployee({ userId, onSuccess }))
    }

    const columns = [
        {
            accessor: 'firstName',
            Header: 'First Name',
        },
        {
            accessor: 'lastName',
            Header: 'Last Name',
        },
        {
            accessor: 'phone',
            Header: 'Phone',
        },
        {
            accessor: 'joined',
            Header: 'Joined',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;
                return createdAt?.split("T")[0];
            }
        },
        {
            accessor: 'email',
            Header: 'Email',
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { userId,leave } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                               
                                { (leave.length>0) && (<Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/employees/edit-leave/" + userId+'/'+leave[0]._id.valueOf()) }}><FontAwesomeIcon icon="pencil-alt" /> Edit Leave</Dropdown.Item>
                                )}

                                { (leave.length ==0) && (<Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/employees/add-leave/" + userId) }}><FontAwesomeIcon icon="plus" /> Add Leave</Dropdown.Item>
                                )}

                                {addEmployeePermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/employees/edit/" + userId) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}
                                { deleteEmployeePermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteEmployee(userId) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                                
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

    return (
           <AdvanceTableWrapper
            columns={columns}
            data={allEmployees}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <EmployeesTableHeader table addEmployeePermission={addEmployeePermission} />
                </Card.Header>
                <Card.Body className="p-0">
                    {requestInPending ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Employees;
