import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetCompany(action) {
    yield call(
        request({
            type: constants.GET_COMPANY,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `company`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToAddCompany(action) { debugger
    yield call( 
        request({
            type: constants.ADD_COMPANY,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `company/add`,
            data: {'company':action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToEditCompany(action) { 
    yield call(
        request({
            type: constants.EDIT_COMPANY,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `company/edit`,///${action.payload.userId}
            data: {'company':action.payload.formData},
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


export default function* rootSaga() {
    yield takeLatest(constants.GET_COMPANY, requestToGetCompany);
    yield takeLatest(constants.ADD_COMPANY, requestToAddCompany);
    yield takeLatest(constants.EDIT_COMPANY, requestToEditCompany);
}
