import FalconCardHeader from "components/common/FalconCardHeader";
import IconButton from "components/common/IconButton";
import AccountSettings from "components/pages/user/settings/AccountSettings";
import ContactSettings from "components/pages/user/settings/ContactSettings";
import ProfileSettings from "components/pages/user/settings/ProfileSettings";
import { Card, Col, Row } from "react-bootstrap";
import ContactDetails from "./ContactDetails";
import DetailTabs from "./DetailTabs";
import LeadDetailHeader from "./LeadDetailHeader";

const LeadDetails = () => {
    return (
        <>
            <LeadDetailHeader />

            <Row className="g-3">
                <Col lg={8}>
                    <DetailTabs />
                </Col>
                <Col lg={4}>
                    <div className="sticky-sidebar" style={{ top: '0px' }}>
                        <ContactDetails />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default LeadDetails;
