import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetRoles = createAction(constants.GET_ROLES);
export const requestGetRole = createAction(constants.GET_ROLE);
export const requestAddRole = createAction(constants.ADD_ROLE);
export const requestEditRole = createAction(constants.EDIT_ROLE);
export const requestDeleteRole = createAction(constants.DELETE_ROLE);




