import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeleteRole, requestGetRoles } from './Middleware/action';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RolesTableHeader from './RolesTableHeader';
import { toast } from 'react-toastify';
import {STATUS} from '../../enum/enum.js';
import { useOutletContext } from "react-router-dom";

const Roles = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);

    const { roles, requestInPending, requestInSuccess } = useSelector(store => store.role);

    let navigate = useNavigate();
    const [allRoles, setAllRoles] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const [addRolePermission, setAddRolePermission] = useState(false);
    const [deleteRolePermission, setDeleteRolePermission] = useState(false);

    useEffect(() => {

        getRoles();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddRolePermission(loggedInUserPermission[0].add_role);
            setDeleteRolePermission(loggedInUserPermission[0].delete_role)
        }

    }, []);

    useEffect(() => {
        setLoading(true);
        if (roles && roles.length > 0) {
            setLoading(false);
            setAllRoles(roles);
        }
    }, [])

    const getRoles = async () => {
        const onSuccess = (data) => {
            setLoading(false);
            setAllRoles(data.roles);
        }
        dispatch(requestGetRoles({ onSuccess }))
    }

    const deleteRole = (role) => { console.log('role',role);
        const onSuccess = (data) => {
            if (data.success) {

                toast.success(`Role deleted successfully`, {
                    theme: 'colored'
                });

                getRoles();
            }
        }
        dispatch(requestDeleteRole({ role, onSuccess }))
    }

    const columns = [
        {
            accessor: 'role',
            Header: 'Role',
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                let statusText =STATUS[status];

                return statusText;
                
            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                            { addRolePermission && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/roles/edit/" + _id) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}

                             { deleteRolePermission && (    
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteRole(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                                </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];
    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allRoles}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <RolesTableHeader table  addRolePermission={addRolePermission}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {isLoading ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Roles;
