import React, { useEffect, useState } from 'react';
import { Card, Dropdown, DropdownButton, Form, Placeholder } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import  ServerSideAdvanceTablePagination from 'components/permissions/ServerSideAdvanceTablePagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import { requestDeleteTask, requestGetTasks,requestChangeTaskStatus,requestChangeTaskRunningStatus } from './Middleware/action';
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TasksTableHeader from './TasksTableHeader';
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { PRIORITY,TASK_TIME_TYPE,TASK_STATUS } from 'enum/enum';
import { requestGetProjects, requestGetProject} from 'components/projects/Middleware/action';
import { requestGetEmployees } from 'components/employees/Middleware/action';
import { convertSecondsToHMS } from 'helpers/custom';
import { saveAs } from 'file-saver';

const Tasks = () => {
    const [loggedInUserPermission,loggedInUser] = useOutletContext();
    const dispatch = useDispatch();
    //const { tasks, requestInPending, requestInSuccess } = useSelector(store => store.tasks);
    let navigate = useNavigate();
    const [allTasks, setAllTasks] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingProject, setIsLoadingProject] = useState(false);
    const [isLoadingEmployee, setIsLoadingEmployee] = useState(false);
    const [addTaskPermission, setAddTaskPermission] = useState(false);
    const [searchTaskAssignedDate, setSearchTaskAssignedDate] = useState();
    const [allProject, setAllProject] = useState(null);
    const [allEmployee, setAllEmployee] = useState(null);
    const [deleteTaskPermission, setDeleteTaskPermission] = useState(false);
    const [recordCount,setRecordCount]=useState(0);
    const [perPage,setPerPage]=useState(20);
    const [pageNo,setPageNo]=useState(1);
    const [totalTimeSpent, setTotalTimeSpent]=useState('');
    const [filterProjectName,setFilterProjectName]=useState('');
    const [showAllTaskStatus, setShowAllTaskStatus] = useState(true);
    const {control, register, handleSubmit, setValue, getValues,reset, formState: { errors } } = useForm();

    const [searchString,setSearchString]=useState("");
    const { projectId,clientId,completed } = useParams();

    const setSearchParam = (value) =>{
        setSearchString((prev)=> {return value});

        pageChangeHandler(1, value);
    }

    useEffect(() => {
        getTasks();

        if(loggedInUserPermission.length)
        {   //console.log(loggedInUserPermission[0].delete_user);
            
            setAddTaskPermission(loggedInUserPermission[0].add_task);
            setDeleteTaskPermission(loggedInUserPermission[0].delete_task)
        }

    }, []);


    //console.log("projetcId param...",projectId);
    /*useEffect(() => {
        setLoading(true);
        if (tasks && tasks.length > 0) {
            setLoading(false);
            setAllTasks(tasks);
        }
    }, [])*/

    const getTasks = async (searchValue) => {
        const onSuccess = (data) => {
            setLoading(false);
            setAllTasks(data.tasks);
            setRecordCount(data.recordCount);

            if(data.totalTimeSpent > 0)
            {
                const formatedTime = convertSecondsToHMS(data.totalTimeSpent);
                setTotalTimeSpent(formatedTime); 
            }
                       
        }
        
        if(!searchValue && projectId)
        {
            searchValue ={projectId:projectId}
        }else if(searchValue && projectId)
        {
            searchValue.projectId = projectId;
        }

        if(!searchValue && completed)
        {
            searchValue ={taskStatus:4}
        }

        const formData ={'pageNo':pageNo,'perPage':perPage,'search':searchValue} 

        console.log("searchValue..",searchValue,'formData..',formData);

        dispatch(requestGetTasks({formData, onSuccess }))
    }

    const pageChangeHandler=(pageNo, searchValue="")=>{
        setPageNo(pageNo);
        getTasks(searchValue);
    }

    const deleteTask = (taskId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`Task deleted successfully`, {
                    theme: 'colored'
                });

                getTasks();
            }
        }
        dispatch(requestDeleteTask({ taskId, onSuccess }))
    }

    useEffect(() => { 
        getProjects();
        getEmployees();

    }, []);

    useEffect(() => { 
        
        if(projectId)
        {
            getProjectName(projectId);
        }

    }, [projectId]);

    const getProjectName=(projectId)=>{

        console.log("projectId..",projectId);

        const onSuccess = (data) => {
            if (data.success) {
                console.log("project data..",data);
                setFilterProjectName(data.project.projectName);
            }
        }
        dispatch(requestGetProject({ projectId, onSuccess }))
    }

    const getProjects=()=>{ 

        setIsLoadingProject(false);
       
        const onSuccess = (data) => { 
           
            if (data.success) { 

                setAllProject(data.projects);
                setIsLoadingProject(true);
            }
        }
        dispatch(requestGetProjects({ onSuccess }))
    }

    const getEmployees=()=>{ 

        setIsLoadingEmployee(false);
       
        const onSuccess = (data) => { 
           
            if (data.success) { 

                setAllEmployee(data.employees);
                setIsLoadingEmployee(true);
            }
        }
        dispatch(requestGetEmployees({ onSuccess }))
    }

    const changeTaskStatus = (taskStatus,taskId)=>{
       
        const onSuccess = (data) => { 
           
            if (data.success) {
                toast.success(data.message, {
                    theme: 'colored'
                });

                //getTasks();
            }
        }
        dispatch(requestChangeTaskStatus({ taskId,taskStatus,onSuccess }))
    }

    const changeTaskRunningStatus=(taskId)=>
    {
        setShowAllTaskStatus(false);
        const onSuccess = (data) => { 
           
            if (data.success) {
                toast.success(data.message, {
                    theme: 'colored'
                });

                if(data.taskRunningStatus)
                {
                    setShowAllTaskStatus(false);
                }
                else
                {
                    setShowAllTaskStatus(true);
                }
                

                getTasks();
            }
        }
        dispatch(requestChangeTaskRunningStatus({ taskId,onSuccess }))
    }

    let columns = [
        {
            accessor: 'taskName',
            Header: 'Task',
            disableSortBy: true,
        },
        {
            accessor: 'assignedTo',
            Header: 'Assigned To',
            disableSortBy: true,
            Cell: (rowData) => {
                const { assignedToUserName } = rowData.row.original;
                return assignedToUserName; 
            }
        },
        {
            accessor: 'Project Name',
            Header: 'Project Name',
            disableSortBy: true,
            Cell: (rowData) => {
                const { projectName } = rowData.row.original;                
                return projectName;
            }
        },
        {
            accessor: 'taskAssignedDate',
            Header: 'Assigned Date',
            disableSortBy: true,
            Cell: (rowData) => {
                let  { taskAssignedDate } = rowData.row.original;
                const assignedDate = taskAssignedDate.split("T")[0];

                return assignedDate;
            }
        },
        {
            accessor: 'priority',
            Header: 'Priority',
            disableSortBy: true,
            Cell: (rowData) => {
                const { priority} = rowData.row.original;
                
                return PRIORITY[priority];
            }
        },
        {
            accessor: 'taskStatus',
            Header: 'Task Status',
            disableSortBy: true,
            Cell: (rowData) => {
                const { taskStatus,_id,taskRunningStatus,assignedTo } = rowData.row.original;
                let taskId= _id.valueOf();
                let elementKeyS= "s_"+taskId;
                return(
                    <>
                    { (loggedInUser.uid== assignedTo) && (!taskRunningStatus && projectId==undefined) && (
                        <Form.Select key={elementKeyS}
                        className="task-list-select"
                        selected={TASK_STATUS[taskStatus]}
                        onChange={(e) => { e.preventDefault(); changeTaskStatus(e.currentTarget.value,taskId) }}
                        >
                            { Object.entries(TASK_STATUS).map(([key, val]) => {
                              return  <option value={key} key={val}>{val}</option>
                            })}
                        </Form.Select>
                    )} 
                    
                    { ((loggedInUser.uid != assignedTo) || ((taskRunningStatus || projectId !==undefined))) && (
                        TASK_STATUS[taskStatus]
                        )}  
                    </>
                    ) 
                 
            }
        },
        {
            accessor: 'assignedHours',
            Header: 'Assigned Time',
            disableSortBy: true,
            Cell: (rowData) => {
                const { assignedHours,taskTimeType} = rowData.row.original;
                
                return assignedHours+" "+TASK_TIME_TYPE[taskTimeType];
            }
        },
        {
            accessor: 'none2',
            Header: 'Time Tracking',
            disableSortBy: true,
            Cell:(rowData)=>{
                //return "hello";
                const { timeSpent } = rowData.row.original;

                const formatedTime = convertSecondsToHMS(timeSpent);

                return formatedTime; 
            }
        }
        ];

    if(projectId === undefined)
    {
        columns.push(   
        {
            accessor: 'assignedBy',
            Header: 'Assigned By',
            disableSortBy: true, 
            Cell: (rowData) => {
                const { createdByUserName} = rowData.row.original;
                return createdByUserName;
            }
        },    
        {
            accessor: 'none1',
            Header: 'Running Status',
            disableSortBy: true,
            Cell:(rowData)=>{
                const { taskStatus,_id,taskRunningStatus,assignedTo } = rowData.row.original; //console.log("taskRunningStatus..",taskRunningStatus)
                let taskId= _id.valueOf(); 
                return(
                    <>
                    { (loggedInUser.uid== assignedTo) && (showAllTaskStatus || taskRunningStatus) && (
                        <Form.Check 
                            type='switch'
                            value="1"
                            onChange={()=>{changeTaskRunningStatus(taskId)}}
                            defaultChecked= { taskRunningStatus}
                          />
                    )}

                    {(loggedInUser.uid != assignedTo)&& taskRunningStatus &&(
                       <><p>Running</p></>
                    )}
                    </>
                  );
            } 
        },
        
        )
    }

    if(projectId === undefined && completed ===undefined)
    {
        columns.push(   
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id } = rowData.row.original;
                const taskId=_id.valueOf();
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                               { addTaskPermission && (

                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/tasks/edit/" +taskId ) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                
                                )}
                                { deleteTaskPermission && (

                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteTask(taskId) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
        )
    }

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={allTasks}
            selection
            sortable
            pagination
            perPage={perPage}
        >
            <Card className="mb-3">
                <Card.Header>
                    <TasksTableHeader table addTaskPermission={addTaskPermission} register={register} control={control} pageChangeHandler={pageChangeHandler} getValues={getValues} setValue= {setValue} setSearchParam={setSearchParam} isLoadingEmployee={isLoadingEmployee} isLoadingProject={isLoadingProject} allEmployee={allEmployee} allProject={allProject}
                    TASK_STATUS={TASK_STATUS} control={control} searchTaskAssignedDate={searchTaskAssignedDate} setSearchTaskAssignedDate={setSearchTaskAssignedDate} projectId={projectId} allTasks={allTasks} filterProjectName={filterProjectName}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {isLoading ?
                        <span className="spinner-grow spinner-grow-sm ms-3"></span>
                        :
                        <AdvanceTable
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <ServerSideAdvanceTablePagination pageChangeHandler={pageChangeHandler} totalRows={recordCount} rowsPerPage={perPage} pageNo={pageNo} setPageNo={setPageNo} totalTimeSpent={totalTimeSpent} 
                        />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Tasks;
