import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetTasks = createAction(constants.GET_TASKS);
export const requestGetTask = createAction(constants.GET_TASK);
export const requestAddTask = createAction(constants.ADD_TASK);
export const requestEditTask = createAction(constants.EDIT_TASK);
export const requestDeleteTask = createAction(constants.DELETE_TASK);
export const requestChangeTaskStatus = createAction(constants.CHANGE_TASK_STATUS);
export const requestChangeTaskRunningStatus = createAction( constants.CHANGE_TASK_RUNNING_STATUS);
export const requestGetTasksForTimeline = createAction( constants.GET_TASKS_FOR_TIMELINE);
