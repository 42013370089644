import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestEditEmployeeLeave, requestAddEmployeeLeave,requestGetEmployeeLeave } from "./Middleware/action";
import { useState } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import { requestGetEmployee } from 'components/employees/Middleware/action';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const AddUpdateEmployeeLeave = () => {

  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const { employeeId,leaveId } = useParams();
  const [employee, setEmployee] = useState(null);

  let navigate = useNavigate();
  //const { id } = useParams();

  useEffect(() => {
    if (employeeId) {
     
      getEmployee();
    }
  }, [employeeId]);

  useEffect(() => {
    if (leaveId) {
     
      getEmployeeLeave();
    }
  }, [leaveId]);

  const getEmployee = () => {
    const onSuccess = (data) => { 
    
      const employee = data.employee;
      setValue('userId', employee.userId);

      setEmployee(employee);
    }
    dispatch(requestGetEmployee({ employeeId: employeeId, onSuccess }))
  }

  const getEmployeeLeave = () => {
    const onSuccess = (data) => { 
    
      const employeeLeave = data.employeeLeave;
 
      setValue('fullDayLeaveGranted', employeeLeave.fullDayLeaveGranted);
      setValue('holidayLeaveGranted', employeeLeave.holidayLeaveGranted);
      setValue('leaveYear', employeeLeave.leaveYear);

    }
    dispatch(requestGetEmployeeLeave({ leaveId: leaveId, onSuccess }))
  }

  const onSubmit = (formData) => {
    saveEmployeeLeaveData(formData);
  }

  const saveEmployeeLeaveData = (formData) => { 
    setIsSubmitting(true);
    const onSuccess = (data) => {
        setIsSubmitting(false);

        if (data.success) {
            toast.success(`Employee's leave ${leaveId?'updated': 'added'} successfully`, {
                theme: 'colored'
            });

            navigate("/employees");

        }
    }

    const onFail = (err) => {
        setIsSubmitting(false);
        toast.error(err.response.data.message);
    }

    if (leaveId) {
        formData.leaveId = leaveId;
        dispatch(requestEditEmployeeLeave({ formData, onSuccess, onFail }))
    } 
    else
    {
      dispatch(requestAddEmployeeLeave({ formData, onSuccess, onFail }))  
    }
}

  //console.log('employee....',employee,'employeeId',employeeId,'leaveId',leaveId);
  return (
    <>

      {employee &&
        <>
          <Card className="mb-3">
            <Card.Header>
              <Row>
                <Col>
                  <h5 className="mb-2">
                    {employee?.firstName} {employee?.lastName} (<a href="mailto: + employee?.email+">{employee?.email}</a>)
                  </h5>
                </Col>
                <Col xs="auto">
                  <h6 className="text-uppercase text-600">
                    Employee
                    <FontAwesomeIcon icon="user" className="ms-2" />
                  </h6>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="border-top">
              
            </Card.Body>
          </Card>
        </>
      }
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={8}>
            <Card>
              <FalconCardHeader title={ 'Add Leave'} />
              <Card.Body className="bg-light">
                
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Full Day Leave</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Full Day Leave"
                      {...register('fullDayLeaveGranted', { required: true })}
                    />
                     <Form.Control
                      type="hidden"
                      {...register('userId', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.fullDayLeaveGranted ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Holiday Leave</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Holiday Leave"
                      {...register('holidayLeaveGranted', { required: false })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.holidayLeaveGranted ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Year"
                      {...register('leaveYear', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.leaveYear ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                 {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                            )}
                   { leaveId? 'Update' : 'Create'}
                  </Button>                
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddUpdateEmployeeLeave;
