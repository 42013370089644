import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";


function* requestToGetLeads(action) {

    // yield put({
    //     type: requestPending(constants.GET_LEADS),
    // })

    yield call(
        request({
            type: constants.GET_LEADS,
            baseURL: apiConfig.API_END_POINT1,
            method: "GET",
            redirect: 'follow',
            url: `leads?key=AIzaSyAb6M2Q17SlCJkt1_Bmr6VMpYKy9C8N8h8`,
            // data: action.payload.leads,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );

    // yield put({
    //     type: requestSuccess(constants.GET_LEADS),
    // })
}

export default function* rootSaga() {
    yield takeLatest(constants.GET_LEADS, requestToGetLeads);
}
