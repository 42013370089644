// import logoInvoice from 'assets/img/logos/logo-invoice.png';
import logoInvoice from 'assets/img/illustrations/navoto_logo.svg';
import IconButton from 'components/common/IconButton';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { useNavigate, useParams } from "react-router-dom";
import { requestInvoiceDetail, requestSendInvoice } from './Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { requestGetClient } from 'components/clients/Middleware/action';
import { CURRENCY } from '../../enum/enum.js';
import html2pdf from 'html2pdf.js';
import { getInvoiceHtml } from './helper/invoiceEmailHtml';
import { apiConfig } from 'config/apiConfig';
import { _getLocalStorage } from 'Services/localStorageService';

const InvoiceDetail = () => {

  const { id, clientId } = useParams();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceFromDate, setInvoiceFromDate] = useState("");
  const [invoiceToDate, setInvoiceToDate] = useState("");

  let navigate = useNavigate();

  const { requestInPending } = useSelector(store => store.user);

  useEffect(() => {
    getInvoiceDetail(id);
  }, []);

  const getInvoiceDetail = async () => {
    const onSuccess = (data) => { //console.log("success called",data.invoice);
      setInvoiceDetail(data.invoice);

      // let objectDate = new Date(data.invoice.createdAt);

      // let invoiceDateRange = data.invoice.dateRange;

      // let day = objectDate.getDate();
      // let month = objectDate.getMonth() + 1;
      // let year = objectDate.getFullYear();

      // let invoiceDate = (day < 10 ? '0' + day : day) + '-' + (month < 10 ? '0' + month : month) + '-' + year;

      // let invoiceFromDateObj = new Date(invoiceDateRange[0]);
      // let invoiceFromDay = invoiceFromDateObj.getDate();
      // let invoiceFromMonth = invoiceFromDateObj.getMonth() + 1;
      // let invoiceFromYear = invoiceFromDateObj.getFullYear();

      // let invoiceFromDate = (invoiceFromDay < 10 ? '0' + invoiceFromDay : invoiceFromDay) + '-' + (invoiceFromMonth < 10 ? '0' + invoiceFromMonth : invoiceFromMonth) + '-' + invoiceFromYear;

      // let invoiceToDateObj = new Date(invoiceDateRange[1]);
      // let invoiceToDay = invoiceToDateObj.getDate();
      // let invoiceToMonth = invoiceToDateObj.getMonth() + 1;
      // let invoiceToYear = invoiceToDateObj.getFullYear();

      // let invoiceToDate = (invoiceToDay < 10 ? '0' + invoiceToDay : invoiceToDay) + '-' + (invoiceToMonth < 10 ? '0' + invoiceToMonth : invoiceToMonth) + '-' + invoiceToYear;

      // setInvoiceDate(invoiceDate);
      // setInvoiceFromDate(invoiceFromDate);
      // setInvoiceToDate(invoiceToDate);

    }
    dispatch(requestInvoiceDetail({ invoiceId: id, onSuccess }))
  }

  const sendInvoice = (invoiceId, isSendingEmail = true) => {
    isSendingEmail ? setIsSubmitting(true) : setSaving(true);

    const onSuccess = (data) => {
      //console.log('data...', data);
      if (data.success) {
        if (data.data && data.data.paymentLink) {
          const detail = invoiceDetail;
          detail.paymentDetail.link = data.data.paymentLink;
          setInvoiceDetail(detail)
        }

        isSendingEmail ? setIsSubmitting(false) : setSaving(false);

        toast.success(data.message, {
          theme: 'colored'
        });

        //getUsers();
      }
    }
   
    const totalOldAmountToPay = _getLocalStorage("totalAmount");
    const oldCurrency = _getLocalStorage("currency");
    
    dispatch(requestSendInvoice({ invoiceId, isSendingEmail, totalOldAmountToPay, oldCurrency,  onSuccess }))
  }

  return (
    <>
      {invoiceDetail && (
        <>
          <Card className="mb-3">
            <Card.Body>
              <Row className="justify-content-between align-items-center">
                <Col md>
                  <h5 className="mb-2 mb-md-0">Order #{invoiceDetail?.orderId}</h5>
                </Col>
                <Col xs="auto">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="pencil-alt"
                    className="me-2 mb-2 mb-sm-0"
                    iconClassName="me-1"
                    onClick={async (e) => {
                      e.preventDefault();
                      navigate(clientId ? `/clients/${clientId}/invoice/edit/${id}` : `/invoice/edit/${id}`)
                    }}
                  >
                    Edit
                  </IconButton>
                  {/* {invoiceDetail?.paymentDetail?.link && ( */}
                    {/* <> */}
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-down"
                        className="me-2 mb-2 mb-sm-0"
                        iconClassName="me-1"
                        onClick={async (e) => {
                          e.preventDefault()
                          const htmlString = await getInvoiceHtml(invoiceDetail)
                          // const fileName = 'invoice.pdf';
                          let today = Date.now();
                          let fileName = `NV_${invoiceDetail.invoiceId}_${today}.pdf`;
                          html2pdf().from(htmlString).set({ filename: fileName, margin: [10, 5, 10, 5], html2canvas: { scale: 2 } }).save();
                        }}
                      >
                        Download (.pdf)
                      </IconButton>
                      <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="print"
                        iconClassName="me-1"
                        className="me-2 mb-2 mb-sm-0"
                        onClick={async (e) => {
                          e.preventDefault();
                          debugger
                          const htmlString = await getInvoiceHtml(invoiceDetail);
                          const iframe = document.createElement("iframe");
                          iframe.style.cssText = "display:none;"
                          document.body.appendChild(iframe);
                          iframe.contentWindow.document.open();
                          iframe.contentWindow.document.write(htmlString);
                          iframe.contentWindow.document.close();
                          iframe.contentWindow.print();
                        }}
                      >
                        Print
                      </IconButton>
                    {/* </> */}
                  {/* )} */}
                  {invoiceDetail.generatePaymentLink && (
                    <Button
                      variant="primary sfsdf"
                      size="sm"
                      icon="dollar-sign"
                      className="mb-2 mb-sm-0 me-2"
                      disabled={isSaving}
                      onClick={(e) => { e.preventDefault(); sendInvoice(id, false) }}
                    >
                      {isSaving ? 'Saving...' : 'Save'
                        // <span className="spinner-grow spinner-grow-sm"></span>
                      }
                      
                    </Button>
                  )}
                  <Button
                    variant="primary sfsdf"
                    size="sm"
                    icon="dollar-sign"
                    className="mb-2 mb-sm-0 px-5"
                    disabled={isSubmitting}
                    onClick={(e) => { e.preventDefault(); sendInvoice(id) }}
                  >
                    {isSubmitting ? 'Sending...' : 'Send'
                      // <span className="spinner-grow spinner-grow-sm"></span>
                    }
                    
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-3" id="printablediv">
            <Card.Body>
              {/* align-items-center  */}
              <Row className="text-center mb-3">
                <Col sm={6} className="text-sm-start">
                  <h2 className="mb-3">
                    <img src={logoInvoice} alt="invoice" width={150} />
                  </h2>
                  <p className="fs--1 mb-0">
                    Ph: +91-8447189572 <br />
                    E-Mail: info@navoto.com
                  </p>
                  {/* <p className="fs--1">
                <a href="mailto:info@navoto.com">info@navoto.com</a>
                <br />
                <a href="tel:91-8447189572">+91-8447189572</a>
              </p> */}
                </Col>
                <Col className="text-sm-end mt-3 mt-sm-0">
                  <h2 className="mb-3">Invoice</h2>
                  <h5>Navoto India Private Limited</h5>
                  <p className="fs--1 mb-0">
                    CIN : U72900DL2020PTC373996 <br />
                    Incuspaze A-68, Block A, Sector 64,<br />
                    Noida, Uttar Pradesh 201301<br />
                    GSTIN No. 07AAHCN3089Q1ZO
                  </p>
                </Col>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <h6 className="text-500">Invoice to</h6>
                  {/* {!invoiceDetail?.clientId && (
                    <p className="fs--1">
                      {invoiceDetail.firstName} {invoiceDetail.lastName}
                    </p>
                  )} */}

                  <p className="fs--1 mb-0 invoice-to" dangerouslySetInnerHTML={{__html: invoiceDetail.invoiceTo}}>
                    {/* <b>{invoiceDetail.invoiceTo}</b> */}
                  </p>
                  <p className="fs--1 mb-0">
                  {invoiceDetail.firstName} {invoiceDetail.lastName}
                  </p>
                  {invoiceDetail?.client && (
                  <p className="fs--1 mb-0">
                  <span className="text-500">Address</span>: {invoiceDetail?.client?.billingDetail?.address} <br/> {invoiceDetail?.client?.billingDetail?.city} <br/> {invoiceDetail?.client?.billingDetail?.country}
                  </p>
                  )}
                  {/* <p className="fs--1">
                    <a href={`mailto:${invoiceDetail.email}`}>{invoiceDetail.email}</a>
                    <br />
                    <a href={`tel:${invoiceDetail.phone}`}>{invoiceDetail.phone}</a>
                  </p> */}


                </Col>
                <Col sm="auto" className="ms-auto">
                  <div className="table-responsive">
                    <Table borderless size="sm" className="fs--1">
                      <tbody>
                        <tr>
                          <th className="text-sm-end">Invoice No:</th>
                          <td>{invoiceDetail.invoiceId}</td>
                        </tr>
                        <tr>
                          <th className="text-sm-end">Order Number:</th>
                          <td>{invoiceDetail.orderId}</td>
                        </tr>
                        <tr>
                          <th className="text-sm-end">Invoice Date:</th>
                          {/* <td>{invoiceDate}</td> */}
                          <td>{invoiceDetail.createdAt ? invoiceDetail?.createdAt?.split("T")[0] : ''}</td>
                        </tr>
                        <tr>
                          <th className="text-sm-end">Payment Due:</th>
                          <td>Upon receipt</td>
                        </tr>
                        <tr>
                          <th className="text-sm-end">Date Range:</th>
                          {/* <td>From {invoiceFromDate} to {invoiceToDate}</td> */}
                          {(invoiceDetail && invoiceDetail?.dateRange && invoiceDetail?.dateRange.length > 0) && (
                            <td>From {invoiceDetail?.dateRange[0]?.split("T")[0]} to {invoiceDetail?.dateRange[1]?.split("T")[0]}</td>
                          )}

                        </tr>
                        <tr className="alert alert-success fw-bold">
                          <th className="text-sm-end">Amount Due:</th>
                          <td>{CURRENCY[invoiceDetail.currency]} {invoiceDetail.invoiceTotal}</td>
                        </tr>
                        {(invoiceDetail?.paymentDetail?.link && invoiceDetail?.generatePaymentLink) && (
                          <>
                            <tr>
                              <th className="text-sm-end">Payment Link:</th>
                              <td> {invoiceDetail?.paymentDetail?.link || ''}</td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
              <div className="mt-4 fs--1">
                <SimpleBarReact>
                  <Table>
                    <thead className="light">
                      <tr className="bg-light text-dark dark__bg-1000">
                        <th className="border-0">Project</th>
                        <th className="border-0">Time Spent</th>
                        <th className="border-0 text-center">Items</th>
                        <th className="border-0 text-center">Subotal</th>
                        <th className="border-0 text-center">Discount</th>
                        <th className="border-0 text-end">Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(invoiceDetail.projects !== undefined && invoiceDetail.projects.length > 0) && invoiceDetail.projects.map((item, i) => {
                        return (

                          <tr key={'line_items_' + i}>
                            <td className="align-middle">
                              <h6 className="mb-0 text-nowrap">
                                {item.projectName}
                              </h6>
                            </td>
                            <td className="align-middle">{item.timeSpent} {item?.timeIn || 'Hrs'}</td>
                            <td className="align-middle text-center">1</td>
                            <td className="align-middle text-center">{CURRENCY[invoiceDetail.currency]} {item.subTotal}</td>
                            <td className="align-middle text-center">{CURRENCY[invoiceDetail.currency]} {parseFloat(item.discount).toFixed(2)}</td>
                            <td className="align-middle text-end">{CURRENCY[invoiceDetail.currency]} {item.totalAmount}</td>
                          </tr>

                        );
                      })}


                    </tbody>
                  </Table>
                </SimpleBarReact>
              </div>

              <Row className="justify-content-end">
                <Col lg="8">
                  <Table borderless size="sm" className="fs--1">
                    <tbody>
                      <tr>
                        <td className="align-middle" colSpan={2}>
                          <h6 className="mb-0 text-nowrap">Bank Account Detail</h6>
                          <p>
                            Bank Name: {invoiceDetail.bankName} <br />
                            Benificary Name: {invoiceDetail.benificaryName} <br />
                            Account No: {invoiceDetail.accountNo} <br />
                            IFS Code: {invoiceDetail.ifscCode} <br />
                            SWIFT Code: {invoiceDetail.swiftCode} <br />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg="4">
                  <Table borderless size="sm" className="fs--1 text-end">
                    <tbody>
                      <tr>
                        <th className="text-900">Subtotal:</th>
                        <td className="fw-semi-bold">{CURRENCY[invoiceDetail.currency]} {invoiceDetail.invoiceSubtotal}</td>
                      </tr>
                      <tr>
                        <th className="text-900">Discount:</th>
                        <td className="fw-semi-bold">{CURRENCY[invoiceDetail.currency]} {invoiceDetail.invoiceDiscount}</td>
                      </tr>
                      <tr className="border-top">
                        <th className="text-900">Total:</th>
                        <td className="fw-semi-bold">{CURRENCY[invoiceDetail.currency]} {invoiceDetail.invoiceTotal}</td>
                      </tr>
                      <tr className="border-top border-top-2 fw-bolder text-900">
                        <th>Amount Due:</th>
                        <td>{CURRENCY[invoiceDetail.currency]} {invoiceDetail.invoiceTotal}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="bg-light">
              <p className="fs--1 mb-0">
                <strong>Notes: </strong> We really appreciate your business and if
                there’s anything else we can do, please let us know!
              </p>
            </Card.Footer>
          </Card>
        </>
      )}
    </>
  );
};

export default InvoiceDetail;
