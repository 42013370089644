import { createAction } from "redux-actions";
import { constants } from "./constants";

export const requestGetEmployees = createAction(constants.GET_EMPLOYEES);
export const requestGetEmployee = createAction(constants.GET_EMPLOYEE);
export const requestAddEmployee = createAction(constants.ADD_EMPLOYEE);
export const requestEditEmployee = createAction(constants.EDIT_EMPLOYEE);
export const requestDeleteEmployee = createAction(constants.DELETE_EMPLOYEE);
export const requestAddEmployeeLeave = createAction(constants.ADD_EMPLOYEE_LEAVE);
export const requestEditEmployeeLeave = createAction(constants.EDIT_EMPLOYEE_LEAVE);
export const requestGetEmployeeLeave = createAction(constants.GET_EMPLOYEE_LEAVE);
export const requestGetEmployeeLeaveByEmployeeId = createAction(constants.GET_EMPLOYEE_LEAVE_BY_EMPLOYEE_ID);
export const requestGetEmployeesForTimeline = createAction(constants.GET_EMPLOYEE_FOR_TIMELINE);

