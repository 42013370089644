import { handleActions } from "redux-actions";
import { initialState } from "./initialState";
import { constants } from "./constants";
import { requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";

export default handleActions(
    {
        [requestSuccess(constants.AUTH_USER)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
        }),
        [requestPending(constants.SIGN_IN)]: (
            state,
            action
        ) => ({
            ...state,
            signInPending: true,
            signInSuccess: false,
            signInFail: false,
        }),
        [requestSuccess(constants.SIGN_IN)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
            signInPending: false,
            signInSuccess: true,
            signInFail: false,
        }),
        [requestFail(constants.SIGN_IN)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
            signInPending: false,
            signInSuccess: false,
            signInFail: true,
        }),
        [requestPending(constants.SIGN_UP)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestSuccess(constants.SIGN_UP)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestFail(constants.SIGN_UP)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestPending(constants.FETCH_USER)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestSuccess(constants.FETCH_USER)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestFail(constants.FETCH_USER)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        })
        ,
        [requestPending(constants.LOG_OUT)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestSuccess(constants.LOG_OUT)]: (
            state,
            action
        ) => ({
            ...state,
            authUser: action.payload,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        }),
        [requestFail(constants.LOG_OUT)]: (
            state,
            action
        ) => ({
            ...state,
            signUpPending: true,
            signUpSuccess: false,
            signUpFail: false,
        })
    },
    initialState()
);
