import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
// SAGA
import productSaga from "./Product/saga";
import authSaga from "../../../components/authentication/Middleware/saga";
import leadSaga from "../../../components/leads/Middleware/saga";
import userSaga from "../../../components/users/Middleware/saga";
import roleSaga from "../../../components/roles/Middleware/saga";
import clientSaga from "../../../components/clients/Middleware/saga";
import invoiceSaga from "../../../components/invoices/Middleware/saga";
import projectSaga from "../../../components/projects/Middleware/saga";
import leaveSaga from "../../../components/leaves/Middleware/saga";
import employeeSaga from "../../../components/employees/Middleware/saga";
import companySaga from "../../../components/company/Middleware/saga";
import logSaga from "../../../components/logs/Middleware/saga";
import permissionSaga from "../../../components/permissions/Middleware/saga";
import taskSaga from "../../../components/tasks/Middleware/saga";
import wchatSaga from "../../../components/chat/Middleware/saga";

// REDUCERS
import productReducer from "./Product/reducer";
import authReducer from "../../../components/authentication/Middleware/reducer";
import leadReducer from "../../../components/leads/Middleware/reducer";
import userReducer from "../../../components/users/Middleware/reducer";
import roleReducer from "../../../components/roles/Middleware/reducer";
import clientReducer from "../../../components/clients/Middleware/reducer";
import invoiceReducer from "../../../components/invoices/Middleware/reducer";
import projectReducer from "../../../components/projects/Middleware/reducer";
import leaveReducer from "../../../components/leaves/Middleware/reducer";
import employeeReducer from "../../../components/employees/Middleware/reducer";
import companyReducer from "../../../components/company/Middleware/reducer";
import logReducer from "../../../components/logs/Middleware/reducer";
import permissionReducer from "../../../components/permissions/Middleware/reducer";
import taskReducer from "../../../components/tasks/Middleware/reducer";
import wchatReducer from "../../../components/chat/Middleware/reducer";

const appReducer = combineReducers({
    product: productReducer,
    lead: leadReducer,
    auth: authReducer,
    user: userReducer,
    client: clientReducer,
    role: roleReducer,
    invoice: invoiceReducer,
    project:projectReducer,
    leave:leaveReducer,
    employee:employeeReducer,
    company:companyReducer,
    log: logReducer,
    permission:permissionReducer,
    task:taskReducer,
    wchat: wchatReducer
});

export const reducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export const rootSaga = function* () {
    yield all([
        productSaga(),
        leadSaga(),
        authSaga(),
        userSaga(),
        clientSaga(),
        roleSaga(),
        invoiceSaga(),
        projectSaga(),
        leaveSaga(),
        employeeSaga(),
        companySaga(),
        logSaga(),
        permissionSaga(),
        taskSaga(),
        wchatSaga()
    ]);
};
