import { call, put, takeLatest } from "redux-saga/effects";
import { constants } from "./constants";
import { request, requestFail, requestPending, requestSuccess } from "../../../Middleware/fetch";
import { apiConfig } from "config/apiConfig";

function* requestToGetClients(action) {
    yield call(
        request({
            type: constants.GET_CLIENTS,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `clients`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToGetClient(action) {
    yield call(
        request({
            type: constants.GET_CLIENT,
            baseURL: apiConfig.API_END_POINT,
            method: "GET",
            redirect: 'follow',
            url: `clients/${action.payload.clientId}`,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToAddClient(action) {
    yield call(
        request({
            type: constants.ADD_CLIENT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `${apiConfig.API_END_POINT}clients/add`,
            data: action.payload.formData,
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}


function* requestToUpdateClient(action) {
    yield call(
        request({
            type: constants.EDIT_CLIENT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `${apiConfig.API_END_POINT}clients/edit`,///${action.payload.userId}
            data: action.payload.formData,
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToDeleteClient(action) {
    yield call(
        request({
            type: constants.DELETE_CLIENT,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `users/delete`,///${action.payload.userId}
            data: { userId: action.payload.userId },
            //headers: { Authorization: `Bearer ${action.payload.token}` },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

function* requestToGetClientList(action) {
        
    yield call( 
        request({
            type: constants.CLIENT_LIST,
            baseURL: apiConfig.API_END_POINT,
            method: "POST",
            redirect: 'follow',
            url: `clients/list`, //${action.payload.clientId || ""}
            data: action.payload.formData,
            // data: action.payload.users,
            //headers: { Authorization: `Bearer ${action.payload.token}`, 'X-Firebase-AppCheck': appCheckToken },
            success: action.payload.onSuccess,
            fail: action.payload.onFail
        }),
        action
    );
}

export default function* rootSaga() {
    yield takeLatest(constants.GET_CLIENTS, requestToGetClients);
    yield takeLatest(constants.GET_CLIENT, requestToGetClient);
    yield takeLatest(constants.ADD_CLIENT, requestToAddClient);
    yield takeLatest(constants.EDIT_CLIENT, requestToUpdateClient);
    yield takeLatest(constants.DELETE_CLIENT, requestToDeleteClient);
    yield takeLatest(constants.CLIENT_LIST, requestToGetClientList);
}
