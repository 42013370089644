import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { publish } from "Services/events";
import { fetchLoggedInUser } from 'Services/firebase';
import { loggedInUserObject } from "Services/localStorageService";
import { useDispatch } from "react-redux";
import { requestUserRolePermission } from "components/users/Middleware/action";

const RequireAuth = ({ allowedRoles,allowedPermissions }) => {

    const [loggedInUser, setLoggedInUser] = useState({});
    const [userRole, setUserRole] = useState("");
    const [loggedInUserId,setLoggedInUserId]=useState();
    const [loading, setLoading] = useState(true);
    const [permissionLoading, setPermissionLoading] = useState(true);
    const [loggedInUserPermission,setLoggedInUserPermission]=useState([]);
    const location = useLocation();

    const dispatch = useDispatch();

    useEffect(() => {
        getAndSetLoggedInUser()
    }, []);

    useEffect(() => {
        if(Object.keys(loggedInUser).length > 0)
        {
            getLoggedInUserPermission(loggedInUser);
        }
        
    }, [loggedInUser]);

    const getAndSetLoggedInUser = async () => {
        setLoading(true);
        const user = await fetchLoggedInUser();
        setLoading(false);
        if (user) {
            setLoggedInUser(user);
            const idToken = await user.getIdTokenResult();

            setLoggedInUserId(user.uid);

            const role = JSON.parse(user.reloadUserInfo.customAttributes).role;

            setUserRole(role);

           // loggedInUserObject.role = role;
           publish("userRole", role);
        }
    }

    const getLoggedInUserPermission = async () =>{
        setPermissionLoading(true);
        const onSuccess = (data) => {
           
            setLoggedInUserPermission(data.userPermission.permissions.rolePermissions);
            publish("userPermission", data.userPermission.permissions.rolePermissions[0]);
            setPermissionLoading(false);
            
        }

        dispatch(requestUserRolePermission({ userId: loggedInUserId, onSuccess }));

    }

    // return (
    //     loading ? "Loading..." : (loggedInUser && userRole && allowedRoles.includes(userRole))
    //         ? <Outlet context={[loggedInUserPermission]}/>
    //         : 
    //         (loggedInUser && userRole && !allowedRoles.includes(userRole)) ? 
    //         <Navigate to="errors/500" state={{ from: location }} replace /> 
    //         :

    //         <Navigate to="auth/login" state={{ from: location }} replace />

    // );

    return (
        (loading ) ? "Loading..." : 
            (permissionLoading) ? "Loading...":    
            (loggedInUser && ((userRole && allowedRoles.includes(userRole)) || allowedPermissions.some(x=>loggedInUserPermission[0][x])))
            ? <Outlet context={[loggedInUserPermission,loggedInUser]}/>
            : 
            (loggedInUser && ( !(userRole && allowedRoles.includes(userRole)) || allowedPermissions.some(x=>loggedInUserPermission[0][x]))) ? 
            <Navigate to="errors/403" state={{ from: location }} replace /> 
            :

            <Navigate to="auth/login" state={{ from: location }} replace />    
    );
}

export default RequireAuth;