import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Row,Col, Card, Dropdown } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../assets/scss/custom.scss";
import {  requestGetCompany } from './Middleware/action';
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { useOutletContext } from "react-router-dom";

const Company = () => {
    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const { company, requestInPending, requestInSuccess } = useSelector(store => store.company);
    const [addCompanyPermission, setAddCompanyPermission] = useState(false);
    let navigate = useNavigate();
    const [allCompany, setAllCompany] = useState([]);


    useEffect(() => {
        getCompany();
        if(loggedInUserPermission.length)
        {   
            setAddCompanyPermission(loggedInUserPermission[0].add_company);
        }
    }, []);


    const getCompany = async () => {
        const onSuccess = (data) => {
            setAllCompany(data.company[0]);
        }
        dispatch(requestGetCompany({ onSuccess }))
    }

    return (
        <>
        <Row className="g-3 mb-3">
        <Col lg={6}>
        <Card>
          <FalconCardHeader title={'Company Detail'} />
          
           <Card.Body className="bg-light">
            <div className="col-md-12">
              <label className="col-md-4">Test</label>
              <div className="col-md-8">
                Test
              </div>
            </div>
            <h6>Company Name: {allCompany.companyName}</h6>
            <h6>Email: {allCompany.email}</h6>
            <h6>Phone: {allCompany.phone}</h6>
            <h6>CIN: {allCompany.CIN}</h6>
            <h6>GSTNo: {allCompany.GSTNo}</h6>
            <h6>City: {allCompany.city}</h6>
            <h6>Country: {allCompany.country}</h6>
            <h6>Address: {allCompany.address}</h6>
            <h6>Pincode: {allCompany.pincode}</h6>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <FalconCardHeader title={'Bank Detail'} />
          
            <Card.Body className="bg-light">
            <h6>Bank Name: {allCompany.bankName}</h6>
            <h6>Benificary Name: {allCompany.benificaryName}</h6>
            <h6>Account No: {allCompany.accountNo}</h6>
            <h6>Ifsc Code: {allCompany.ifscCode}</h6>
            <h6>Swift Code: {allCompany.swiftCode}</h6>
            <h6>Country: {allCompany.bankCountry}</h6>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {addCompanyPermission && (
        <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={(e)=>{e.preventDefault(); navigate(`/company/edit/${allCompany._id}`) }}
            >
              <span className="d-none d-sm-inline-block ms-1" >Edit</span>
        </IconButton>
        )}
        {/*<IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={(e)=>{e.preventDefault(); navigate(`/company/add/`) }}
            >
              <span className="d-none d-sm-inline-block ms-1" >Add</span>
        </IconButton>*/}
        </>
    );
};

export default Company;
