import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import {downloadExcel} from 'helpers/custom';
import {fixTimezoneOffset,convertSecondsToHMS} from '../../helpers/custom';

const TaskTableHeader = ({ selectedRowIds,addTaskPermission,register,control,pageChangeHandler,getValues,setValue,name,setSearchParam,isLoadingEmployee,isLoadingProject,allEmployee,allProject,TASK_STATUS,searchTaskAssignedDate,setSearchTaskAssignedDate,projectId,allTasks,filterProjectName  }) => {
  
  let navigate = useNavigate();

  const [showFilter , setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [assignedStartDate, assignedEndDate] = dateRange;

  const showHideFilter=()=>{
    showFilter ? setShowFilter(false) :setShowFilter(true);      
  }

  const search =()=>{
    const taskName = getValues("taskName");
    const assignedTo = getValues("assignedTo");
    const projectId = getValues("projectId");
    //const taskAssignedDate =dateRange;
    const taskStatus = getValues("taskStatus");

    let startDate='';
    let endDate='';

    if(dateRange[0] !=undefined)
    {
      startDate = fixTimezoneOffset(dateRange[0]);
    }

    if(dateRange[1] !=undefined)
    {
      endDate = fixTimezoneOffset(dateRange[1]);
    }

    let searchString ={taskName:taskName,assignedTo:assignedTo,projectId:projectId,taskAssignedDate:[startDate,endDate],taskStatus:taskStatus}

    setSearchParam(searchString);
  }

  const resetSearch= () =>{

    setValue("taskName","");
    setValue("assignedTo","");
    setValue("projectId","");
    //setValue("searchTaskAssignedDate","");
    setValue("taskStatus","");
    setDateRange([null,null]);

    let searchString =""

    setSearchParam(searchString);
  }

  const downloadTask = async (allTasks) =>{

    let taskArray=[];

    let taskArrayHeader=['Sr No.','Task Name','Person','Project Name','Assigned Date','Time Assigned','Time Spent','Task Status'];

    allTasks.map((task,index)=>{

    const assignedDate = task.taskAssignedDate.split("T")[0];

    const formatedTime = convertSecondsToHMS(task.timeSpent);

    let pushArray = {"Sr No.":(index+1),'Task Name':task.taskName, "Person":task.assignedToUserName, "Project Name":task.projectName
, "Assigned Date":assignedDate,"Time Assigned":task.assignedHours+" "+task.taskTimeType, "Time Spent":formatedTime,"Task Status":TASK_STATUS[task.taskStatus]}

     
      taskArray.push(pushArray);

      console.log("single task..",task,index+1);

    });

    downloadExcel(taskArray,taskArrayHeader,'task');

  }

  return (
    <>
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">All Tasks { filterProjectName && ( `- ${filterProjectName}`)}</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">

          <IconButton
            variant="falcon-default"
            size="sm"
            icon="arrow-down"
            className="me-2 mb-2 mb-sm-0"
            iconClassName="me-1"
            onClick={async (e) => {
              e.preventDefault()
              downloadTask(allTasks);
              //downloadExcel(formattedData,'test');

            }}
            >
            Download Excel
          </IconButton>
          {(addTaskPermission && projectId ===undefined) && (
            <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={(e)=>{e.preventDefault(); navigate("/tasks/add") }}
            >
              <span className="d-none d-sm-inline-block ms-1" >New</span>
              
            </IconButton>
            </>
              )}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={ showHideFilter}
            >
            <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
    { showFilter && (
    <>
    <Row>
      { projectId===undefined &&(
      <>
      <Col xs={2}>
        <Col>
          <Form.Label>Keyword</Form.Label>
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Keyword"
            {...register('taskName')}
          />
        </Col>
      </Col>
      <Col xs={2}>
        <Col>
          <Form.Label>Employee</Form.Label>
        </Col>
        <Col>
          <Form.Select
            {...register('assignedTo', { required: true })}
          >
            <option value="">Employee</option>
            { isLoadingEmployee && allEmployee.map( (emp, i) => <option key={i} value={emp.userId}>{emp.firstName} {emp.lastName}</option>)}
          </Form.Select>  
        </Col>
      </Col>
      <Col xs={2}>
        <Col>
          <Form.Label>Keyword</Form.Label>
        </Col>
        <Col>
          <Form.Select
            {...register('projectId', { required: true })}
          >
            <option value="">Project</option>
            { isLoadingProject && allProject.map( (project, i) => <option key={i} value={project._id.valueOf()}>{project.projectName} ({project.user[0] !=undefined && project.user[0].firstName} { project.user[0] !=undefined && project.user[0].lastName})</option>)}
          </Form.Select>
        </Col>
      </Col>
      <Col xs={2}>
        <Col>
          <Form.Label>Status</Form.Label>
        </Col>
        <Col>
          <Form.Select
            {...register('taskStatus', { required: true })}
          >
            <option value="">Status</option>
              { Object.entries(TASK_STATUS).map(([key, val]) => {
                return  <option value={key} key={val}>{val}</option>
              })}
          </Form.Select>
        </Col> 
      </Col>
        </>
      )}
      <Col xs={2}>
        <Col>
          <Form.Label>Assigned Date</Form.Label>
        </Col>
        <Col>
          <Controller
            control={control}
            name="searchTaskAssignedDate"
            render={({ field }) => (
            <DatePicker
              {...field}
              selected={searchTaskAssignedDate}
              utcOffset={0}
              dateFormat="dd/MM/yyyy"
              className='form-control'
              selectsRange={true}
              startDate={assignedStartDate}
              endDate={assignedEndDate}
              onChange={(update) => { console.log("sfsdf..",update);
                setDateRange(update);
              }}
              isClearable={true}
            />  
          )}
          />
        </Col>
      </Col>
     
      <Col xs={2} >
        <Col><Form.Label>&nbsp;</Form.Label></Col>
        <Col>
          <Button variant="primary" type="button" onClick={search}>Search</Button>
          <Button variant="secondary" type="button" onClick={resetSearch}>Reset</Button>
        </Col>
      </Col>
    </Row>
    </>
    )}
    </>
  );
};

TaskTableHeader.propTypes = {
  selectedRowIds: PropTypes.object
};

export default TaskTableHeader;
