const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_INVOICES: undefined,
  ADD_INVOICE: undefined,
  EDIT_INVOICE: undefined,
  INVOICE_DETAIL: undefined,
  SEND_INVOICE: undefined,
  UPDATE_PAYMENT_STATUS: undefined,
  DELETE_INVOICE: undefined,
  CANCEL_INVOICE: undefined,
  MARK_DONE: undefined,
  SEND_RECIEPT: undefined,
  GET_RECIEPT: undefined,
  EDIT_RECIEPT: undefined
});
