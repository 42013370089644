import { useNavigate, useParams } from "react-router-dom";
import { requestUpdatePaymentStatus } from './Middleware/action';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

const PaymentResult = () => {

        const { sessionId } = useParams();
        const dispatch = useDispatch();
        console.log("id", sessionId);

        useEffect(() => {
                updatePaymentStatus(sessionId);
        }, []);

        const updatePaymentStatus = async () => {
                const onSuccess = (data) => {
                        console.log("success");
                        //setInvoiceDetail(data.invoice);
                }
                dispatch(requestUpdatePaymentStatus({ sessionId: sessionId, onSuccess }))
        }

        return (
                <>
                        <h1>Thank you for payment.</h1>
                </>
        );
};

export default PaymentResult;
