import React, { useEffect, useState } from 'react';
import { Alert, Card, Dropdown, Tab, Tabs } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetInvoices, requestDeleteInvoice, requestMarkAsDone, requestCancelInvoice, sendReciept, requestEditReciept } from './Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import InvoiceTableHeader from './InvoiceTableHeader';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { CURRENCY } from '../../enum/enum.js';
import { useOutletContext } from "react-router-dom";
import ServerSideAdvanceTablePagination from 'components/permissions/ServerSideAdvanceTablePagination';
import { requestGetClient } from "../clients/Middleware/action";
import { useForm, Controller } from "react-hook-form";
import { getInvoiceHtml } from './helper/invoiceEmailHtml';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import JSZip from 'jszip';
import DownloadInvoicesModal from './downloadModal';
import CancelInvoicesModal from './CancelnvoieModal';
import { invoicePaymentReciept } from './helper/recieptHtml';
import RecieptModal from './RecieptModal';

const Invoices = () => {

    const [loggedInUserPermission] = useOutletContext();
    const dispatch = useDispatch();
    const [allInvoices, setAllInvoices] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const { invoices, requestInPending } = useSelector(store => store.invoice);
    let navigate = useNavigate();
    const [recordCount, setRecordCount] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [pageNo, setPageNo] = useState(1);
    const [isOpen, setIsOpen] = useState(false);
    const { clientId } = useParams();
    const [clientName, setClientName] = useState('');
    const [addInvoicePermission, setAddInvoicePermission] = useState(false);
    const [deleteInvoicePermission, setDeleteInvoicePermission] = useState(false);
    const { control, register, handleSubmit, setValue, getValues, reset, formState: { errors } } = useForm();

    const [searchString, setSearchString] = useState("");
    const [downloading, setDownloading] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [invoiceId, setInvoiceId] = useState("");
    const [activeTab, setActiveTab] = useState('Completed');
    const [invoice, setInvoice] = useState(null);
    const [receiptModalOpen, setReceiptModalOpen] = useState(null);
    const [newReciept, setNewReciept] = useState(null);

    const setSearchParam = (value) => {
        setSearchString((prev) => { return value });
        pageChangeHandler(1, value);
    }

    useEffect(() => {
        setLoading(true);
        if (invoices && invoices.length > 0) {
            setLoading(false);
            setAllInvoices(invoices);
        }
    }, []);

    useEffect(() => {
        if (activeTab) {
            getInvoices();
        }
    }, [activeTab])

    useEffect(() => {
        // getInvoices();
        if (loggedInUserPermission.length) {   //console.log(loggedInUserPermission[0].delete_user);
            setAddInvoicePermission(loggedInUserPermission[0].add_invoice);
            setDeleteInvoicePermission(loggedInUserPermission[0].delete_invoice)
        }
    }, []);

    const getInvoices = async (searchValue) => {
        setAllInvoices([]);
        setLoading(true);
        const onSuccess = (data) => {
            setLoading(false);
            setAllInvoices(data.invoices);
            setRecordCount(data.recordCount);
        }

        if (!searchValue && clientId) {
            searchValue = { clientId: clientId }
        } else if (searchValue && clientId) {
            searchValue.clientId = clientId;
        }

        const formData = { 'pageNo': pageNo, 'perPage': perPage, 'search': searchValue, status: activeTab }
        dispatch(requestGetInvoices({ formData, onSuccess }))
    }

    const deleteInvoice = (invoiceId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`Invoice deleted successfully`, {
                    theme: 'colored'
                });
                getInvoices();
            }
        }
        dispatch(requestDeleteInvoice({ invoiceId, onSuccess }))
    }

    const handleCancelModal = (invoiceId) => {
        if (invoiceId) {
            setInvoiceId(invoiceId);
        }
        setCancelModal(!cancelModal);
    }

    const cancelInvoice = (reason) => {
        const onSuccess = (data) => {
            if (data.success) {
                handleCancelModal();

                toast.success(`Invoice canceled successfully`, {
                    theme: 'colored'
                });
                getInvoices();
            }
        }

        const onFail = (data) => {
            if (!data.success) {
                toast.error(data.response.data.message, {
                    theme: 'colored'
                });
            }
        }
        dispatch(requestCancelInvoice({ invoiceId, reason, onSuccess, onFail }))
    }

    const markAsCompleted = (invoiceId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`Invoice mark as completed successfully`, {
                    theme: 'colored'
                });
                getInvoices();
            }
        }
        const onFail = (data) => {
            if (!data.success) {
                toast.error(data.response.data.message, {
                    theme: 'colored'
                });
            }
        }
        dispatch(requestMarkAsDone({ invoiceId, onSuccess, onFail }))
    }

    const sendRecieptEmail = (invoiceId) => {
        const onSuccess = (data) => {
            if (data.success) {
                
                setReceiptModalOpen(false);

                toast.success(`Receipt sent successfully`, {
                    theme: 'colored'
                });
            }
        }
        const onFail = (data) => {
            if (!data.success) {
                toast.error(data.response.data.message, {
                    theme: 'colored'
                });
            }
        }
        dispatch(sendReciept({ invoiceId, onSuccess, onFail }))
    }

    const downloadReciept = (invoice) => {
        handleDownloadReciept([invoice]);
    }

    const handleRecieptModal = (invoice) => {
        setInvoice(invoice);
        setReceiptModalOpen(true);
    }

    const handleReciept = (data, updatedReciept, isDownloading = null) => {
        const onSuccess = (data) => {
            if (data.success) {
                const newReciept = invoice;
                newReciept.createdByUser = data.userData;
                if (data.reciept) {
                    newReciept.recieptId = data.reciept.recieptId || "";
                    newReciept.receiptDate = data.reciept.recieptDate;
                }

                if (isDownloading !== null) {
                    if (isDownloading) {
                        downloadReciept(newReciept);
                    } else {
                        sendRecieptEmail(invoice._id);
                    }
                } else {
                    setNewReciept(newReciept)
                }
            }
        }

        const onFail = (data) => {
            if (!data.success) {
                toast.error(data.response.data.message, {
                    theme: 'colored'
                });
            }
        }

        const oldReceiptDate = updatedReciept.createdAt;
        const newRecieptDate = data.recieptDate ? formatDateToLocalISOString(data.recieptDate) : (updatedReciept.receipt?.recieptDate ? updatedReciept.receipt?.recieptDate : oldReceiptDate);
        dispatch(requestEditReciept({ invoiceId: invoice._id, recieptDate: newRecieptDate, onSuccess, onFail }))
    }

    const formatDateToLocalISOString = (date) => {
        const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTime = new Date(date - tzOffset).toISOString().slice(0, -1); // remove the 'Z' at the end
        return localISOTime;
    };


    const handleDownloadReciept = async (recieptsToDownload) => {
        // setDownloading(true);

        const allHtmlData = [];
        for (var i = 0; i < recieptsToDownload.length; i++) {
            const invoiceDetail = recieptsToDownload[i];
            const htmlData = await invoicePaymentReciept(invoiceDetail, true);
            let today = Date.now();
            let fileName = `NV_${invoiceDetail.invoiceId}_${today}.pdf`;
            allHtmlData.push({ fileName: fileName, htmlString: htmlData });
        }

        if (allHtmlData && allHtmlData.length > 0) {
            var zip = new JSZip();
            for (const htmlData of allHtmlData) {
                // await html2pdf().from(htmlData.htmlString).set({ margin: [10, 5, 10, 5] }).outputPdf().then(function (pdf) {
                //     const pdfData = btoa(pdf);
                //     zip.file(`${htmlData.fileName}`, pdfData, { base64: true });
                // }, (error) => {
                // });

                await html2pdf().from(htmlData.htmlString).set({
                    margin: [10, 5, 10, 5], filename: htmlData.fileName, 
                    image: { type: 'jpeg', quality: 1 },
                    jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
                    html2canvas: { scale: 3, logging: true, dpi: 300, letterRendering: true },
                }).outputPdf().save();
            }

            // zip.generateAsync({ type: "blob" }).then(function (content) {
            //     saveAs(content, "reciept.zip");
            //     // setDownloading(false);
            // });
        }
    }

    const pageChangeHandler = (pageNo, searchValue = "") => {
        setPageNo(pageNo);

        // if (searchString) {
        //     searchValue = searchString;
        // }

        getInvoices(searchValue);
    }

    useEffect(() => {
        if (clientId) {
            getClientName(clientId);
        }
    }, [clientId]);

    const getClientName = (clientId) => {
        const onSuccess = (data) => {
            if (data.success) {
                const client = data.user;
                setClientName(client.firstName + ' ' + client.lastName);
            }
        }
        dispatch(requestGetClient({ clientId, onSuccess }))
    }

    const toggleDownloadModal = () => {
        setIsOpen(!isOpen);
    }

    const handleDownload = (dateRange) => {
        setDownloading(true);
        const onSuccess = (data) => {
            handleDownloadInvoices(data.invoices);
        }

        const formData = { 'search': { dateRange: dateRange } }
        dispatch(requestGetInvoices({ formData, onSuccess }))
    }

    const handleDownloadInvoices = async (invoicesToDownload) => {
        const allHtmlData = [];
        for (var i = 0; i < invoicesToDownload.length; i++) {
            const invoiceDetail = invoicesToDownload[i];
            const htmlData = await getInvoiceHtml(invoiceDetail);
            let today = Date.now();
            let fileName = `NV_${invoiceDetail.invoiceId}_${today}.pdf`;
            allHtmlData.push({ fileName: fileName, htmlString: htmlData });
        }

        if (allHtmlData && allHtmlData.length > 0) {
            var zip = new JSZip();
            for (const htmlData of allHtmlData) {
                await html2pdf().from(htmlData.htmlString).set({ margin: [10, 5, 10, 5] }).outputPdf().then(function (pdf) {
                    const pdfData = btoa(pdf);
                    zip.file(`${htmlData.fileName}`, pdfData, { base64: true });
                }, (error) => {
                });
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, "invoices.zip");
                setDownloading(false);
            });
        }
    }

    const columns = [
        {
            accessor: 'firstName',
            Header: 'First Name',
        },
        {
            accessor: 'lastName',
            Header: 'Last Name',
            disableSortBy: true,
        },
        {
            accessor: 'email',
            Header: 'Email',
            disableSortBy: true,
        },
        {
            accessor: 'invoiceTotal',
            Header: 'Amount',
            Cell: (rowData) => {
                const { invoiceTotal, currency } = rowData.row.original;

                let total = CURRENCY[currency] + ' ' + invoiceTotal;
                return total;
            }
        },
        {
            accessor: 'paymentDetail.paymentStatus',
            Header: 'Payment Status',
            disableSortBy: true,
            Cell: rowData => {
                const { paymentDetail } = rowData.row.original;
                if (paymentDetail) {
                    const status = paymentDetail.paymentStatus;
                    return (
                        <SoftBadge
                            pill
                            bg={classNames({
                                success: status === 'Completed',
                                warning: status === 'Pending',
                                secondary: status === 'Failed'
                            })}
                            className="d-flex flex-center"
                        >
                            <p className="mb-0">
                                {status === 'Completed' && 'Completed'}
                                {status === 'Pending' && 'Pending'}
                                {status === 'Failed' && 'Failed'}
                            </p>
                            <FontAwesomeIcon
                                icon={classNames({
                                    check: status === 'Completed',
                                    stream: status === 'Pending',
                                    ban: status === 'Failed'
                                })}
                                transform="shrink-2"
                                className="ms-1"
                            />
                        </SoftBadge>
                    );
                } else {
                    return "";
                }
            }
        },
        {
            accessor: 'createdAt',
            Header: 'Created At',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;

                let objectDate = new Date(createdAt);

                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();

                let invoiceDate = (day < 10 ? '0' + day : day) + '-' + (month < 10 ? '0' + month : month) + '-' + year;
                return invoiceDate;
            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id, paymentDetail } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                                {addInvoicePermission && (
                                    <>
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate((clientId ? `/clients/${clientId}/invoice/` : "/invoice/") + _id) }}><FontAwesomeIcon icon="info-circle" /> Detail</Dropdown.Item>
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate((clientId ? `/clients/${clientId}/invoice/edit/` : "/invoice/edit/") + _id) }}><FontAwesomeIcon icon="pencil-alt" /> Edit</Dropdown.Item>
                                    </>
                                )}
                                {deleteInvoicePermission && (
                                    <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteInvoice(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                )}
                                {paymentDetail.paymentStatus === "Pending" && (
                                    <>
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); handleCancelModal(_id) }}><FontAwesomeIcon icon="ban" /> Cancel Invoice</Dropdown.Item>
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); markAsCompleted(_id) }}><FontAwesomeIcon icon="check" /> Mark As Completed</Dropdown.Item>
                                    </>
                                )}
                                {paymentDetail.paymentStatus === "Completed" && (
                                    <>
                                        {/* downloadReciept(rowData.row.original) sendRecieptEmail(_id)*/}
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); handleRecieptModal(rowData.row.original) }}><FontAwesomeIcon icon="download" /> Download Reciept</Dropdown.Item>
                                        <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); handleRecieptModal(rowData.row.original) }}><FontAwesomeIcon icon="envelope" /> Send Reciept</Dropdown.Item>
                                    </>
                                )}
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

    const hanldeSetActiveTab = (e) => {
        setActiveTab(e);
    }

    return (
        <>
            <Tabs defaultActiveKey={activeTab} onSelect={hanldeSetActiveTab}>
                <Tab eventKey="Completed" title="Completed">
                    <InvoiceTable
                        isLoading={isLoading}
                        columns={columns}
                        allInvoices={allInvoices}
                        perPage={perPage}
                        clientId={clientId}
                        addInvoicePermission={addInvoicePermission}
                        clientName={clientName}
                        register={register}
                        control={control}
                        pageChangeHandler={pageChangeHandler}
                        getValues={getValues}
                        setValue={setValue}
                        setSearchParam={setSearchParam}
                        toggleDownloadModal={toggleDownloadModal}
                        recordCount={recordCount}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                </Tab>
                <Tab eventKey="Pending" title="Pending">
                    <InvoiceTable
                        isLoading={isLoading}
                        columns={columns}
                        allInvoices={allInvoices}
                        perPage={perPage}
                        clientId={clientId}
                        addInvoicePermission={addInvoicePermission}
                        clientName={clientName}
                        register={register}
                        control={control}
                        pageChangeHandler={pageChangeHandler}
                        getValues={getValues}
                        setValue={setValue}
                        setSearchParam={setSearchParam}
                        toggleDownloadModal={toggleDownloadModal}
                        recordCount={recordCount}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                </Tab>
                <Tab eventKey="Canceled" title="Canceled">
                    <InvoiceTable
                        isLoading={isLoading}
                        columns={columns}
                        allInvoices={allInvoices}
                        perPage={perPage}
                        clientId={clientId}
                        addInvoicePermission={addInvoicePermission}
                        clientName={clientName}
                        register={register}
                        control={control}
                        pageChangeHandler={pageChangeHandler}
                        getValues={getValues}
                        setValue={setValue}
                        setSearchParam={setSearchParam}
                        toggleDownloadModal={toggleDownloadModal}
                        recordCount={recordCount}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                </Tab>
                <Tab eventKey="Failed" title="Failed">
                    <InvoiceTable
                        isLoading={isLoading}
                        columns={columns}
                        allInvoices={allInvoices}
                        perPage={perPage}
                        clientId={clientId}
                        addInvoicePermission={addInvoicePermission}
                        clientName={clientName}
                        register={register}
                        control={control}
                        pageChangeHandler={pageChangeHandler}
                        getValues={getValues}
                        setValue={setValue}
                        setSearchParam={setSearchParam}
                        toggleDownloadModal={toggleDownloadModal}
                        recordCount={recordCount}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                    />
                </Tab>
            </Tabs>

            {isOpen && (
                <DownloadInvoicesModal show={isOpen} handleClose={toggleDownloadModal} handleDownload={handleDownload} control={control} getValues={getValues} setValue={setValue} downloading={downloading} />
            )}

            {cancelModal && (
                <CancelInvoicesModal show={cancelModal} handleClose={handleCancelModal} cancelInvoice={cancelInvoice} />
            )}

            {receiptModalOpen && (
                <RecieptModal show={receiptModalOpen} invoice={invoice} handleClose={() => { setReceiptModalOpen(false) }} handleReciept={handleReciept} newReciept={newReciept} />
            )}
        </>
    );
};

export default Invoices;


const InvoiceTable = ({ isLoading, columns, allInvoices, perPage, clientId, addInvoicePermission, clientName, register, control, pageChangeHandler, getValues, setValue, setSearchParam, toggleDownloadModal, recordCount, pageNo, setPageNo }) => {
    return (
        <>
            {(!isLoading && recordCount == 0) ? (<Alert variant="warning">No data found</Alert>)
                : (
                    <AdvanceTableWrapper
                        columns={columns}
                        data={allInvoices}
                        selection
                        sortable
                        pagination
                        perPage={perPage}
                    >
                        <Card className="mb-3">
                            <Card.Header>
                                <InvoiceTableHeader
                                    table
                                    clientId={clientId}
                                    addInvoicePermission={addInvoicePermission}
                                    clientName={clientName}
                                    register={register}
                                    control={control}
                                    pageChangeHandler={pageChangeHandler}
                                    getValues={getValues}
                                    setValue={setValue}
                                    setSearchParam={setSearchParam}
                                    handleDownloadInvoices={toggleDownloadModal}
                                />
                            </Card.Header>
                            <Card.Body className="p-0">

                                {isLoading ? (
                                    <span className="spinner-grow spinner-grow-sm ms-3"></span>
                                ) : (
                                    <AdvanceTable
                                        table
                                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                                        rowClassName="align-middle white-space-nowrap"
                                        tableProps={{
                                            size: 'sm',
                                            striped: true,
                                            className: 'fs--1 mb-0 overflow-hidden'
                                        }}
                                    />
                                )}
                            </Card.Body>
                            <Card.Footer>
                                <ServerSideAdvanceTablePagination
                                    pageChangeHandler={pageChangeHandler}
                                    totalRows={recordCount}
                                    rowsPerPage={perPage}
                                    pageNo={pageNo}
                                    setPageNo={setPageNo}
                                />
                            </Card.Footer>
                        </Card>
                    </AdvanceTableWrapper>
                )}
        </>
    );
};
