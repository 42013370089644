import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useDispatch, useSelector } from "react-redux";
import { requestAddPermission, requestEditPermission, requestGetPermission } from "./Middleware/action";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const AddUpdatePermission = () => {

    const dispatch = useDispatch();
    const { authUser } = useSelector(store => store.auth);

    let navigate = useNavigate();

    const { id } = useParams();

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);


    const onSubmit = (formData) => {
        savePermission(formData);
    };

    useEffect(() => {
        if (id) {
            getPermission();
        }
    }, [id]);


    const getPermission = () => {
        setLoading(true);
        const onSuccess = (data) => { console.log("data..",data);
            setLoading(false)
            setValue('name', data.permission.name);
            setValue('status', data.permission.status);
        }
        dispatch(requestGetPermission({ permissionId: id, onSuccess }))
    }

    const savePermission = (formData) => {
        setIsSubmitting(true);
        const onSuccess = (data) => {
            setIsSubmitting(false);

            if (data.success) {
                toast.success(`Permission ${id ? 'updated' : 'added'} successfully`, {
                    theme: 'colored'
                });
                navigate("/permissions");
            }
        }

        if (id) {
            const data = formData;
            data.permissionId = id;
            dispatch(requestEditPermission({ data, onSuccess }))
        } else {
            dispatch(requestAddPermission({ formData, onSuccess }))
        }
    }

    return (
        <Card>
            <FalconCardHeader title={id ? 'Update Permission' : 'Add Permission'} />
            <Card.Body className="bg-light">
                {loading ?
                    <span className="spinner-grow spinner-grow-sm"></span>
                    :
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3 g-3">
                            <Form.Group as={Col} lg={6}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    {...register('name', { required: true })}
                                />

                            </Form.Group>
                            <div className="invalid-feedback" style={{ display: errors.role ? 'block' : 'none' }}>Field is required</div>
                             <Form.Group as={Col} lg={6}>
                                <Form.Label>Status</Form.Label>
                                <Form.Select aria-label="Status" {...register('status', { required: true })}>
                                  <option value="1">Active</option>
                                  <option value="2">Inactive</option>

                                </Form.Select>
                                <div className="invalid-feedback" style={{ display: errors.status ? 'block' : 'none' }}>Field is required</div>
                              </Form.Group>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary" type="submit" disabled={isSubmitting} >
                                {isSubmitting && (
                                    <span className="spinner-grow spinner-grow-sm"></span>
                                )}
                                {id ? 'Update Permission' : 'Add Permission'}
                            </Button>
                        </div>
                    </Form>
                }
            </Card.Body>
        </Card>
    )
}

export default AddUpdatePermission;
