import React, { useEffect, useState } from 'react';
import { Card, Dropdown,Button,Modal,CloseButton,Form,Tabs,Tab,Col,Row } from 'react-bootstrap';
//import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { requestGetLeaves,requestDeleteLeave,requestGetLeave,requestAcceptRejectLeave} from './Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { LeaveTableHeader,MyLeaveTableHeader } from './LeaveTableHeader';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { useOutletContext } from "react-router-dom";

const Leaves = () => {
    const [loggedInUserPermission,loggedInUser] = useOutletContext();
    const dispatch = useDispatch();
    const {register,handleSubmit, setValue, getValue } = useForm();
    const [employeeLeaves, setEmployeeLeaves] = useState([]);
    const [myLeaves, setMyLeaves] = useState([]);
    const { requestInPending } = useSelector(store => store.leave);
    const [modalShow, setModalShow] = React.useState(false);
    const [isAcceptSubmitting, setIsAcceptSubmitting] = useState(false);
    const [isRejectSubmitting, setIsRejectSubmitting] = useState(false);
    const [isGettingLeaveData, setIsGettingLeaveData] = useState(false);
    const [appliedBy, setAppliedBy] = useState();
    const [noOfDays, setNoOfDays] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [leaveCategoryId, setLeaveCategoryId] = useState();
    const [leaveDescription, setLeaveDescription] = useState();
    const [leaveStatus, setLeaveStatus] = useState("Pending");
    const [approveLeavePermission, setApproveLeavePermission] = useState(false);
    const [showAcceptReject, setShowAcceptReject] = useState(false);
    const [acceptRejectReason, setAcceptRejectReason] = useState("");
    const [loggedInUserId , setLoggedInUserId]= useState();

  let navigate = useNavigate();

  useEffect(() => { 
        getLeaves();

        if(loggedInUserPermission.length)
        {   //console.log("approve_leave...",loggedInUserPermission[0].approve_leave);
            
            setApproveLeavePermission(loggedInUserPermission[0].approve_leave);
        }
       
        setLoggedInUserId(loggedInUser.uid);

  }, []);

  const getLeaves = async () => { 
        const onSuccess = (data) => {  
      
            setEmployeeLeaves(data.employeeLeaves);
            setMyLeaves(data.myLeaves)
        }
        dispatch(requestGetLeaves({ onSuccess }))
    }

    const deleteLeave = (leaveId) => {
        const onSuccess = (data) => {
            if (data.success) {
                toast.success(`Leave deleted successfully`, {
                    theme: 'colored'
                });

                getLeaves();
            }
        }
        dispatch(requestDeleteLeave({ leaveId, onSuccess }))
    }

    const acceptLeave=(formData)=>{
        
        let status = 1; //accept
        formData['status']=status;
        setIsAcceptSubmitting(true);
        const onSuccess = (data) => { console.log("data",data);
            setIsAcceptSubmitting(false);

            if (data.success) {
                toast.success(data.message, { 
                    theme: 'colored'
                });
                setModalShow(false);
                getLeaves();
            }
        }

        const onFail = (data)=>{ console.log("faile data",data);

            toast.success(data.message, {
                    theme: 'colored'
                });

        }

        dispatch(requestAcceptRejectLeave({ formData, onSuccess,onFail }))

    }
    const rejectLeave=(formData)=>{

        let status = 2; //rejected

        formData['status']=status;

        setIsRejectSubmitting(true);
        const onSuccess = (data) => { 
            setIsRejectSubmitting(false);

            if (data.success) {
                toast.success(data.message, {
                    theme: 'colored'
                });
                setModalShow(false);
                getLeaves();
           } 
        }

        const onFail = (data)=>{ 

            toast.success(data.message, {
                    theme: 'colored'
                });
        }

        dispatch(requestAcceptRejectLeave({ formData, onSuccess,onFail }))
       
    }

    const viewLeave=(leaveId)=>{
        setModalShow(true);
        setIsGettingLeaveData(true);
        const onSuccess = (data) => {
           setIsGettingLeaveData(false);
          let leaveData = data.leave[0];

          let fromObjectDate = new Date(leaveData.fromDate);
          let fromDay = fromObjectDate.getDate();
          let fromMonth = fromObjectDate.getMonth()+1;
          let fromYear = fromObjectDate.getFullYear();
          let fromDate= (fromDay<10 ?'0'+fromDay:fromDay)+'/'+(fromMonth<10?'0'+fromMonth:fromMonth)+'/'+fromYear;

          let toObjectDate = new Date(leaveData.toDate);
          let toDay = toObjectDate.getDate();
          let toMonth = toObjectDate.getMonth()+1;
          let toYear = toObjectDate.getFullYear();
          let toDate= (toDay<10 ?'0'+toDay:toDay)+'/'+(toMonth<10?'0'+toMonth:toMonth)+'/'+toYear;
         
          let appliedBy = leaveData.user[0].firstName+' '+leaveData.user[0].lastName;

          setValue('leaveId',leaveData._id.valueOf() ); 
          setAppliedBy(appliedBy);
          setFromDate(fromDate);
          setToDate(toDate);
          setNoOfDays(leaveData.noOfDays);
          setLeaveCategoryId(leaveData.leaveCategoryId);
          setLeaveDescription(leaveData.description); 
          setAcceptRejectReason(leaveData.actionReason)
                
            if(leaveData.status ===1)
            {
                setLeaveStatus('Accepted');

            } else if(leaveData.status===2)
            {
                setLeaveStatus('Rejected');
            }
            else 
            {
                setLeaveStatus('Pending');
            }

            if(loggedInUserId !== leaveData.appliedBy)
            {    
                setShowAcceptReject(true); 
            }
            else
            {
                setShowAcceptReject(false); 
            }
        }
        dispatch(requestGetLeave({ leaveId: leaveId, onSuccess }))
    }

    const myColumns = [
    {
        accessor: 'appliedBy',
        Header: 'Applied By',
        Cell: (rowData) => {
            const { user } = rowData.row.original;
            
            let name = user.firstName+' '+user.lastName;
            return name;
        }
    },
    {
        accessor: 'fromDate',
        Header: 'From',
        Cell: (rowData) => {
            const { fromDate } = rowData.row.original;
            
            let objectDate = new Date(fromDate);

            let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let fromDateReturn= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return fromDateReturn;
            }
        },
        {
            accessor: 'toDate',
            Header: 'To',
            Cell: (rowData) => {
                const { toDate } = rowData.row.original;
                
                let objectDate = new Date(toDate);

                let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let toDateReturn= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return toDateReturn;
            }
        },
        {
            accessor: 'leaveCategoryId',
            Header: 'Leave Type',
        },
        {
            accessor: 'createdAt',
            Header: 'Applied On',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;
               
                let objectDate = new Date(createdAt);

                let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let appliedDate= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return appliedDate;
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                let leaveStatus='Pending';
                
                if(status ===1)
                {
                    leaveStatus='Accepted';

                } else if(status===2)
                {
                    leaveStatus ='Rejected';

                }
               
                return leaveStatus;

            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id,status } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                            { status==null && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/leaves/edit/" + _id+"/"+loggedInUserId) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteLeave(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); viewLeave(_id) }}><FontAwesomeIcon icon="eye" /> View</Dropdown.Item>
                                
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

const employeeColumns = [
    {
        accessor: 'appliedBy',
        Header: 'Applied By',
        Cell: (rowData) => {
            const { user } = rowData.row.original;
            
            let name = user.firstName+' '+user.lastName;
            return name;
        }
    },
    {
        accessor: 'fromDate',
        Header: 'From',
        Cell: (rowData) => {
            const { fromDate } = rowData.row.original;
            
            let objectDate = new Date(fromDate);

            let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let fromDateReturn= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return fromDateReturn;
            }
        },
        {
            accessor: 'toDate',
            Header: 'To',
            Cell: (rowData) => {
                const { toDate } = rowData.row.original;
                
                let objectDate = new Date(toDate);

                let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let toDateReturn= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return toDateReturn;
            }
        },
        {
            accessor: 'leaveCategoryId',
            Header: 'Leave Type',
        },
        {
            accessor: 'createdAt',
            Header: 'Applied On',
            Cell: (rowData) => {
                const { createdAt } = rowData.row.original;
               
                let objectDate = new Date(createdAt);

                let day = objectDate.getDate();
                let month = objectDate.getMonth()+1;
                let year = objectDate.getFullYear();

                let appliedDate= (day<10 ?'0'+day:day)+'-'+(month<10?'0'+month:month)+'-'+year;
                return appliedDate;
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: (rowData) => {
                const { status } = rowData.row.original;

                let leaveStatus='Pending';
                
                if(status ===1)
                {
                    leaveStatus='Accepted';

                } else if(status===2)
                {
                    leaveStatus ='Rejected';

                }
               
                return leaveStatus;

            }
        },
        {
            accessor: 'none',
            Header: 'Action',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const { _id,status } = rowData.row.original;
                return (
                    <Flex>
                        <CardDropdown>
                            <div className="py-2">
                            { status == null && (
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); navigate("/leaves/edit/" + _id) }}><FontAwesomeIcon icon="info-circle" /> Edit</Dropdown.Item>
                                )}
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); deleteLeave(_id) }}><FontAwesomeIcon icon="trash" /> Remove</Dropdown.Item>
                                <Dropdown.Item href="#!" onClick={(e) => { e.preventDefault(); viewLeave(_id) }}><FontAwesomeIcon icon="eye" /> View</Dropdown.Item>
                                
                            </div>
                        </CardDropdown>
                    </Flex>
                );
            }
        }
    ];

  return (
    <>
    <Modal
    show={modalShow}
    onHide={() => setModalShow(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">Leave Application</Modal.Title>
      <CloseButton
        className="btn btn-circle btn-sm transition-base p-0"
        onClick={() => setModalShow(false)}
      />
    </Modal.Header>
    <Modal.Body>
      <form>
         {isGettingLeaveData && (
                                <span className="spinner-grow spinner-grow-sm"></span>
          )}
         <p>Applied By: {appliedBy}</p>
          <p>Period: From {fromDate} to {toDate}</p>
          <p>No of Days: {noOfDays}</p>
          <p>Type: {leaveCategoryId}</p>
          <p>Reason: {leaveDescription}</p>
          <p>Status: {leaveStatus}</p>
          { acceptRejectReason && (
          <p>Accept/Reject Reason: {acceptRejectReason} </p>
          )}
          
          <Form.Control
                  type="hidden"
                  {...register('leaveId')}
                />
            { showAcceptReject && (  
            <> 
            <Form.Group className="mb-3">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Reason"
              {...register('actionReason', { required: false })}
            />
            </Form.Group>
            <Row className="g-3">
                <Form.Group as={Col} lg={2}>
                {isAcceptSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                )}
                    <Button onClick={handleSubmit(acceptLeave)}>Accept</Button>  
                </Form.Group>
                <Form.Group as={Col} lg={2}>
                {isRejectSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                )}
                    <Button onClick={handleSubmit(rejectLeave)}>Reject</Button> 
                </Form.Group>
            </Row>
           </>
           )} 
        </form>
    </Modal.Body>
    <Modal.Footer>
   
    <Button onClick={() => setModalShow(false)}>Close</Button>
    </Modal.Footer>
  </Modal>
    <Tabs defaultActiveKey="my-leave" id="uncontrolled-tab-example">
      <Tab eventKey="my-leave" title="My Leaves" className='border-bottom border-x p-3'>
        <AdvanceTableWrapper
            columns={ myColumns}
            data={myLeaves}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <MyLeaveTableHeader table loggedInUserId={loggedInUserId} />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
      </Tab>
      { approveLeavePermission && (
        
      <Tab eventKey="employee-leave" title="Employee Leaves" className='border-bottom border-x p-3'>
            <AdvanceTableWrapper
            columns={employeeColumns}
            data={employeeLeaves}
            selection
            sortable
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <LeaveTableHeader table />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination table />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
      </Tab>
      
      )}
    </Tabs>
 </>

    );
};

export default Leaves;
