import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestEditEmployee, requestAddEmployee,requestGetEmployee } from "./Middleware/action";
import { useState } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {countryData} from '../../data/countryData.js';

const AddUpdateEmployeeLeave = () => {

  const dispatch = useDispatch();
  const { authUser } = useSelector(store => store.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [passwordRequired, setPasswordRequired] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [country, setCountry] = useState();
  let navigate = useNavigate();
  const { id } = useParams();
  const loggedInUserId =authUser.user.uid;
  const [showEmail, setShowEmail] = useState(null);

  useEffect(() => {
    if (id) {
      setPasswordRequired(false);
      getEmployee();
    }
  }, [id]);

  const getEmployee = () => {
    const onSuccess = (data) => { 
    //console.log('data..',data);
      const employee = data.employee;
      //setValue('userId', employee.userId);
      setValue('firstName', employee.firstName);
      setValue('lastName', employee.lastName);
      setValue('email', employee.email);
      setValue('phone', employee.meta[0]?.phone);
      setValue('address', employee.meta[0]?.address);
      setValue('country', employee.meta[0]?.country);
      setValue('city', employee.meta[0]?.city);
      setValue('status', employee.status);
      
      setShowEmail(employee.email);

      setEmployee(employee);
    }
    dispatch(requestGetEmployee({ employeeId: id, onSuccess }))
  }

  const onSubmit = (formData) => {
    saveEmployeeData(formData);
  }

  const saveEmployeeData = (formData) => { console.log("form data ....",formData);
    setIsSubmitting(true);
    const onSuccess = (data) => {
        setIsSubmitting(false);

        if (data.success) {
            toast.success(`Employee ${id? 'updated':  'added'} successfully`, {
                theme: 'colored'
            });

            if((id == loggedInUserId))
            {
                navigate("/dashboard");
            }
            else
            {
                navigate("/employees");
            }
        }
    }

    const onFail = (err) => {
        setIsSubmitting(false);
        toast.error(err.response.data.message);
    }
    //save client
    formData.role = "Employee";
    
    if (id) 
    {
        formData.userId = id;
        dispatch(requestEditEmployee({ formData, onSuccess, onFail }))
    } else
    {
        dispatch(requestAddEmployee({ formData, onSuccess, onFail }))
    }
}

 
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={8}>
            <Card>
              <FalconCardHeader title={ id? 'Edit Employee': 'Add Employee'}  />
             
                <Card.Body className="bg-light">
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="First Name"
                                {...register('firstName', { required: true })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.firstName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Last Name"
                                {...register('lastName', { required: true })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.lastName ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6} controlId="email">
                            <Form.Label>Email</Form.Label>

                            { (id == loggedInUserId) ?
                            <Form.Label as={Col} lg={6}> {showEmail} </Form.Label>
                            
                             :
                            <>
                                <Form.Control
                                type="email"
                                placeholder="Email"
                                {...register('email', { required: 'Field is required', pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Invalid Email',
                                } })}
                        />
                                <div className="invalid-feedback" style={{ display: errors.email?.message ? 'block' : 'none' }}>{errors.email?.message}</div>
                            </>
                        }

                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Password"

                                {...register('password', { required: {passwordRequired} })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.password ? 'block' : 'none' }}>Field is required</div>

                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                placeholder="Phone"
                                {...register('phone', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.phone ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Address"
                                {...register('address', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.address ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>Country</Form.Label>
                             <Form.Select 
                             aria-label="country"
                             value={country}
                              {...register('country', { required: false })}>
                             <option value="">Select Country</option>
                                {countryData.map((item, index) => {
                                  return(
                                   <option value={item.name} key={index}>{item.name}</option>
                                   );
                                })}
                            </Form.Select>

                            <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                        <Form.Group as={Col} lg={6}>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="City"
                                {...register('city', { required: false })}
                            />
                            <div className="invalid-feedback" style={{ display: errors.city ? 'block' : 'none' }}>Field is required</div>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6}>
                        <Form.Label>Status</Form.Label>
                        <Form.Select aria-label="Status" {...register('status', { required: true })}>
                          <option value="1">Active</option>
                          <option value="2">Inactive</option>

                        </Form.Select>
                        <div className="invalid-feedback" style={{ display: errors.status ? 'block' : 'none' }}>Field is required</div>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                    <Form.Group as={Col} lg={6}>
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                            )}
                      { (id == loggedInUserId)? 'Edit Profile':( id? 'Update Employee':'Create Employee')}
                      </Button> 
                      </Form.Group>
                    </Row>
                    
                </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddUpdateEmployeeLeave;
