const keyMirror = require("keymirror");

export const constants = keyMirror({
  GET_TASKS: undefined,
  GET_TASK: undefined,
  ADD_TASK: undefined,
  EDIT_TASK: undefined,
  DELETE_TASK:undefined,
  CHANGE_TASK_STATUS:undefined,
  CHANGE_TASK_RUNNING_STATUS:undefined,
  GET_TASKS_FOR_TIMELINE:undefined
});
