import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Collapse, Dropdown, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestAddCompany, requestEditCompany,requestGetCompany } from "./Middleware/action";
import { useState } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';

const AddUpdateCompany = () => {

    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { control, register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (formData) => {
        saveCompanyData(formData);
    }

  let navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
        getCompany();
    }
  }, [id]);

  const getCompany = () => {

    setLoading(true);
    const onSuccess = (data) => {

      setLoading(false);

      let company= data.company[0];

      setValue('companyName', company.companyName);
      setValue('email', company.email);
      setValue('phone',company.phone );
      setValue('CIN', company.CIN);
      setValue('GSTNo', company.GSTNo);
      setValue('city', company.city);
      setValue('country', company.country);
      setValue('address', company.address);
      setValue('pincode', company.pincode);
      setValue('bankName',company.bankName );
      setValue('benificaryName', company.benificaryName);
      setValue('accountNo', company.accountNo);
      setValue('ifscCode', company.ifscCode);
      setValue('swiftCode', company.swiftCode);
      setValue('bankCountry', company.bankCountry);      
    }
    dispatch(requestGetCompany({ companyId: id, onSuccess }))
  }

  const saveCompanyData = (formData) => {
    setIsSubmitting(true);
    
    const onSuccess = (data) => {
    setIsSubmitting(false);

      if (data.success) {
        console.log(data);
        let companyId = data.companyId;
        toast.success(`Company ${id ? 'updated' : 'added'} successfully`, {
          theme: 'colored'
        });

        navigate("/company");
      }
    }


    if (id) {
      formData.companyId = id;
      dispatch(requestEditCompany({ formData, onSuccess }))
    } else {
      dispatch(requestAddCompany({ formData, onSuccess }))
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col lg={8}>
            <Card>
              <FalconCardHeader title={id ? 'Edit Company' : 'Add Company'} />
              <Card.Body className="bg-light">
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={12}>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Company Name"
                      {...register('companyName', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.companyName ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        {...register('email', { required: 'Field is required', pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: 'Invalid Email',
                        } })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.email?.message ? 'block' : 'none' }}>{errors.email?.message}</div>
                  </Form.Group>

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      {...register('phone', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.phone ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>                
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>CIN</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="CIN"
                      {...register('CIN', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.CIN ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>GST No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="GST No"
                      {...register('GSTNo', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.GSTNo ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      {...register('city', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.city ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      {...register('country', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.country ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Pincode</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      placeholder="Pincode"
                      {...register('pincode', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.pincode ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Address"
                    {...register('address', { required: true })}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <FalconCardHeader title="Bank Detail" />
              <Card.Body className="bg-light">
                <Form.Group className="mb-3">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Bank Name"
                    {...register('bankName', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.bankName ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Benificary Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Benificary Name"
                    {...register('benificaryName', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.benificaryName ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Account No</Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="Account No"
                    {...register('accountNo', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.accountNo ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="IFSC Code"
                    {...register('ifscCode', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.ifscCode ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Swift Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Swift Code"
                    {...register('swiftCode', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.swiftCode ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    {...register('bankCountry', { required: true })}
                  />
                  <div className="invalid-feedback" style={{ display: errors.bankCountry ? 'block' : 'none' }}>Field is required</div>
                </Form.Group>
                <div className="text-end">
                  <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                                <span className="spinner-grow spinner-grow-sm"></span>
                            )}  {'Save'}
                    
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddUpdateCompany;
