import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { requestAddProject, requestEditProject, requestGetProject } from "./Middleware/action";
import { requestGetClients } from '../clients/Middleware/action';
import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import FalconCardHeader from 'components/common/FalconCardHeader';
import DatePicker from "react-datepicker";
import { CURRENCY } from 'enum/enum';
const AddClientProject = ({ invoiceClientId, handleModelClose, setUpdateProjectList, setAddNewProject }) => {

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  let navigate = useNavigate();
  const [allClients, setAllClients] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const onSubmit = (formData) => {
    saveProjectData(formData);
  }

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (invoiceClientId) {
      setValue('clientId', invoiceClientId);
    }

  }, [invoiceClientId, allClients])


  const getClients = async (invoiceClientId) => {
    const onSuccess = (data) => {
      setAllClients(data.clients);

    }
    dispatch(requestGetClients({ onSuccess }))
  }
  // console.log("requestGetClients", allClients);
  const saveProjectData = () => {

    setIsSubmitting(true);

    getValues('projectName');

    let formData = new FormData;

    formData.projectName = getValues('projectName');
    formData.projectType = getValues('projectType');
    formData.projectSize = getValues('projectSize');
    formData.clientId = getValues('clientId');
    formData.projectStatus = getValues('projectStatus');
    formData.priority = getValues('priority');
    formData.startDate = getValues('startDate');
    formData.endDate = getValues('endDate');
    formData.budget = getValues('budget');
    formData.currency = getValues('currency');
    formData.projectDescription = getValues('projectDescription');

    const onSuccess = (data) => {
      setIsSubmitting(false);

      if (data.success) {
        //console.log(data);
        let projectId = data.projectId;
        toast.success(`Project added successfully`, {
          theme: 'colored'
        });
        handleModelClose();
        //setUpdateProjectList(true);
        setAddNewProject(true);
        //navigate("/projects");
      }
    }

    dispatch(requestAddProject({ formData, onSuccess }))
  }

  return (
    <>
      <Row className="g-3">
        <Col lg="12">

          <Card>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FalconCardHeader title={'Add Project'} />
              <Card.Body className="bg-light">
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Project Name"
                      {...register('projectName', { required: true })}
                    />
                    <div className="invalid-feedback" style={{ display: errors.projectName ? 'block' : 'none' }}>Field is required</div>
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Project Type</Form.Label>
                    <Form.Select aria-label="Status" {...register('projectType', { required: false })}>
                      <option value="Hourly">Hourly</option>
                      <option value="Fixed">Fixed</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Project Size</Form.Label>
                    <Form.Select aria-label="projectSize" {...register('projectSize', { required: false })}>
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Big">Big</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Project Client</Form.Label>
                    <Form.Select aria-label="Status" {...register('clientId', { required: false })} >
                      <option value="">Select</option>
                      {allClients.map((item, index) => {
                        return (
                          <option value={item.userId} key={index}>{item.firstName} {item.lastName}</option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>

                </Row>
                <Row className="mb-3 g-3">

                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Status</Form.Label>
                    <Form.Select aria-label="Status" {...register('projectStatus', { required: false })}>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Completed">Completed</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Priority</Form.Label>
                    <Form.Select aria-label="Priority" {...register('priority', { required: false })}>
                      <option value="Urgent">Urgent</option>
                      <option value="High">High</option>
                      <option value="Medium">Medium</option>
                      <option value="Low">Low</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6} >
                    <Form.Label>Start Date</Form.Label>
                    <Controller
                      control={control}
                      name="startDate"
                      selected={startDate}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          className='form-control'
                          onChange={(date) => {
                            console.log("date changed", date)
                            field.onChange(date)
                            setStartDate(date)
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>End Date</Form.Label>
                    <Controller
                      control={control}
                      name="endDate"
                      selected={endDate}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={endDate}
                          dateFormat="dd/MM/yyyy"
                          className='form-control'
                          onChange={(date) => {
                            console.log("date changed", date)
                            field.onChange(date)
                            setEndDate(date)
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg={6} >
                    <Form.Label>Budget</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Budget"
                      {...register('budget', { required: false })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg={6}>
                    <Form.Label>Currency</Form.Label>
                    <Form.Select aria-label="Currency" {...register('currency', { required: false })}>
                      {Object.keys(CURRENCY).map((curr, i) => {
                        return (
                          <option value={curr} key={i}>{curr}</option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>

                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={13}
                    placeholder="Description"
                    {...register('projectDescription', { required: false })}
                  />
                </Form.Group>

                <div className="text-end">
                  <Button variant="primary" type="button" onClick={saveProjectData} >
                    Create
                  </Button>
                </div>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddClientProject;
